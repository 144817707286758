import { ShareDataService } from "../../_services/share-data.service";
import { HttpAdapterService } from "../../_services/http-adapter.service";
import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { saveAs } from "file-saver";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { PlogDispComponent } from "../plog-disp/plog-disp.component";

@Component({
  selector: "app-plog",
  templateUrl: "./plog.component.html",
  styleUrls: ["./plog.component.scss"],
})
export class PlogComponent {
  selectedSystem = "";
  subscription: any;
  successMessage: any;
  plogData: any;

  constructor(
    private sharedServices: ShareDataService,
    private http: HttpClient,
    private dialog: MatDialog,
    private httpAdapter: HttpAdapterService
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit() {
    this.systemListener();
  }

  systemListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      ) {
        this.selectedSystem = data["selectedSystem"];
        this.getRawPlog();
      }
    });
  }

  addPlogs: { key: string; value: string }[] = [{ key: "", value: "" }];

  removePlogs: { key: string }[] = [{ key: "" }];

  // Method to check if the current PLOG data is filled
  // isPlogDataAvailable(): boolean {
  //   // Check if the last item in the list has both key and value filled
  //   const lastPlog = this.addPlogs[this.addPlogs.length - 1];
  //   return (
  //     lastPlog.key.trim() !== "" &&
  //     lastPlog.value.trim() !== "" &&
  //     !this.plogData?.hasOwnProperty(lastPlog.key.trim())
  //   );
  // }

  isKeyExists(key: string, type: "addPlog" | "removePlog"): boolean {
    if (!key.trim()) {
      return false; // Don't show an error for empty keys
    }
    if (type === "addPlog") {
      return !!this.plogData[key]; // Key already exists
    } else if (type === "removePlog") {
      return !this.plogData[key]; // Key does not exist
    }
    return false;
  }

  isValueExists(key: string): boolean {
    if (!key.trim()) {
      return false; // Don't show an error for empty keys
    }
    return Object.keys(this.plogData).some((keyItem) => this.plogData[keyItem] == key); // Key already exists

  }

  // Method to check if all PLOG entries are filled
  isAllPlogsFilled(): boolean {
    return this.addPlogs.every(
      (plog) =>
        plog.key.trim() !== "" &&
        plog.value.trim() !== "" &&
        !this.plogData?.hasOwnProperty(plog.key.trim()) &&
        !this.isValueExists(plog.value)
    );
  }

  // Method to check if all remove PLOG keys are filled
  isRemovePlogsFilled(): boolean {
    return this.removePlogs.every(
      (plog) =>
        plog.key.trim() !== "" && this.plogData?.hasOwnProperty(plog.key.trim())
    );
  }

  // Method to add a key-value pair from addPlogs or removePlogs
  addKeyValue(type: string) {
    if (type === "addPlog") {
      this.addPlogs.push({ key: "", value: "" });
    } else {
      this.removePlogs.push({ key: "" });
    }
  }

  // Method to remove a key-value pair from addPlogs or removePlogs
  removeKeyValue(index: number, type: string) {
    if (type === "addPlog") {
      this.addPlogs.splice(index, 1);
    } else if (type === "removePlog") {
      this.removePlogs.splice(index, 1);
    }
  }

  // Method to submit PLOGs
  addPlog() {
    const self = this;
    const params = {
      system: this.selectedSystem,
      url: "reports/insertPlog",
      data: self.addPlogs,
    };
    self.httpAdapter.httpPost(params, (err, result) => {
      if (!err && result.status == 200) {
        self.getRawPlog()
        self.addPlogs.length = 0;
        self.addPlogs.push({ key: "", value: "" });
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          data: {
            Title: "Success",
            Content: result.body.message,
          },
        });
      } else {
        console.error("Error in executing API", err);
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          data: {
            Title: "Error",
            Content: "Error in Submission",
          },
        });
      }
    });
  }

  // Method to remove PLOGs
  removePlog() {
    const self = this;
    const params = {
      system: this.selectedSystem,
      url: "reports/removePlog",
      data: self.removePlogs,
    };
    self.httpAdapter.httpPost(params, (err, result) => {
      if (!err) {
        self.getRawPlog()
        self.removePlogs.length = 0;
        self.removePlogs.push({ key: "" });
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          data: {
            Title: "Success",
            Content: result.body.message,
          },
        });
      } else {
        console.error("Error in executing API", err);
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          data: {
            Title: "Error",
            Content: "Error in Submission",
          },
        });
      }
    });
  }

  viewPlog(viewOptions) {
    const self = this;
    const inputFilegetParam = {
      url: "reports/plog/viewPlog",
      system: this.selectedSystem,
    };
    self.httpAdapter.httpGetNew(inputFilegetParam).subscribe((result) => {
      this.successMessage = result.body["message"];
      console.log("this.plogData", this.plogData);
      if (!this.successMessage) {
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          panelClass: "my-alert-message-class",
          data: {
            Title: "Error Message",
            Content: `Please Contact Admin!`,
          },
        });
        return true;
      }
      // const jsonStr = JSON.stringify(result.body["data"], null, 2); // Convert to pretty-printed JSON
      if (viewOptions == "download") {
        const blob = new Blob([result.body["data"]], { type: "text" });

        const url = window.URL.createObjectURL(blob);
        const today = new Date();
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "PLOG Config" +
          "_" +
          today.getDate().toString() +
          "-" +
          today.toLocaleString("default", { month: "long" }) +
          "-" +
          today.getFullYear().toString() +
          "-" +
          today.getHours().toString() +
          "HH-" +
          today.getMinutes().toString() +
          "MM.input";
        document.body.appendChild(a); // Append to the document
        a.click();
        document.body.removeChild(a); // Remove from the document

        window.URL.revokeObjectURL(url);
      } else {
        const dialogRef = self.dialog.open(PlogDispComponent, {
          data: { value: result.body["data"] },
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
        });
      }
    });
  }

  getRawPlog() {
    const self = this;
    const inputFilegetParam = {
      url: "reports/plog/rawPlog",
      system: this.selectedSystem,
    };
    self.httpAdapter.httpGetNew(inputFilegetParam).subscribe((result) => {
      this.plogData = result.body["data"];
      this.successMessage = result.body["message"];
      console.log("this.plogData", this.plogData);
      if (!this.successMessage) {
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          panelClass: "my-alert-message-class",
          data: {
            Title: "Error Message",
            Content: `Please Contact Admin!`,
          },
        });
        return true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
