import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TSIValidateViewComponent } from "./validate-view.component";


const routes: Routes = [
  { path: "", redirectTo: "validate", pathMatch: "full" },
  { path: "", component: TSIValidateViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ValidateViewRoutingModule { }
