<div class="plog-container">
  <section>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button
            mat-fab
            color="primary"
            (click)="viewPlog('download')"
            matTooltip="Download PLOG Conf"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button
            mat-fab
            color="primary"
            (click)="viewPlog('view')"
            matTooltip="View PLOG Conf"
          >
            <mat-icon>visibility</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>

  <div class="card-section">
    <mat-card class="card-plog">
      <mat-card-header>
        <mat-card-title>PLOG Form</mat-card-title>
        <mat-card-subtitle>Form to Add/Remove PLOG Data</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group animationDuration="1000ms">
          <!-- Add PLOG Tab -->
          <mat-tab label="Add PLOG" class="header-tab">
            <div class="row header-container">
              <div class="col-5">
                <h5 class="m-0">Key</h5>
              </div>
              <div class="col-5">
                <h5 class="m-0">Value</h5>
              </div>
              <div class="col-2"></div>
            </div>

            <div class="scrollable-content">
              <div
                *ngFor="let plog of addPlogs; let plogIndex = index"
                class="row"
              >
                <div class="col-5">
                  <mat-form-field class="w-100 p-0">
                    <input matInput class="p-0" [(ngModel)]="plog.key" />
                  </mat-form-field>
                  <div
                    *ngIf="isKeyExists(plog.key, 'addPlog')"
                    class="error-message mb-2"
                  >
                    Key '{{ plog.key }}' already exists!
                  </div>
                </div>
                <div class="col-5">
                  <mat-form-field class="w-100">
                    <input matInput [(ngModel)]="plog.value" />
                  </mat-form-field>
                  <div
                    *ngIf="isValueExists(plog.value)"
                    class="error-message mb-2"
                  >
                    Value '{{ plog.value }}' already exists!
                  </div>
                </div>
                <div class="col-2">
                  <button
                    mat-button
                    [hidden]="plogIndex === 0"
                    (click)="removeKeyValue(plogIndex, 'addPlog')"
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      fontIcon="delete"
                    ></mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="submit-buttons">
              <button
                mat-stroked-button
                color="primary"
                [disabled]="!isAllPlogsFilled()"
                (click)="addKeyValue('addPlog')"
              >
                <span>Add PLOG Data</span>
              </button>
              <button
                mat-flat-button
                color="primary"
                [disabled]="!isAllPlogsFilled()"
                (click)="addPlog()"
              >
                <span>Submit</span>
              </button>
            </div>
          </mat-tab>

          <!-- Remove PLOG Tab -->
          <mat-tab label="Remove PLOG" class="header-tab">
            <div class="row header-container">
              <div class="col-12">
                <h5>Keys to Remove</h5>
              </div>
            </div>

            <div class="scrollable-content">
              <div
                *ngFor="let plog of removePlogs; let plogIndex = index"
                class="row"
              >
                <div class="col-10">
                  <mat-form-field class="w-100">
                    <input matInput [(ngModel)]="plog.key" />
                  </mat-form-field>
                  <div
                    *ngIf="isKeyExists(plog.key, 'removePlog')"
                    class="error-message"
                  >
                    Key '{{ plog.key }}' doesn't exist
                  </div>
                </div>
                <div class="col-2">
                  <button
                    mat-button
                    [hidden]="plogIndex === 0"
                    (click)="removeKeyValue(plogIndex, 'removePlog')"
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example home icon"
                      fontIcon="delete"
                    ></mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="submit-buttons">
              <button
                mat-stroked-button
                color="warn"
                [disabled]="!isRemovePlogsFilled()"
                (click)="addKeyValue('removePlog')"
              >
                <span>Add Key</span>
              </button>
              <button
                mat-flat-button
                color="primary"
                [disabled]="!isRemovePlogsFilled()"
                (click)="removePlog()"
              >
                <span>Submit</span>
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
