import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

/** For socket implementation in EFT, dependency injection has been created.- (PM-71) */
export class SocketioClientServices {
  private authInfo = {};
  private socket: any;
  public sharedData = {};
  // readonly url:string  = 'http://127.0.0.1:3200/EFTsocket';
  readonly url: string = `${environment.apiPath}EFTsocket`;

  constructor() {}

  /**
   * The function below connects the socket to the given server url. (PM-71)
   */
  socketConnection(payload) {
    return new Promise((resolve, reject) => {
      try {
        console.log(payload);
        this.authInfo["userName"] = payload["userName"];
        this.authInfo["groupName"] = payload["groupName"];
        this.socket = io(this.url, {
          reconnection: true,
          reconnectionDelay: 10,
          reconnectionDelayMax: 5000,
          reconnectionAttempts: 99999,
          // 'transports': ['websocket'],
          // 'secure': false,
          auth: {
            userName: this.authInfo["userName"],
            groupName: this.authInfo["groupName"],
          },
        });

        /** Connection event */
        this.socket.on("connect", () => {
          console.log("✔️ Socket sucessfully Connected");
        });

        /** Error events */
        this.socket.on("error", (error) => {
          console.log("❌ ERROR -->", error);
        });

        this.socket.on("connect_error", (error) => {
          console.log("❌ CONNECT_ERROR -->", error);
        });

        this.socket.io.on("reconnect_error", (error) => {
          console.log("❌ RECONNECT_ERROR -->", error);
        });

        var userSocket = {
          connection: true,
          auth: this.socket.auth,
        };
        resolve(userSocket);
      } catch (err) {
        reject(err.message);
      }
    });
  }

  /**
   * The function below returns the status of the socketConnection with the server. (PM-147)
   */
  connectionStatus() {
    if (this.socket) {
      if (this.socket.connected != undefined) {
        return this.socket.connected;
      }
    } else {
      return false;
    }
  }
  /**
   * The function below creates an Observable for lising socket events.  (PM-71)
   */
  listeningFunction(eventName: string) {
    return new Observable((sub) => {
      this.socket.on(eventName, (data) => {
        sub.next(data);
      });
    });
  }

  /**
   * The function below emits the value to the specified event name. (PM-71)
   */
  emitingFunction(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  /**
   * The following function is for disconnecting a socket connection. (PM-71)
   */
  disConnectSocket() {
    if (this.socket) {
      /** When the socket is connected, only the code below will  execute  (PM-147) */
      this.socket.disconnect();
    } else {
      return null;
    }
  }
}
