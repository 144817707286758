import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { ShareDataService } from "src/app/_services/share-data.service";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";
import * as d3 from "d3";
import { hierarchy, tree } from "d3-hierarchy";
import * as $ from "jquery";

@Component({
  selector: "app-generate-tree",
  templateUrl: "./generate-tree.component.html",
  styleUrls: ["./generate-tree.component.scss"],
})
export class GenerateTreeComponent implements OnInit {
  subscription: any;
  public httpAdapter: HttpAdapterService;
  public sharedData = {};

  constructor(
    private http: HttpClient,
    private sharedServices: ShareDataService,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private socket: SocketioClientServices
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit() {
    var self = this;
    // self.subscription = self.sharedServices.currentData$.subscribe((data) => {
    //   self.sharedData = { ...data };
    //   console.log(self.sharedData["pgmvsScript"]);
    //   this.generateTree();
    // });
  }

  // generateTree() {
  //   var margin = {
  //       top: 20,
  //       right: 120,
  //       bottom: 20,
  //       left: 120,
  //     },
  //     width = 960 - margin.right - margin.left,
  //     height = 800 - margin.top - margin.bottom;

  //   var root = {
  //     name: "ABCD",
  //     children: [
  //       {
  //         name: "Script1",
  //         children: [
  //           {
  //             name: "Sub Script1",
  //             children: [
  //               {
  //                 name: "PH1",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //               {
  //                 name: "PH2",
  //                 children: [
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //           {
  //             name: "Sub Script2",
  //             children: [
  //               {
  //                 name: "PH1",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //               {
  //                 name: "PH2",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         name: "Script2",
  //         children: [
  //           {
  //             name: "Sub Script1",
  //             children: [
  //               {
  //                 name: "PH1",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //               {
  //                 name: "PH2",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //           {
  //             name: "Sub Script2",
  //             children: [
  //               {
  //                 name: "PH1",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //               {
  //                 name: "PH2",
  //                 children: [
  //                   {
  //                     name: "trans1",
  //                     count: 3938,
  //                   },
  //                   {
  //                     name: "trans2",
  //                     count: 3812,
  //                   },
  //                   {
  //                     name: "trans3",
  //                     count: 6714,
  //                   },
  //                   {
  //                     name: "trans4",
  //                     count: 743,
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         name: "Script3",
  //         children: [
  //           {
  //             name: "PH1",
  //             children: [
  //               {
  //                 name: "trans1",
  //                 count: 3938,
  //               },
  //               {
  //                 name: "trans2",
  //                 count: 3812,
  //               },
  //               {
  //                 name: "trans3",
  //                 count: 6714,
  //               },
  //               {
  //                 name: "trans4",
  //                 count: 743,
  //               },
  //             ],
  //           },
  //           {
  //             name: "PH2",
  //             children: [
  //               {
  //                 name: "trans1",
  //                 count: 3938,
  //               },
  //               {
  //                 name: "trans2",
  //                 count: 3812,
  //               },
  //               {
  //                 name: "trans3",
  //                 count: 6714,
  //               },
  //               {
  //                 name: "trans4",
  //                 count: 743,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   };

  //   var i = 0,
  //     duration = 750,
  //     rectW = 100,
  //     rectH = 30;

  //   // var tree = d3.tree().size([200, 200]);
  //   // var diagonal = d3.separation().projection(function (d) {
  //   //   return [d.x + rectW / 2, d.y + rectH / 2];
  //   // });
  //   // var zm = (zm = d3.behavior.zoom().scaleExtent([1, 3]).on("zoom", redraw));
  //   // var svg = d3
  //   //   .select("#showPgmVsScript")
  //   //   .append("svg")
  //   //   .attr("width", 2500)
  //   //   .attr("height", 1000)
  //   //   .call(zm)
  //   //   .append("g")
  //   //   .attr("transform", "translate(" + 350 + "," + 20 + ")");

  //   // //necessary so that zoom knows where to zoom and unzoom from
  //   // zm.translate([350, 20]);

  //   // root["x0"] = 0;
  //   // root["y0"] = height / 2;

  //   // function collapse(d) {
  //   //   if (d.children) {
  //   //     d._children = d.children;
  //   //     d._children.forEach(collapse);
  //   //     d.children = null;
  //   //   }
  //   // }

  //   // root.children.forEach(collapse);
  //   // update(root);

  //   // d3.select("#body").style("height", "800px");

  //   // function update(source) {
  //   //   // Compute the new tree layout.
  //   //   var nodes = tree.nodes(root).reverse(),
  //   //     links = tree.links(nodes);

  //   //   // Normalize for fixed-depth.
  //   //   nodes.forEach(function (d) {
  //   //     d.y = d.depth * 180;
  //   //   });

  //   //   // Update the nodes…
  //   //   var node = svg.selectAll("g.node").data(nodes, function (d) {
  //   //     return d.id || (d.id = ++i);
  //   //   });

  //   //   // Enter any new nodes at the parent's previous position.
  //   //   var nodeEnter = node
  //   //     .enter()
  //   //     .append("g")
  //   //     .attr("class", "node")
  //   //     .attr("transform", function (d) {
  //   //       return "translate(" + source.x0 + "," + source.y0 + ")";
  //   //     })
  //   //     .on("click", click);

  //   //   nodeEnter
  //   //     .append("rect")
  //   //     .attr("width", rectW)
  //   //     .attr("height", rectH)
  //   //     .attr("stroke", "black")
  //   //     .attr("stroke-width", 1)
  //   //     .style("fill", function (d) {
  //   //       return d._children ? "lightsteelblue" : "#fff";
  //   //     });

  //   //   nodeEnter
  //   //     .append("text")
  //   //     .attr("x", rectW / 2)
  //   //     .attr("y", rectH / 2)
  //   //     .attr("dy", ".35em")
  //   //     .attr("text-anchor", "middle")
  //   //     .text(function (d) {
  //   //       return d.name;
  //   //     });

  //   //   // Transition nodes to their new position.
  //   //   var nodeUpdate = node
  //   //     .transition()
  //   //     .duration(duration)
  //   //     .attr("transform", function (d) {
  //   //       return "translate(" + d.x + "," + d.y + ")";
  //   //     });

  //   //   nodeUpdate
  //   //     .select("rect")
  //   //     .attr("width", rectW)
  //   //     .attr("height", rectH)
  //   //     .attr("stroke", "black")
  //   //     .attr("stroke-width", 1)
  //   //     .style("fill", function (d) {
  //   //       return d._children ? "lightsteelblue" : "#fff";
  //   //     });

  //   //   nodeUpdate.select("text").style("fill-opacity", 1);

  //   //   // Transition exiting nodes to the parent's new position.
  //   //   var nodeExit = node
  //   //     .exit()
  //   //     .transition()
  //   //     .duration(duration)
  //   //     .attr("transform", function (d) {
  //   //       return "translate(" + source.x + "," + source.y + ")";
  //   //     })
  //   //     .remove();

  //   //   nodeExit
  //   //     .select("rect")
  //   //     .attr("width", rectW)
  //   //     .attr("height", rectH)
  //   //     //.attr("width", bbox.getBBox().width)""
  //   //     //.attr("height", bbox.getBBox().height)
  //   //     .attr("stroke", "black")
  //   //     .attr("stroke-width", 1);

  //   //   nodeExit.select("text");

  //   //   // Update the links…
  //   //   var link = svg.selectAll("path.link").data(links, function (d) {
  //   //     return d.target.id;
  //   //   });

  //   //   // Enter any new links at the parent's previous position.
  //   //   link
  //   //     .enter()
  //   //     .insert("path", "g")
  //   //     .attr("class", "link")
  //   //     .attr("fill", "none")
  //   //     .attr("stroke", "black")
  //   //     .attr("x", rectW / 2)
  //   //     .attr("y", rectH / 2)
  //   //     .attr("d", function (d) {
  //   //       var o = {
  //   //         x: source.x0,
  //   //         y: source.y0,
  //   //       };
  //   //       return diagonal({
  //   //         source: o,
  //   //         target: o,
  //   //       });
  //   //     });

  //   //   // Transition links to their new position.
  //   //   link.transition().duration(duration).attr("d", diagonal);

  //   //   // Transition exiting nodes to the parent's new position.
  //   //   link
  //   //     .exit()
  //   //     .transition()
  //   //     .duration(duration)
  //   //     .attr("d", function (d) {
  //   //       var o = {
  //   //         x: source.x,
  //   //         y: source.y,
  //   //       };
  //   //       return diagonal({
  //   //         source: o,
  //   //         target: o,
  //   //       });
  //   //     })
  //   //     .remove();

  //   //   // Stash the old positions for transition.
  //   //   nodes.forEach(function (d) {
  //   //     d.x0 = d.x;
  //   //     d.y0 = d.y;
  //   //   });
  //   // }

  //   // // Toggle children on click.
  //   // function click(d) {
  //   //   if (d.children) {
  //   //     d._children = d.children;
  //   //     d.children = null;
  //   //   } else {
  //   //     d.children = d._children;
  //   //     d._children = null;
  //   //   }
  //   //   update(d);
  //   // }

  //   // //Redraw for zoom
  //   // function redraw() {
  //   //   //console.log("here", d3.event.translate, d3.event.scale);
  //   //   svg.attr(
  //   //     "transform",
  //   //     "translate(" +
  //   //       d3.event.translate +
  //   //       ")" +
  //   //       " scale(" +
  //   //       d3.event.scale +
  //   //       ")"
  //   //   );
  //   // }
  //   var margin = { top: 40, right: 90, bottom: 50, left: 90 },
  //     width = 660 - margin.left - margin.right,
  //     height = 500 - margin.top - margin.bottom;

  //   // declares a tree layout and assigns the size
  //   var treemap = d3.tree().size([width, height]);

  //   //  assigns the data to a hierarchy using parent-child relationships
  //   var nodes = d3.hierarchy(root);

  //   // maps the node data to the tree layout
  //   nodes = treemap(nodes);

  //   // append the svg obgect to the body of the page
  //   // appends a 'group' element to 'svg'
  //   // moves the 'group' element to the top left margin
  //   var svg = d3
  //       .select("body")
  //       .append("svg")
  //       .attr("width", width + margin.left + margin.right)
  //       .attr("height", height + margin.top + margin.bottom),
  //     g = svg
  //       .append("g")
  //       .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  //   // adds the links between the nodes
  //   var link = g
  //     .selectAll(".link")
  //     .data(nodes.descendants().slice(1))
  //     .enter()
  //     .append("path")
  //     .attr("class", "link")
  //     .attr("d", function (d) {
  //       return (
  //         "M" +
  //         d.x +
  //         "," +
  //         d.y +
  //         "C" +
  //         d.x +
  //         "," +
  //         (d.y + d.parent.y) / 2 +
  //         " " +
  //         d.parent.x +
  //         "," +
  //         (d.y + d.parent.y) / 2 +
  //         " " +
  //         d.parent.x +
  //         "," +
  //         d.parent.y
  //       );
  //     });

  //   // adds each node as a group
  //   var node = g
  //     .selectAll(".node")
  //     .data(nodes.descendants())
  //     .enter()
  //     .append("g")
  //     .attr("class", function (d) {
  //       return "node" + (d.children ? " node--internal" : " node--leaf");
  //     })
  //     .attr("transform", function (d) {
  //       return "translate(" + d.x + "," + d.y + ")";
  //     });

  //   // adds the circle to the node
  //   node.append("circle").attr("r", 10);

  //   // adds the text to the node
  //   node
  //     .append("text")
  //     .attr("dy", ".35em")
  //     .attr("y", function (d) {
  //       return d.children ? -20 : 20;
  //     })
  //     .style("text-anchor", "middle")
  //     .text(function (d) {
  //       return d.data.name;
  //     });
  // }
}
