<div class="container-fluid">
  <div class="topSection">
    <div class="row">
      <div class="col-3">
        <nz-select
          [(ngModel)]="selectedProgramName"
          nzAllowClear
          nzPlaceHolder="Select a Program"
          nzShowSearch
          style="width: 60%"
          (ngModelChange)="getProgramDetails()"
        >
          <nz-option-group
            *ngFor="let programTitle of programGroups"
            [nzLabel]="programTitle.letter"
            class="ant-item-group"
          >
            <nz-option
              *ngFor="let program of programTitle.programName"
              [nzValue]="program"
              [nzLabel]="program"
            ></nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div class="col-3"></div>
      <!-- </div> -->
      <div class="col-3">
        <button
          *ngIf="gotScripts"
          mat-mini-fab
          aria-label="Example icon button with a home icon"
          class="download"
          (click)="downloadScripGen()"
        >
          <mat-icon>download</mat-icon>
        </button>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</div>
<!--
  Div to Display the Tree Data
-->
<div class="pgmcontainer">
  <svg #showPgmVsScript id="showPgmVsScript"></svg>
</div>

<!-- <ng-template #notLoggedIn>
  <h1>Please log in to continue.</h1>
</ng-template>


<ng-container *ngIf="!gotScripts">
  <ng-container *ngTemplateOutlet="notLoggedIn"></ng-container>
</ng-container> -->
