import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { DialogueComponent } from "../errorPages/dialogue/dialogue.component";
import { MatDialog } from "@angular/material/dialog";
import { InputDispComponent } from "../run-view/run-view/configTabs/scripts/input-disp/input-disp.component";
import { saveAs } from "file-saver";
import { ShareDataService } from "../_services/share-data.service";
import { UserService } from "../_services/user.service";

@Component({
  selector: "app-library-management",
  templateUrl: "./library-management.component.html",
  styleUrls: ["./library-management.component.scss"],
})
export class LibraryManagementComponent {
  public httpAdapter: HttpAdapterService;

  public inputFiles: {
    _id: String;
    fileName: String;
    inputFileContent: String;
    modifiedTime: Date;
    createdAt: Date;
  }[] = [];
  public access = false;
  selectedSystem: string = "";
  subscription: any;
  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sharedServices: ShareDataService,
    private userService: UserService
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }
  ngOnInit() {
    const token = this.userService.getToken();
    this.access = token ? true : false;
    if (this.access) {
      this.socketListener();
      this.getFiles();
    }
  }

  socketListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      )
        this.selectedSystem = data["selectedSystem"];
      this.inputFiles = [];
      this.getFiles();
    });
  }

  getFiles() {
    let self = this;
    const params = {
      system: this.selectedSystem,
      url: "inputFile/getAllScriptsByTesters",
    };

    self.httpAdapter.httpPost(params, function (err, result) {
      if (!err) {
        console.log(result.body.data);
        self.inputFiles = result.body.data;
        // this.inputFiles = self.logs.map((item) => ({ ...item, checked: false }));
      } else {
        console.log("Error in executing api", err);
      }
    });
  }

  showInput(file) {
    var self = this;

    var inputFilegetParam = {
      url: "inputFile/viewFile",
      data: file,
    };
    self.httpAdapter.httpPost(inputFilegetParam, function (err, fileResp) {
      if (!err) {
        /**
         * Checking if there is any error while viewing the Input Script File
         * If error exists, prompting for an window to show the error
         */
        if (fileResp.body.isError) {
          //console.log('Error Message --->',fileResp.body.message);
          // window.alert(`File doesn't exist, Please delete and add the Script`);
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            panelClass: "my-alert-message-class",
            data: {
              Title: "Error Message",
              Content: `File doesn't exist, Please delete and add the Script`,
            },
          });
          return true;
        }
        /**
         * To display the normal and subscript transaction in the view file, the blow code is used (PD-37)
         */

        var inputFile = fileResp["body"][0]["inputFileContent"].split("\n");

        const dialogRef = self.dialog.open(InputDispComponent, {
          // data: { value: self.inputFile.join('\n') }
          /**
           * Commanded the above line.
           * Rather than sharing as a string, send the array as it. (PM-157)
           */
          data: { value: inputFile, irtf: fileResp["body"][0]["irtfRequired"] },
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
        });
      }
    });
  }

  downloadScript(file) {
    var self = this;
    var params = {
      url: "inputFile/viewFile",
      data: file,
    };
    self.httpAdapter.httpPost(params, function (err, result) {
      if (err) {
        console.log("Error", err);
      } else {
        if (result.status == 200) {
          console.log("Download scripts 👉️ \r", result.body);
          const today = new Date();
          const blob = new Blob([result.body[0]["inputFileContent"]], {
            type: "text",
          });
          saveAs(
            blob,
            file.fileName +
              "_" +
              today.getDate().toString() +
              "-" +
              today.toLocaleString("default", { month: "long" }) +
              "-" +
              today.getFullYear().toString() +
              "-" +
              today.getHours().toString() +
              "HH-" +
              today.getMinutes().toString() +
              "MM.input"
          );
        } else {
          console.log("Error Message 👉️ \r", result.body.message);
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: { Title: "Error Message", Content: result.body.message },
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
