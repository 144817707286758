import { Component } from "@angular/core";
import { ShareDataService } from "../_services/share-data.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DialogueComponent } from "../errorPages/dialogue/dialogue.component";
import { InputDispComponent } from "../run-view/run-view/configTabs/scripts/input-disp/input-disp.component";
import { LogDispComponent } from "./input-disp/input-disp.component";
import { SocketioClientServices } from "../_services/socketio-client.services";
import { AppComponent } from "../app.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-pre-load",
  templateUrl: "./pre-load.component.html",
  styleUrls: ["./pre-load.component.scss"],
})
export class PreLoadComponent {
  makeTpfFilePath = "";
  loadFilePath = "";
  oldrName = "";
  userName = "";
  showPassword = false;
  password = "";
  validationStatus = false;
  subscription: any;
  selectedSystem = "";
  options = [];
  loadSetName = "";
  // logonInput = "";
  // logonPassword = "";
  showLogonPassword = false;
  // cmdLniata = "";
  public httpAdapter: HttpAdapterService;

  constructor(
    private sharedServices: ShareDataService,
    private route: ActivatedRoute,
    public http: HttpClient,
    public dialog: MatDialog,
    public socket: SocketioClientServices,
    private _snackBar: MatSnackBar
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  getDisabledStatus() {
    return (
      !this.makeTpfFilePath ||
      !this.loadFilePath ||
      !this.oldrName ||
      !this.userName ||
      !this.password ||
      !this.options ||
      !this.options.length ||
      !this.options.some((item) => item.status)
    );
  }
  systemListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      ) {
        console.log('this.options',data["vpars"])
        this.makeTpfFilePath = "";
        this.loadFilePath = "";
        this.oldrName = "";
        this.userName = "";
        this.password = "";
        this.selectedSystem = data["selectedSystem"];
        this.options = data["vpars"].map((item) => ({ name: item, status: false })) || [];
        this.getExistingLoadDetails();
      }
    });

  }
  ngOnInit() {
    this.systemListener();
    this.getPreloadLogs();
    this.socketListener();
  }
  validateLoadDetails() {
    var params = {
      url: "preload/validateLoadSetDetails",
      system: this.selectedSystem,
      data: {
        makeTpfFilePath: this.makeTpfFilePath,
        loadFilePath: this.loadFilePath,
        oldrName: this.oldrName,
        loadSetName: this.loadSetName,
        userName: this.userName,
        password: this.password,
        systemsArray: this.options
          .filter((item) => item.status)
          .map((item) => item.name),
      },
    };
    this.httpAdapter.httpPostNew(params).subscribe(
      (res) => {
        if (res.validLoad) {
          this.validationStatus = true;
          this.loadInProgress = false;
          this.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: {
              Title: "Success",
              Content: res.message,
            },
          });
        } else {
          this.validationStatus = false;
          this.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: {
              Title: "Error",
              Content: res.message,
            },
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  loadInProgress = false;
  startLoadingLoadDetails() {
    this.loadInProgress = true;
    var params = {
      url: "preload/startPreload",
      data: {
        makeTpfFilePath: this.makeTpfFilePath,
        loadFilePath: this.loadFilePath,
        oldrName: this.oldrName,
        loadSetName: this.loadSetName,
        userName: this.userName,
        password: this.password,
        systemsArray: this.options
          .filter((item) => item.status)
          .map((item) => item.name),
      },
      system: this.selectedSystem,
    };
    this.httpAdapter.httpPostNew(params).subscribe(
      (res) => {
        if (res.loadStarted) {
          this.loadInProgress = true;
        } else {
          this.loadInProgress = false;
          this.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: {
              Title: "Error",
              Content: res.message,
            },
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  fieldsChanging() {
    this.validationStatus = false;
  }

  getExistingLoadDetails() {
    var params = {
      url: "preload/getExistingLoadDetails",
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe(
      (res) => {
        const response: any = res.body;
        if (response) {
          this.makeTpfFilePath = response.makeTpfFilePath;
          this.loadFilePath = response.loadFilePath;
          this.oldrName = response.oldrName;
          this.userName = response.userName;
          this.password = response.password;
          this.loadSetName = response.loadSetName;
          this.loadInProgress = response.progress;
          if (response.progress) this.validationStatus = true;

          // this.logonPassword = response.logonPassword;
          // this.logonInput = response.logonInput;
          // this.cmdLniata = response.cmdLniata;
          this.options = this.options.map((system) => ({
            name: system.name,
            status: response.systemsArray.includes(system.name),
          }));
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  loadFilePathChanging(event) {
    const loadUrl =
      this.loadFilePath.split("/")[this.loadFilePath.split("/").length - 1];
    if (loadUrl.indexOf(".load") > 1)
      this.loadSetName = loadUrl.replace(".load", "");
  }

  preloadLogs = [];

  getPreloadLogs() {
    var params = {
      url: "preload/getLoadLogs",
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe(
      (res) => {
        const response: any = res.body;
        if (response) {
          this.preloadLogs = response;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  viewLoadHistory(item) {
    const dialogRef = this.dialog.open(LogDispComponent, {
      // data: { value: self.inputFile.join('\n') }
      /**
       * Commanded the above line.
       * Rather than sharing as a string, send the array as it. (PM-157)
       */

      data: { value: item.logData ? item.logData.split("\n") : [] },
    });
  }

  socketListener() {
    this.socket.listeningFunction("preloadInfo").subscribe((data: any) => {
      if (data.completed) {
        new AppComponent(this._snackBar).showPreloadSnackBar(
          data.message,
          "success"
        );
        this.loadInProgress = false;
        this.validationStatus = true;
        this.getPreloadLogs();
      } else {
        if (data.error) {
          new AppComponent(this._snackBar).showPreloadSnackBar(
            data.message,
            "error"
          );
        } else {
          new AppComponent(this._snackBar).showPreloadSnackBar(
            data.message,
            "notes"
          );
        }
      }
    });
  }
  openNetworkLog(script) {
    window.open(
      `/#/${this.selectedSystem}/networkLog/Preload/${script}?total=7`,
      "_blank"
    );
  }
}
