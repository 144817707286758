import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
// import { debounceTime,distinctUntilChanged  } from 'rxjs/operators';

@Component({
  selector: "app-tracedef",
  templateUrl: "./tracedef.component.html",
  styleUrls: ["./tracedef.component.scss"],
})
export class TracedefComponent implements OnInit {
  public _conf;
  @Input() conf;
  @Input() validator;
  public newKeys = [];
  /**
   * Below function is to collect the newly added forms in an arry
   */
  @Input() public set newKeysAdded(val: string) {
    if (val) {
      this.newKeys = [];
      Object.keys(val).map((value) => {
        this.newKeys.push(value);
      });
    }
  }

  // public traceDef: FormGroup;
  // @Output() changedConf = new EventEmitter();
  constructor(private fb: FormBuilder) {
    // this.traceDef = this.fb.group({
    //   "ALL": this.fb.control(false),
    //   "MACRO_GROUPS": this.fb.group({
    //     "CREATE": this.fb.control(false),
    //     "ENTER/BACK": this.fb.control(false),
    //     "FILE": this.fb.control(false),
    //     "FIND": this.fb.control(false),
    //     "GDS": this.fb.control(false),
    //     "POOL": this.fb.control(false),
    //     "RTTAPE": this.fb.control(false),
    //     "SEND": this.fb.control(false),
    //     "SER/SNP": this.fb.control(false),
    //     "STORAGE": this.fb.control(false),
    //   }),
    //   "TPFDF": this.fb.control(false),
    //   "INPUT": this.fb.control(false),
    //   "OUTPUT": this.fb.control(false)
    // })
  }

  ngOnInit() {
    // this.onChanges();
  }

  selectAll(event) {
    console.log("pppppp");
    if (event) {
      this.conf.get("MACRO_GROUPS").get("CREATE").setValue(false);
      this.conf.get("MACRO_GROUPS").get("ENTER/BACK").setValue(false);
      this.conf.get("MACRO_GROUPS").get("FILE").setValue(false);
      this.conf.get("MACRO_GROUPS").get("FIND").setValue(false);
      this.conf.get("MACRO_GROUPS").get("GDS").setValue(false);
      this.conf.get("MACRO_GROUPS").get("POOL").setValue(false);
      this.conf.get("MACRO_GROUPS").get("RTTAPE").setValue(false);
      this.conf.get("MACRO_GROUPS").get("SEND").setValue(false);
      this.conf.get("MACRO_GROUPS").get("SER/SNP").setValue(false);
      this.conf.get("MACRO_GROUPS").get("STORAGE").setValue(false);
      this.conf.get("MACRO_GROUPS").get("TPFDF").setValue(false);
    }
  }
  // ngOnChanges(changes) {
  //   if (JSON.stringify(changes.conf.previousValue) != JSON.stringify(this._conf)) {
  //     var trc = this._conf["TRACEDEF"];
  //     this.traceDef.patchValue(trc)
  //   }
  // }

  // onChanges() {
  //   // this.traceDef.get('ALL').valueChanges
  //   //   .subscribe(changesMade => {

  //   //     if (changesMade) {
  //   //       this.traceDef.get('MACROGROUP').disable();
  //   //     } else {
  //   //       this.traceDef.get('MACROGROUP').enable();
  //   //     }

  //   //   })
  //     this.traceDef.valueChanges
  //     .pipe(
  //       debounceTime(500),
  //       distinctUntilChanged(),
  //     )
  //     .subscribe(changesmade=>{
  //       // console.log("Value chnages in Trace def")
  //       // console.log(this.traceDef.value)
  //       this._conf["TRACEDEF"] ={...this._conf["TRACEDEF"],...this.traceDef.value}
  //       this.changedConf.emit(this._conf)
  //     })

  // }
}
