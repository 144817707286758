import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchScript'
})
export class SearchScriptPipe implements PipeTransform {

  //Pipe function user to filter log name and username with the given  search value.(PD-6)
  transform(value: any, searchString: String): any {
    console.log(value);

    var data = JSON.parse(JSON.stringify(value))

    if (data[0]['scriptData'] != undefined) {
      if (searchString != null && searchString != undefined && data != undefined && searchString != "") {
        data.forEach(function (ele) {
          ele["scriptData"] = ele["scriptData"].filter(function (element) {
            if (element.name.toLowerCase().includes(searchString.toLowerCase())) {
              //	Filtering by username. 
              return element
            } else if (element.user['_id']['user'].toLowerCase().includes(searchString.toLowerCase())) {
              //	Filtering by log name. 
              return element
            }
          })
        })
      }
      data = data.filter((a, index) => {
        a['ArrayIndex'] = index;
        return a["scriptData"].length > 0
      })
    } else {
      data = data.filter(function (element, index) {
        if (element.name.toLowerCase().includes(searchString.toLowerCase())) {
          return element
        } else if (element.user['_id']['user'].toLowerCase().includes(searchString.toLowerCase())) {
          return element
        }
      })

    }
    if (data.length == 0) { data = ['No search result'] }
    console.log(data);

    return data;
  }

}
