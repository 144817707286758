import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RunViewComponent } from './run-view/run-view.component';



const routes: Routes = [ 
  // {path:'',redirectTo:'/runscript',pathMatch:'full'},
{path:'',component:RunViewComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RunViewRoutingModule { }
