import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hex'
})
export class HexPipe implements PipeTransform {

  transform(value: string): any {
    var convertedId = parseInt(value.substr(2, 6), 16).toString()
    var decId = "TX" + convertedId.padStart(6, '0');
    return decId;
  }
}
