import { NetworkLogComponent } from "./network-log.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { MatGridListModule } from "@angular/material/grid-list";
import { HttpClientModule } from "@angular/common/http";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { NetworkLazyloadDirective } from "./networklazyload.directive";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { NzSelectModule } from "ng-zorro-antd/select";

const routes: Routes = [
  {
    path: "",
    component: NetworkLogComponent,
    // children: [
    //   { path: 'generateTree', component: GenerateTreeComponent }
    // ]
  },
];

@NgModule({
  declarations: [NetworkLogComponent, NetworkLazyloadDirective],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatGridListModule,
    HttpClientModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    ScrollingModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    NzSelectModule,
  ],
})
export class NetworkLogModule {}
