import { HttpClient } from "@angular/common/http";
import { Component, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, startWith, switchMap } from "rxjs";
import { of } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-network-log",
  templateUrl: "./network-log.component.html",
  styleUrls: ["./network-log.component.scss"],
})
export class NetworkLogComponent {
  executionName: string = "";
  executionId: string = "";
  phase1ExecutionData: any = [];
  phase2ExecutionData: any = [];

  selectedTransaction = "ALL";

  public httpAdapter: HttpAdapterService;
  @ViewChildren(CdkVirtualScrollViewport)
  virtualScroll: QueryList<CdkVirtualScrollViewport>;

  constructor(
    private route: ActivatedRoute,
    public http: HttpClient,
    private clipboard: Clipboard
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }
  copyToClipboard(data) {
    this.clipboard.copy(data);
  }
  currentTabIndex = 0;
  tabChange(tabChangeEvent: MatTabChangeEvent): void {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    //  console.log('index => ', tabChangeEvent.index);
  }
  limit = 100;
  phase1offset = 0;
  endReached = false;
  show133 = "";
  show70 = "";
  show75 = "";
  show98 = "";
  show1 = "";

  loadMorePhase1Data(last, index) {
    if (
      !this.phase1EndReached &&
      this.phase1ExecutionData.length - this.limit / 2 === index + 1
    ) {
      this.getPhase1ExecutionData();
    } else if (
      this.phase1EndReached &&
      this.phase1ExecutionData.length - 5 === index + 1
    ) {
      this.getPhase1ExecutionData();
    }

    // if (this.currentScript.progress !== "PROGRESS" && last && !this.endReached) {
    // this.getExecutionData(this.limit, this.offset + this.limit)
    // this.offset += this.limit
    // }
  }
  filteredOptions;
  wholeTrasactions = [];
  totalTransactions = 100;
  getArray() {
    this.wholeTrasactions = Array(this.totalTransactions)
      .fill(1)
      .map((ele, i) => "TX" + (i + 1).toString().padStart(6, "000000"));
  }
  loadMorePhase2Data(last, index) {
    if (
      !this.phase2EndReached &&
      this.phase2ExecutionData.length - this.limit / 2 === index + 1
    ) {
      this.getPhase2ExecutionData();
    } else if (
      this.phase2EndReached &&
      this.phase2ExecutionData.length - 5 === index + 1
    ) {
      this.getPhase2ExecutionData();
    }

    // if (this.currentScript.progress !== "PROGRESS" && last && !this.endReached) {
    // this.getExecutionData(this.limit, this.offset + this.limit)
    // this.offset += this.limit
    // }
  }
  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.executionName = params.get("name");
          this.executionId = params.get("id");
          return of(this.executionId);
        })
      )
      .subscribe((executionId) => {
        this.totalTransactions = parseInt(
          this.route.snapshot.queryParamMap.get("total"),
          10
        );
        this.getPhase1ExecutionData();
        this.getPhase2ExecutionData();
        this.getArray();

        // Do something with the executionName if needed
      });
  }
  trackByFn(index, item) {
    return index; // Assuming your item has a unique 'id' property
  }
  phase1EndReached = false;
  getPhase1ExecutionData() {
    var params = {
      url: `network/getNetworkLog?id=${this.executionId}&limit=${
        this.limit
      }&offset=${this.phase1offset}&phase=PHASE1&transactionId=${
        this.selectedTransaction === "ALL" ? "" : this.selectedTransaction
      }&show133=${this.show133}&show70=${this.show70}&show75=${
        this.show75
      }&show98=${this.show98}&show1=${this.show1}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe((res) => {
      if (this.phase1offset === 0) {
        this.phase1ExecutionData = res.body;
        const response: any[] = Array(res.body);
        // this.offset = this.limit;
        this.phase1offset += response[0].length;
        if (response[0].length < this.limit) this.phase1EndReached = true;
        else this.phase1EndReached = false;
      } else {
        const response: any[] = Array(res.body);
        this.phase1offset += response[0].length;

        this.phase1ExecutionData = this.phase1ExecutionData.concat(response[0]);
        if (response[0].length < this.limit) this.phase1EndReached = true;
        else this.phase1EndReached = false;
      }
    });
  }
  phase2EndReached = false;
  phase2offset = 0;
  getPhase2ExecutionData() {
    var params = {
      url: `network/getNetworkLog?id=${this.executionId}&limit=${
        this.limit
      }&offset=${this.phase2offset}&phase=PHASE2&transactionId=${
        this.selectedTransaction === "ALL" ? "" : this.selectedTransaction
      }&show133=${this.show133}&show70=${this.show70}&show75=${
        this.show75
      }&show98=${this.show98}&show1=${this.show1}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe((res) => {
      if (this.phase2offset === 0) {
        this.phase2ExecutionData = res.body;
        const response: any[] = Array(res.body);
        // this.offset = this.limit;
        this.phase2offset += response[0].length;
        if (response[0].length < this.limit) this.phase2EndReached = true;
        else this.phase2EndReached = false;
      } else {
        const response: any[] = Array(res.body);
        this.phase2offset += response[0].length;

        this.phase2ExecutionData = this.phase2ExecutionData.concat(response[0]);
        if (response[0].length < this.limit) this.phase2EndReached = true;
        else this.phase2EndReached = false;
      }
    });
  }
}
