<div
  [ngStyle]="{ color: brand_color, background: brand, }"
  class="col-12 card p-0 m-0 utilization-card-container row"
>
  <div class="col-12 p-0" style="position: relative">
    <h5
      class="typography"
      [ngStyle]="{ color: brand_color, }"
      style="text-decoration: underline; cursor: pointer"
      (click)="viewLniata.emit('true')"
    >
      {{ label }} ({{ value }})
    </h5>
    <button
      (click)="deleteLniata.emit('true')"
      title="Delete Lniata"
      style="position: absolute; bottom: 2px; right: 2px"
      class="btn btn-none p-0 pt-2"
    >
      <mat-icon style="color: red; font-size: 20px">delete</mat-icon>
    </button>
  </div>
</div>
