import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  ViewChildren,
  ViewChild,
  ElementRef,
  QueryList,
  ViewEncapsulation,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import * as _ from "underscore";
import { HexPipe } from "../hex.pipe";
import { FormControl } from "@angular/forms";
import { ShareDataService } from "../../_services/share-data.service";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, of, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";

interface dropDownList {
  scriptName: string;
  parentScript: string;
  subScript: boolean;
}
@Component({
  selector: "app-exec-log",
  templateUrl: "./exec-log.component.html",
  styleUrls: ["./exec-log.component.scss"],
})
export class ExecLogComponent implements OnInit {
  execLog;
  pokemonControl = new FormControl("");
  selectedValue: string;
  ScrollOption = false;
  ph1Length: any;
  ph2Length: any;
  private observer: IntersectionObserver;
  public sharedData = {};
  subscription: any;

  sharedServicesFunction() {
    var self = this;
    self.subscription = self.sharedServices.currentData$.subscribe((data) => {
      self.sharedData = data;
    });
  }
  trackByFn(index, item) {
    return item.transId;
  }
  activeSubScript = "";
  loadSubScript(item) {
    this.activeSubScript = item;
  }

  @ViewChildren("phaseData") execFocus: QueryList<any>;
  @ViewChildren(CdkVirtualScrollViewport)
  virtualScroll: QueryList<CdkVirtualScrollViewport>;

  @Input() public set selectedScript(val: string) {
    if (val != undefined) {
      if (Object.keys(val).length != 0) this.filterMethod(val);
    }
  }

  @Input() currentScript;
  @Input() executionId;
  public selectedSystem: string = "";
  // public phaseArray = [];
  @Output() public sendData = new EventEmitter();

  @Input() changing: Subject<boolean>;
  @Input() filterChanging: Subject<{
    script: Object;
    subScriptStatus: Boolean;
  }>;

  ph1Loading = false;
  ph2Loading = false;

  moveToTrace(transId) {
    const hex = new HexPipe();
    window.open(
      `/#/${this.selectedSystem}/traceData?scriptName=${
        this.currentScript.name
      }&scriptId=${this.executionId}&transId=${hex.transform(transId)}&total=${
        this.currentScript.percentage.PHASE1Total |
        this.currentScript.percentage.PHASE2Total
      }`,
      "_blank"
    );
  }
  loadMoreData(transId, phase) {
    // const transId = parseInt(tnxId.substr(2, 6), 16);

    //   this.ph1Offset += this.ph1Limit;
    //   this.ph2Offset += this.ph2Limit;
    if (
      phase === "PH1" &&
      (transId === this.ph1Count - 100 || transId === this.ph1Count)
    ) {
      if (this.currentScript.progress == "PROGRESS") {
        this.sendData.emit();
      }
      this.ph1Loading = true;
      this.getMoreExecutionData(this.ph1Limit, this.ph1Offset, 0, 0, phase);
    }
    if (
      phase === "PH2" &&
      (transId === this.ph2Count - 100 || transId === this.ph2Count)
    ) {
      if (this.currentScript.progress == "PROGRESS") {
        this.sendData.emit();
      }
      this.ph2Loading = true;

      this.getMoreExecutionData(0, 0, this.ph2Limit, this.ph2Offset, phase);
    }
  }
  execLog1;
  getMoreExecutionData(ph1Limit, ph1Offset, ph2Limit, ph2Offset, Phase) {
    var params = {
      url: `execution/getExecutionLogs?id=${this.executionId}&ph1Limit=${ph1Limit}&ph2Limit=${ph2Limit}&ph1Offset=${ph1Offset}&ph2Offset=${ph2Offset}&fileName=${this.filteredScript}&subScriptName=${this.filteredSubScript}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe(
      (data, error) => {
        if (data) {
          if (Phase === "PH1") {
            this.PH1Data = [...this.PH1Data, ...data.body.executionData.PH1];
            this.ph1Offset += data.body.executionData.PH1.length;
            this.ph1Count += data.body.executionData.PH1.length;

            if (data.body.executionData.PH1.length < this.ph1Limit)
              this.phase1EndReached = true;
            else this.phase1EndReached = false;

            this.ph1Length = data.body.executionData["phase1Data"]
              .toString()
              .padStart(4, (0).toString());
            this.ph2Length = data.body.executionData["phase2Data"]
              .toString()
              .padStart(4, (0).toString());
            this.ph1Loading = false;
            this.cdk.detectChanges();
          }
          if (Phase === "PH2") {
            this.PH2Data = [...this.PH2Data, ...data.body.executionData.PH2];
            this.ph2Offset += data.body.executionData.PH2.length;
            this.ph2Count += data.body.executionData.PH2.length;

            if (data.body.executionData.PH2.length < this.ph2Limit)
              this.phase2EndReached = true;
            else this.phase2EndReached = false;
            this.ph2Loading = false;
            this.cdk.detectChanges();
          }
        } else {
          console.log(error);
          this.ph1Loading = false;
          this.ph2Loading = false;
        }
      },
      (error) => console.log(error)
    );
    // this.fullDataTofilter = JSON.stringify(this.execLog);
  }

  wholeExecutionData: any = {};

  // @Input() set LogData(value) {

  //   this.phaseArray = [];
  //   this.ph1Length = value["executionData"]["PH1"].length.toString().padStart(4, (0).toString());
  //   this.ph2Length = value["executionData"]["PH2"].length.toString().padStart(4, (0).toString());
  //   console.log('value["executionData"]', value["executionData"], 'ph1Length', this.ph1Length)
  //   var valid = false
  //   var count = ''
  //   var valideCount = this.ph1Length || this.ph2Length
  //   valideCount.split('').map((a) => {

  //     if (a != '0' && valid == false) {
  //       valid = true
  //     }
  //     if (valid) {
  //       count = count + a
  //     }

  //   })
  //   if (Number(count) > 4) {
  //     this.ScrollOption = true
  //   } else {
  //     this.ScrollOption = false

  //   }
  //   this.wholeExecutionData = value["executionData"];
  //   this.executionStatus = value["executionData"].executionMsg.state;

  //   this.getExecutionData(this.limit)
  // };
  loading = false;
  ph1Limit = 2000;
  ph2Limit = 2000;
  ph1Offset = 0;
  ph2Offset = 0;
  phase1EndReached = false;
  phase2EndReached = false;
  ph1Count = 0;
  ph2Count = 0;
  PH1Data = [];
  PH2Data = [];
  getExecutionData(script?: String, subScript?: String) {
    this.PH1Data = [];
    this.PH2Data = [];
    this.loading = true;

    var params = {
      url: `execution/getExecutionLogs?id=${this.executionId}&ph1Limit=${this.ph1Limit}&ph2Limit=${this.ph2Limit}&ph1Offset=${this.ph1Offset}&ph2Offset=${this.ph2Offset}&fileName=${script}&subScriptName=${subScript}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe(
      (data, error) => {
        if (data) {
          this.PH1Data = data.body.executionData.PH1;
          this.PH2Data = data.body.executionData.PH2;
          // this.phaseArray = [];
          this.ph1Length = data.body.executionData.phase1Data
            .toString()
            .padStart(4, (0).toString());
          this.ph2Length = data.body.executionData.phase2Data
            .toString()
            .padStart(4, (0).toString());

          if (Number(this.ph2Length | this.ph2Length) > 4) {
            this.ScrollOption = true;
          } else {
            this.ScrollOption = false;
          }
          // this.execLog1 = this.execLog;
          // this.wholeExecutionData = this.execLog;
          this.ph1Loading = false;
          this.ph2Loading = false;
        } else {
          console.log(error);
          this.ph1Loading = false;
          this.ph2Loading = false;
        }
        this.loading = false;
        // if (this.lastIndexData) {
        //   const requireIndex = document.getElementById(this.lastIndexData);
        //   document
        //     .getElementById("execLogContainer")
        //     .scrollTo({ top: requireIndex.scrollTop });
        // }
      },
      (error) => console.log(error)
    );
    // this.fullDataTofilter = JSON.stringify(this.execLog);

    console.log("execLog", this.execLog);
    // setTimeout(() => {
    //   this.sendData.emit(this.scriptDropdownList)
    // });
  }
  DownArrow = true;
  UpArrow = false;

  scriptDropdownList: dropDownList[] = [];
  httpAdapter;
  constructor(
    private sharedServices: ShareDataService,
    private route: ActivatedRoute,
    public router: Router,
    public cdk: ChangeDetectorRef,
    public http: HttpClient
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }
  fullDataTofilter: any;
  ngOnInit(): void {
    this.execLog = [];
    this.ph1Loading = true;
    this.ph2Loading = true;

    this.getExecutionData("", "");
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      this.sharedData = data;
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      )
        this.selectedSystem = data["selectedSystem"];
    });

    this.changing.subscribe((v) => {
      this.refreshData();
    });
    this.filterChanging.subscribe((data) => {
      this.detectFilterChange(data);
    });
  }

  filteredScript = "";
  filteredSubScript = "";

  detectFilterChange(data) {
    const { script, subScriptStatus } = data;
    if (script) {
      this.filteredScript = script.FileName;
      this.filteredSubScript = subScriptStatus ? script.subScript : "";
      this.ph1Offset = 0;
      this.ph2Offset = 0;
      this.PH1Data = [];
      this.PH2Data = [];
      this.ph1Loading = true;
      this.ph2Loading = true;

      this.getExecutionData(
        script.FileName,
        subScriptStatus ? script.subScript : ""
      );
    } else {
      this.filteredScript = "";
      this.filteredSubScript = "";
      this.ph1Offset = 0;
      this.ph2Offset = 0;
      this.ph1Loading = true;
      this.ph2Loading = true;
      this.getExecutionData("", "");
    }
  }
  lastIndexData;
  refreshData() {
    this.lastIndexData =
      this.PH1Data.length > this.PH2Data.length
        ? `executionph2${this.PH2Data.length}`
        : `executionph1${this.PH1Data.length}`;
    this.ph1Offset = 0;
    this.ph2Offset = 0;
    this.getExecutionData("", "");
    // }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public findKeys(obj) {
    if (obj) return Object.keys(obj);
    else return [];
  }

  filterMethod(event) {
    this.execLog = JSON.parse(this.fullDataTofilter);

    if (event != "ALL") {
      this.execLog["PH1"] = this.execLog["PH1"].filter((e) => {
        return e.filename == event.parentScript;
      });

      this.execLog["PH2"] = this.execLog["PH2"].filter((e) => {
        return e.filename == event.parentScript;
      });
      if (event.scriptName != event.parentScript) {
        if (this.execLog["PH1"].length > 0)
          this.execLog["PH1"][0]["array_of_json"] = [
            ...this.execLog["PH1"][0]["array_of_json"].filter((e) => {
              return e.subScriptname == event.scriptName;
            }),
          ];
        if (this.execLog["PH2"].length > 0)
          this.execLog["PH2"][0]["array_of_json"] = [
            ...this.execLog["PH2"][0]["array_of_json"].filter((e) => {
              return e.subScriptname == event.scriptName;
            }),
          ];
      }
    }
    console.log("this.execLog", this.execLog);
    console.log("this.fullDataTofilter", this.fullDataTofilter);
  }
  /**
   * This function groups a list of objects with a given key (PD-36)
   */

  getArray(data) {
    return Array(data.length).fill("");
  }

  public groupBykey(givenArray, key) {
    givenArray = givenArray.reduce((group, Obj) => {
      group[Obj[key]] = group[Obj[key]] ?? [];
      group[Obj[key]].push(Obj);
      return group;
    }, {});
    return givenArray;
  }

  public phaseCount(phaseData) {
    var self = this;

    return phaseData.length.toString().padStart(4, (0).toString());
  }

  public isSinglePhase(data) {
    return !(_.has(data, "PH1") && _.has(data, "PH2"));
  }

  public isJson(data) {
    try {
      if (_.isObject(JSON.parse(data))) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  public displayFormatedOutput(data) {
    try {
      if (_.isObject(JSON.parse(data))) {
        return JSON.stringify(JSON.parse(data), null, 4);
      } else {
        return data;
      }
    } catch (error) {
      return data;
    }
  }

  scrollTop() {
    this.virtualScroll.toArray().forEach((el) => el.scrollToIndex(0, "smooth"));

    setTimeout(() => {
      this.UpArrow = false;
      this.DownArrow = true;
    }, 500);
  }

  scrollDown() {
    this.virtualScroll.toArray().forEach((el) =>
      el.scrollTo({
        bottom: 0,
        behavior: "smooth",
      })
    );

    setTimeout(() => {
      this.DownArrow = false;
      this.UpArrow = true;
    }, 500);
  }
}
