import { Injectable } from "@angular/core";
import { inject } from "@angular/core";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { ShareDataService } from "../_services/share-data.service";
import { SocketioClientServices } from "../_services/socketio-client.services";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public httpAdapter: HttpAdapterService;
  cookieService = inject(CookieService);

  constructor(
    public http: HttpClient,
    public router: Router,
    private socket: SocketioClientServices,
    public dialog: MatDialog
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  getToken() {
    const cookieExists: boolean = this.cookieService.check("virusGHitter");
    let token = cookieExists ? this.cookieService.get("virusGHitter") : null;
    return token;
  }

  getPayload() {
    if (this.getToken()) {
      return jwtDecode(this.cookieService.get("virusGHitter"));
    }
  }


  logout() {
    const params = {
      url: "logout",
    };

    const cookieExists: boolean = this.cookieService.check("virusGHitter");
    this.httpAdapter.httpGet(params, (err, result) => {
      if (!err) {
        cookieExists
          ? this.cookieService.delete("virusGHitter")
          : this.cookieService.deleteAll();

        this.socket.disConnectSocket();
        this.router.navigateByUrl('/login').then(() => {
          //TODO: Check for Reload
          window.location.reload();
        });

      } else {
        console.log("Error in executing api", err);
      }
    });
  }
}
