import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LibraryManagementComponent } from "./library-management.component";
import { RouterModule, Routes } from "@angular/router";
import { MatGridListModule } from "@angular/material/grid-list";
import { HttpClientModule } from "@angular/common/http";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";

const routes: Routes = [
  {
    path: "",
    component: LibraryManagementComponent,
    // children: [
    //   { path: 'generateTree', component: GenerateTreeComponent }
    // ]
  },
];

@NgModule({
  declarations: [LibraryManagementComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatGridListModule,
    HttpClientModule,
    MatCardModule,
    MatIconModule,
  ],
})
export class LibraryManagementModule { }
