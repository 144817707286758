<div class="scriptConatiner" [ngClass]="{ minimize: minimize }">
  <div
    class="innerAside"
    [ngClass]="{
      minimize: minimize,
      innergrid1: runValidation,
      innergrid2: !runValidation
    }"
  >
    <div class="toggleGrid">
      <!--
                Block of Code for:
                Toggling Option B/w RunView and Validate View
             -->
      <div class="switchToggle">
        <div class="btn-group btn-toggle">
          <ng-container *ngIf="sharedData['view'] == 'Live'">
            <a
              class="btn btn-xs toggBtn"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              [href]="['#/' + selectedSystem + '/scripts/runScripts']"
              [ngClass]="{ active: !runValidation }"
            >
              <span
                ><span class="count"> </span
                ><span class="btn_toggle-text">RUN</span></span
              >
            </a>
            <a
              [ngStyle]="{ 'pointer-events': scripts.length ? 'auto' : 'none' }"
              class="btn btn-xs toggBtn"
              [href]="[
                '#/' +
                  selectedSystem +
                  '/scripts/validate/' +
                  currentScriptIndex
              ]"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              [ngClass]="{ active: runValidation }"
            >
              <span>
                <!-- <span class="count">{{ scripts.length }}</span> -->
                <span class="btn_toggle-text">VALIDATE</span></span
              >
            </a>
          </ng-container>
          <!-- <ng-container *ngIf="sharedData['view'] == 'Report'">
            <div class="reportFreezeId">{{ sharedData["freezeId"] }}</div>
          </ng-container>
           -->
        </div>
      </div>

      <button
        *ngIf="sharedData['view'] == 'Report'"
        mat-icon-button
        class="backToFreezeIdList"
        (click)="backToFreezeIdList()"
      >
        <!-- <mat-icon>favorite</mat-icon> -->
        <img src="assets/Images/backToFreezeId.png" />
      </button>
      <button
        *ngIf="sharedData['view'] == 'Live'"
        class="backToFreezeIdList"
        (click)="minimizeWindows()"
      >
        <mat-icon fontIcon="view_quilt"></mat-icon>
      </button>

      <!-- Date filter (PD-7) -->

      <div *ngIf="runValidation">
        <div
          class="datepicker"
          style="-webkit-text-fill-color: rgb(248, 248, 252)"
        >
          <mat-form-field
            appearance="outline"
            style="margin-top: 10px; width: 90%; height: 8vh"
          >
            <mat-label class="matLabel">Log History</mat-label>
            <input
              matInput
              [matDatepicker]="datePicker"
              class="dateInput"
              [(ngModel)]="selectedDate"
              [max]="maxdate"
              [disabled]="true"
              (dateInput)="dateFilterForLog($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              class="datePicker"
              #datePicker
              disabled="false"
            ></mat-datepicker>
            <br />
          </mat-form-field>
        </div>
      </div>
    </div>

    <!--
                Block of Code for:
                Showing Search and Filter Options in the Validate View
         -->

    <ng-container>
      <input
        class="selectAll"
        [ngClass]="{ ReportCheckBox: sharedData['view'] == 'Report' }"
        type="checkbox"
        *ngIf="sharedData['view'] == 'Report'"
        [(ngModel)]="selectall"
        (change)="selectAllScripts()"
      />

      <div
        class="input-group ScriptSearch rounded-pill row"
        *ngIf="search && runValidation"
      >
        <span class="searchIcon">
          <i class="fa fa-search" aria-hidden="true"></i>
        </span>
        <input
          type="text"
          #searchField
          placeholder="Search for script"
          (input)="changeSearchData($event.target.value)"
          [(ngModel)]="searchScriptFilter"
        />
        <span class="doneIcon">
          <!-- <mat-icon (click)="closeSearch()" style="cursor: pointer;">close icon</mat-icon> -->
          <i
            class="fa-thin fa fa-times"
            aria-hidden="true"
            (click)="closeSearch()"
          ></i>
          <!-- <i class="fa-light fa-xmark" ></i> -->
        </span>
      </div>
      <!-- <mat-form-field class="ScriptSearch" *ngIf="search">
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" matInput placeholder="Search for a script" [(ngModel)]="searchScriptFilter">
                <mat-icon matSuffix (click)="closeSearch()" style="cursor: pointer;">done</mat-icon>
            </mat-form-field> -->
      <button
        type="button"
        *ngIf="!search && runValidation"
        class="btn m-0 mt-1 runByMeSearch rounded-btn"
        [title]="userSearch ? 'All' : 'Run by me'"
        (click)="userSearch = !userSearch; offset = 0; getScripts()"
        [ngClass]="{
          activeUserSearch: userSearch,
          
        }"
      >
        <i
          class="fa-thin fa fa-user"
          aria-hidden="true"
          style="color: white; font-size: 16px"
        ></i>
      </button>
      <button
        *ngIf="!search && runValidation"
        type="button"
        class="btn searchButton"
        (click)="moveFocus()"
      >
        <img class="" src="assets/Images/scriptsSearch.png" />
      </button>
      <button
        type="button"
        class="btn filterButton"
        *ngIf="runValidation"
        [matMenuTriggerFor]="menu"
        (click)="showSortingOptions()"
      >
        <img class="" src="assets/Images/filter.png" />
      </button>
      <mat-menu #menu="matMenu" overlapTrigger="false">
        <mat-card class="sortView">
          <mat-card-content
            class="sortContent"
            (click)="$event.stopPropagation()"
          >
            <!-- <mat-menu #menu="matMenu"> -->
            <section class="example-section">
              <mat-checkbox
                color="primary"
                [(ngModel)]="filters[0]"
                class="example-margin"
                >COMPLETED</mat-checkbox
              >
            </section>
            <section class="example-section">
              <mat-checkbox
                color="primary"
                [(ngModel)]="filters[1]"
                class="example-margin"
                >IN PROGRESS</mat-checkbox
              >
            </section>

            <section class="example-section">
              <mat-checkbox
                color="primary"
                [(ngModel)]="filters[2]"
                class="example-margin"
                >YET TO REVIEWED</mat-checkbox
              >
            </section>

            <section class="example-section">
              <mat-checkbox
                color="primary"
                [(ngModel)]="filters[3]"
                class="example-margin"
                >ABORTED</mat-checkbox
              >
            </section>
            <hr />
            <section class="example-section">
              <mat-checkbox
                color="primary"
                [(ngModel)]="filters[4]"
                class="example-margin"
                >PASS</mat-checkbox
              >
            </section>

            <section class="example-section">
              <mat-checkbox
                color="primary"
                [(ngModel)]="filters[5]"
                class="example-margin"
                >FAIL</mat-checkbox
              >
            </section>
          </mat-card-content>
        </mat-card>
        <button class="applyFilters" (click)="submitFilters()">
          Apply filters
        </button>
      </mat-menu>
    </ng-container>

    <!--
                Block of Code for:
                Displaying List of Scripts of RunView Tab
         -->
    <div style="width: 100%; background-color: #273eae" *ngIf="!runValidation">
      <ng-container *ngIf="!runValidation">
        <div style="display: flex; width: 100%; justify-content: space-between">
          <button
            class="addScriptButton mt-3"
            (click)="addScript()"
            style="width: 50%"
            *ngIf="sharedData['view'] == 'Live'"
          >
            Add Config
          </button>

          <div
            style="width: 50%"
            class="input-groupnew ConfigSearch rounded-pill row mt-3"
            *ngIf="configSearch && !runValidation"
          >
            <span class="searchIcon">
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              #configSearchField
              class="inputField"
              placeholder="Search config"
              [(ngModel)]="searchConfigFilter"
            />
            <span class="doneIcon">
              <i
                class="fa-thin fa fa-times"
                aria-hidden="true"
                (click)="closeConfigSearch()"
              ></i>
            </span>
          </div>
          <button
            *ngIf="!configSearch && !runValidation"
            type="button"
            class="btn scriptsearchButton"
            (click)="moveConfigFocus()"
          >
            <img class="" src="assets/Images/scriptsSearch.png" />
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="listScripts.length > 0">
        <div
          class="scriptListViewNew"
          style="
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 85vh;
            align-items: center;
          "
        >
          <div
            class="card script Run"
            [ngClass]="{
              activeScript: currentRunScript['_id'] == lscript._id,
              notActive: currentRunScript['_id'] != lscript._id
            }"
            *ngFor="
              let lscript of listScripts | configSearch : searchConfigFilter;
              let scriptIndex = index
            "
          >
            <div *ngIf="lscript == 'No search result'">
              <div
                class="text-center mt-4"
                style="color: white; font-weight: bold"
              >
                No search result
              </div>
            </div>
            <div
              *ngIf="lscript != 'No search result'"
              (click)="loadRunScript(lscript)"
              class="card-body p-0"
            >
              <div class="scriptDataArea">
                <div class="scriptName">
                  <div class="scriptNameTag">
                    <span class="card-title">{{ lscript["scriptName"] }}</span>
                  </div>

                  <div class="userId mr-2" style="color: white">
                    <mat-icon
                      style="
                        color: white;
                        font-size: 20px;
                        height: 20px;
                        width: 20px;
                      "
                      >perm_identity</mat-icon
                    >
                    <p
                      style="
                        line-height: 20px;
                        font-size: 10px;
                        font-weight: bold;
                      "
                      class="m-0"
                    >
                      {{ lscript["conf"]["GENERAL"]["USER"] | uppercase }}
                    </p>
                  </div>
                </div>
                <div class="scriptStatusDetails pt-1 pb-1">
                  <span
                    class="label label-success completed"
                    *ngIf="
                      lscript['scriptRunStatus'] != null &&
                      lscript['scriptRunStatus'] == 'Completed'
                    "
                    >COMPLETED</span
                  >
                  <span
                    class="YetToValidate"
                    *ngIf="
                      lscript['scriptRunStatus'] != null &&
                      lscript['scriptRunStatus'] == 'Not Runned'
                    "
                  >
                    NEW Script</span
                  >
                  <span
                    class="label label-danger fail"
                    *ngIf="
                      lscript['scriptRunStatus'] != null &&
                      lscript['scriptRunStatus'] == 'Failed'
                    "
                    >FAILED</span
                  >
                  <span
                    *ngIf="lscript['scriptRunStatus'] == 'inProgress'"
                    class="inProgress"
                    >INPROGRESS</span
                  >
                  <span
                    *ngIf="lscript['scriptRunStatus'] == 'ABORTED'"
                    class="aborted"
                    >ABORTED</span
                  >
                </div>

                <div
                  class="scriptTimeDetails"
                  *ngIf="lscript['scriptRunStatus'] != 'Not Runned'"
                >
                  <span class="activeStartTime"
                    >Start: {{ lscript.startTime | date : "hh:mm" }}
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style="
                        width: 3px;
                        height: 5.8px;
                        color: #ffffff;
                        margin-left: 11px;
                        margin-right: 11px;
                        -webkit-text-stroke: 1px;
                      "
                    ></i
                  ></span>

                  <div
                    class="flowStatus Percentage"
                    *ngIf="lscript['scriptRunStatus'] == 'inProgress'"
                  >
                    <ng-container *ngIf="lscript.conf['GENERAL']['RUNPH1']">
                      PH1:{{ lscript.percentage["PHASE1"] }} %
                    </ng-container>
                    &nbsp;&nbsp;
                    <ng-container *ngIf="lscript.conf['GENERAL']['RUNPH2']">
                      PH2:{{ lscript.percentage["PHASE2"] }} %
                    </ng-container>
                  </div>
                  <div
                    class="flowStatus w-50 text text-truncate"
                    mat-raised-button
                    #tooltip="matTooltip"
                    [matTooltip]="lscript.progressMsg"
                    *ngIf="lscript['scriptRunStatus'] == 'ABORTED'"
                  >
                    {{ lscript.progressMsg }}
                  </div>
                  <span
                    class="activeEndTime"
                    *ngIf="lscript['scriptRunStatus'] == 'Completed'"
                    >End: {{ lscript.endTime | date : "hh:mm" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!--
                Block of Code for:
                Displaying List of Scripts of ValidateView Tab
         -->
    <div class="scriptListView" *ngIf="runValidation">
      <ng-container *ngIf="scripts.length <= 0">
        <br />
        <br />
        <div class="text-center" style="color: white; font-weight: bold">
          No Data Found
        </div>
      </ng-container>
      <ng-container *ngIf="scripts.length > 0">
        <!-- <ng-container>
                    <input class="selectAll" [ngClass]='{"ReportCheckBox":sharedData["view"]=="Report"}' type="checkbox" *ngIf="sharedData['view']=='Report'"
                        [(ngModel)]="selectall" (change)="selectAllScripts()">
                    <mat-form-field class="ScriptSearch" *ngIf="search">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" matInput placeholder="Search for a script" [(ngModel)]="searchScriptFilter">
                        <mat-icon matSuffix (click)="closeSearch()" style="cursor: pointer;">done</mat-icon>
                    </mat-form-field>
                    <button *ngIf="!search" type="button" class="btn searchButton" (click)="search=!search">
                        <img class="" src="assets/Images/scriptsSearch.png"></button>
                    <button type="button" class="btn filterButton"> <img class=""
                            src="assets/Images/filter.png"></button>
                </ng-container> -->

        <ng-container>
          <!-- <div
            class="dateContainer"
            [ngClass]="{ ReportDate: sharedData['view'] == 'Report' }"
          >
            <div
              class="date"
              [ngClass]="{ dateReport: sharedData['view'] == 'Report' }"
            >
              {{ formatDate(groupedScripts[ScriptIndex1]["Date"]) }}
            </div>
          </div> -->

          <cdk-virtual-scroll-viewport
            [itemSize]="50"
            class="scriptDateGroupContainer"
            id="scriptDataDisplayContainer"
            style="
              display: flex;
              align-items: center;
              height: 78vh;
              justify-content: center;
            "
          >
            <ng-container
              *ngFor="
                let scriptDate of scripts;
                let dateIndex = index;
                trackBy: trackItem
              "
            >
              <div class="dateContainerWrapper">
                <div
                  class="dateContainer"
                  [ngClass]="{ ReportDate: sharedData['view'] == 'Report' }"
                >
                  <div
                    class="date"
                    [ngClass]="{ dateReport: sharedData['view'] == 'Report' }"
                  >
                    {{ formatDate(scriptDate.date) }} - ({{
                      scriptDate?.data?.length
                    }})
                  </div>
                </div>
                <ng-container
                  *ngFor="let script of scriptDate.data; let index = index"
                >
                  <div
                    appLazyload
                    (reachedBottom)="loadMoreScriptsData(index + 1, dateIndex)"
                    style="text-decoration: none; width: 100%"
                    class="card script"
                    [ngClass]="{
                      activeScript: currentScriptIndex == script._id,
                      notActive: currentScriptIndex != script._id
                    }"
                    [id]="script._id"
                  >
                    <app-execution-card
                      [script]="script"
                      [index]="index"
                      [sharedData]="sharedData"
                      [selectedSystem]="selectedSystem"
                      [currentScriptIndex]="currentScriptIndex"
                      (saveCheckedScriptEmit)="saveCheckedScript($event)"
                      (deletingSelectedScriptEmit)="
                        deletingSelectedScript($event)
                      "
                      (getTraceLogEmitter)="getTraceLogData($event)"
                      (getInputFileEmitter)="getInputFile($event)"
                      (downloadSummaryReportsEmit)="
                        downloadReports($event, 'summaryReport')
                      "
                      (downloadDumpReportsEmit)="
                        downloadReports($event, 'dumpReport')
                      "
                      (downloadRunTimeReportsEmit)="
                        downloadReports($event, 'runTime')
                      "
                      (getiRTFLogEmit)="getiRTFLog($event)"
                      (getNetworkLogEmitter)="getNetworkLogData($event)"
                      (onDemandValidationEmitter)="onDemandValidation($event)"
                      (downloadPLOGReportsEmit)="
                        downloadPLOGReports($event, 'plog')
                      "
                    ></app-execution-card>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- <ng-container
              *ngFor="
                let scriptDate of scripts;
                let index = index;
                trackBy: trackItem
              "
            >
              <div
                class="dateContainer"
                [ngClass]="{ ReportDate: sharedData['view'] == 'Report' }"
              >
                <div
                  class="date"
                  [ngClass]="{ dateReport: sharedData['view'] == 'Report' }"
                >
                  {{ formatDate(scriptDate.date) }} - ({{
                    scriptDate.data.length
                  }})
                </div>
              </div>
              <ng-container *ngFor="let script of scriptDate.data">
                <div
                  appLazyload
                  (reachedBottom)="loadMoreScriptsData(index + 1)"
                  style="text-decoration: none; width: 100%"
                  class="card script"
                  [ngClass]="{
                    activeScript: currentScriptIndex == script._id,
                    notActive: currentScriptIndex != script._id
                  }"
                  [id]="script._id"
                >
                  <app-execution-card
                    [script]="script"
                    [sharedData]="sharedData"
                    [selectedSystem]="selectedSystem"
                    [currentScriptIndex]="currentScriptIndex"
                    (saveCheckedScriptEmit)="saveCheckedScript($event)"
                    (deletingSelectedScriptEmit)="
                      deletingSelectedScript($event)
                    "
                    (getTraceLogEmitter)="getTraceLogData($event)"
                    (getInputFileEmitter)="getInputFile($event)"
                    (downloadSummaryReportsEmit)="
                      downloadReports($event, 'summaryReport')
                    "
                    (downloadDumpReportsEmit)="
                      downloadReports($event, 'dumpReport')
                    "
                    (downloadRunTimeReportsEmit)="
                      downloadReports($event, 'runTime')
                    "
                    (getiRTFLogEmit)="getiRTFLog($event)"
                    (getNetworkLogEmitter)="getNetworkLogData($event)"
                    (onDemandValidationEmitter)="onDemandValidation($event)"
                  ></app-execution-card>
                </div>
              </ng-container>
            </ng-container> -->
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>
    </div>

    <!--
                Block of Code for:
                NA
         -->
    <div class="scriptGenerateReport">
      <div class="split_3_border"></div>
      <div class="createButtonTag" *ngIf="runValidation">
        <!-- <button class="createButton">Freeze Run</button> -->
        <!-- <button class="createButton" (click)="freezeScripts()" [disabled]="!statusCheck()"
                    [ngClass]='{"disabled":!statusCheck()}' *ngIf="sharedData['view']=='Live'">Freeze
                    Run</button> -->
        <button
          class="createButton"
          [disabled]="selectedScripts.length == 0"
          (click)="createReport()"
          [ngClass]="{ disabled: selectedScripts.length == 0 }"
          *ngIf="sharedData['view'] == 'Report'"
        >
          Create Report
        </button>
      </div>
      <div class="RUNButtonTag" *ngIf="!runValidation">
        <!-- <button class="createButton">Freeze Run</button> -->
        <!-- <button class="RUNButton"
                     >RUN</button> -->
        <!-- <button class="createButton" [disabled]="selectedScripts.length==0" (click)="createReport()"
                    [ngClass]='{"disabled":selectedScripts.length==0}' *ngIf="sharedData['view']=='Report'">Create
                    Report</button> -->
      </div>
    </div>
  </div>
  <!--
        Block of Code for:
        Displaying Data as per Script Selected in the List of Validate Tab(Live Section)
    -->
  <div class="scriptDataContainer" [ngClass]="{ minimize: minimize }">
    <button
      *ngIf="sharedData['view'] == 'Live' && minimize"
      class="backToFreezeIdList"
      [ngClass]="{ minimize: minimize }"
      (click)="minimizeWindows()"
    >
      <mat-icon fontIcon="view_quilt"></mat-icon>
    </button>
    <div class="scriptheader" *ngIf="listScripts.length > 0">
      <span
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
        *ngIf="currentScript && currentScript['name'] != null"
      >
        <span class="scriptsText" *ngIf="runValidation">SCRIPT </span>
      </span>
      <span class="scriptsText" *ngIf="!runValidation">CONFIG </span>
      <mat-icon>keyboard_arrow_right</mat-icon>
      <span class="scriptNameHeader" *ngIf="runValidation && currentScript">
        {{ currentScript["name"] }}</span
      ><span
        class="scriptNameHeader"
        *ngIf="!runValidation && currentRunScript"
      >
        {{ currentRunScript["scriptName"] }}</span
      >
    </div>
    <div class="runValidateView">
      <router-outlet></router-outlet>
      <!-- <app-validate-view *ngIf="runValidation" [(current)]="currentScript"
                    [(execLogCompletion)]="execLogCompletion" [(currentScriptIndex)]="currentScriptIndex"
                    [(execMessage)]="execMessage"></app-validate-view> -->
    </div>
  </div>

  <!--
        Block of Code for:
        NA
    -->
  <div
    class="freezeViewContainer"
    *ngIf="isShowFreezeView && groupedScripts.length > 0"
  >
    <div class="freezeView">
      <div class="warningMessage">
        <span>Are you sure?</span>
      </div>
      <div class="warningDetails">
        <span
          >On freezing the run, you will not able to <br />make changes to the
          scripts.</span
        >
      </div>
      <div class="freezeId">
        <span>FREEZE ID</span>
      </div>
      <div class="freezeIdArea">
        <input type="text" class="freezeIdTextArea" [(ngModel)]="freezeId" />
      </div>
      <div class="buttonViews">
        <button class="cancelButton" (click)="cancelFreeze()">Cancel</button>
        <button class="confirmButton" (click)="submitFreeze()">Confirm</button>
      </div>
    </div>
  </div>

  <!--
        Block of Code for:
        Display Modal on Clicking Add Config Buttion in Run Tab(Live Section)
    -->
  <div class="ScriptDetailsAdd" *ngIf="addScriptDetailsWindow">
    <div class="scriptsDeatilsView">
      <form>
        <div class="row Header">
          <div class="col-12">Enter Script details</div>
        </div>
        <div class="row UserName">
          <div class="col-12">
            <div class="form-group">
              <label>SCRIPT NAME</label>
              <input
                type="text"
                maxlength="8"
                class="form-control"
                name="scriptName"
                [(ngModel)]="scriptDeatils['scriptName']"
                placeholder="Script name"
                #scriptName
              />
            </div>
            {{ scriptName.value }}
            <p *ngIf="!(scriptName.value.length > 2)" style="color: red">
              Configuration name should contain atleast 2 characters
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <button
              class="cancelButton"
              style="margin-left: 73%"
              (click)="cancelAddScript()"
            >
              Cancel
            </button>
          </div>
          <div class="col-6">
            <button
              class="confirmButton"
              [disabled]="!(scriptName.value.length > 2)"
              (click)="submitScriptDetails()"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- confirmation Dialog for deleting the log  (PD-11)-->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!--
                   If an already deleted log user tries to delete it,
                   the below error message will be displayed for 3 seconds to intimate the user that the log has been already deleted or deleted by someone.
                 -->
        <div style="flex-direction: column">
          <h5 class="modal-title" id="exampleModalLabel">
            Delete Confirmation
          </h5>
          <p class="p-0 m-0 text-danger errorblink" style="font-size: 12px">
            {{ deleteError }}
          </p>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body text-center">
        Do you want to delete <b>{{ deletingScript }}</b> log permanently
      </div>
      <div class="footer">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
        >
          Close
        </button>
        <!-- Calling the delete function -->
        <button type="button" class="btn btn-danger" (click)="deleteScript()">
          <span class="mr-1">Delete</span>
          <div
            *ngIf="deletespinner"
            class="spinner-grow pt-2"
            style="width: 15px; height: 15px"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
