import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ElementRef,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { HexPipe } from "src/app/validate-view/hex.pipe";
import _ from "underscore";

interface phaseData {
  scriptName: String;
  subScripts: Array<{
    subScriptName: String;
    transactions: Array<{
      MI: String;
      MO: String;
      AB: Array<{
        ABrecordTwo: {
          errorType: String;
          errorPrefix: String;
          errorCode: String;
        };
        ABrecordOne: {
          data: {
            SO: String;
            object: String;
            pgmDisp: String;
            functionName: String;
            loadset: String;
          };
        };
      }>;
      trnsId: string;
    }>;
  }>;
  subScriptName: String;
}
@Component({
  selector: "app-subscriptcontainer",
  templateUrl: "./subscriptcontainer.component.html",
  styleUrls: ["./subscriptcontainer.component.scss"],
  providers: [HexPipe],
})
export class SubscriptcontainerComponent {
  @Input() PHData: Array<phaseData> = [];
  @Input() filteredScript = "";
  @Input() aciveSubScript = "";
  @Input() total;
  @Input() scriptName;
  @Input() executionId;
  @Input() selectedSystem;
  @Input() phase = "";
  @Output() loadMoreDataEmitter = new EventEmitter();
  @Output() loadSubScriptEmitter = new EventEmitter();
  @Output() TraceDataEmitter = new EventEmitter();
  constructor(
    private hexPipe: HexPipe,
    private renderer: Renderer2,
    private el: ElementRef,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit() {
    console.log(this.phase);

    this.constructPhaseData(this.PHData);
  }
  trackByFn(index, item) {
    return index; // Assuming your item has a unique 'id' property
  }
  public isJson(data) {
    try {
      if (_.isObject(JSON.parse(data))) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  public displayFormatedOutput(data) {
    try {
      if (_.isObject(JSON.parse(data))) {
        return JSON.stringify(JSON.parse(data), null, 4);
      } else {
        return data;
      }
    } catch (error) {
      return data;
    }
  }

  loadMoreData(trns) {
    this.loadMoreDataEmitter.emit(trns);
  }
  loadSubScript(trns) {
    this.loadSubScriptEmitter.emit(trns);
  }

  moveToTrace(trans) {
    console.log(trans);

    this.TraceDataEmitter.emit(trans);
  }
  onScroll(index: string, event: Event): void {
    const target = event.target as HTMLElement;
    const otherContainer = document.getElementById(index);
    otherContainer.scrollLeft = target.scrollLeft;
    otherContainer.scrollTop = target.scrollTop;
  }

  public constructPhaseData(phaseData: Array<phaseData>) {
    let outputString = `
    
        <style>
        .input {
          // grid-row: 1;
          // height: 56px;
          // border-radius: 10px !important;
          border-bottom: 1px solid black;
          background-color: rgb(247, 232, 203);
        }
          

        .card-body::-webkit-scrollbar-thumb {
          background-image: linear-gradient(to top, rgb(209, 145, 184), #949ccf);
            // background-color: #32435460;
            border-radius: 10px;
          } 

      /* Handle on hover */
        .card-body::-webkit-scrollbar-thumb:hover {
              // background: linear-gradient(to top,rgb(199,60,143),#7783CD);
                   background-image: linear-gradient(to top, rgb(209, 145, 184), #949ccf);
               // background-color: #324354;
          }

        .card-body::-webkit-scrollbar {
            width: 10px !important;
            height: 10px !important;
            // background-color: #000000;
            -webkit-transition: width 5s;
            transition: 1s;
            right: 0%;
          }

         .card-body:hover::-webkit-scrollbar {
            width: 10px !important;
            // height: 15px !important;
            // cursor: pointer !important;
          }

        .card-body::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            background-color: rgb(202, 199, 199);
            border-radius: 10px;
          }

          .dumpTable td {
            font-family: "Courier Prime", monospace;
            font-size: 0.8vw;
            font-stretch: normal;
            // line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            word-wrap: nowrap;
            word-break: nowrap;
          }

          .dumpTable th {
            padding: 0.4rem;
            vertical-align: top;
            border-top: 0px solid #dee2e6;
            border-bottom: 1px solid #eceff4;
            font-family: "Courier Prime", monospace;
            font-size: 0.8vw;
            font-weight: 700;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            white-space: nowrap;
            word-wrap: nowrap;
            word-break: nowrap;
          }
            .phaseData {
              color: white;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: relative;
            }

            .phaseContainer {
              display: flex;
              flex-direction: column;
              // background-color: white;
              border-radius: 10px;
              width: 100%;
            }
          .scriptContainer{
          position: relative
          }
        </style>
        <div class="phaseOne">
        <div class="phaseContainer">
        <div class="phaseData" id="phaseData">
        `;

    phaseData.forEach((script) => {
      outputString += `
        <div class="scriptContainer">
        <p
        class="m-1 p-1 text-center text-dark transacText"
        style="
          position: sticky;
          top: 0px;
          z-index: 10;
          background-color: white;
          color: black;
          font-weight: bold;
        "
      >
        SCRIPT :  ${script.scriptName}
      </p>`;
      script.subScripts.forEach((subScriptItem) => {
        outputString +=
          subScriptItem.subScriptName != "No Subscript"
            ? `
        <div class="subscriptContainer">

        <p
      class="m-1 text-center text-dark transacText"
      style="
       position: sticky;
        top: 30px;
        z-index: 9;
        background-color: white;
        color: black;
        font-weight: bold;
      "
    >
      ***${subScriptItem.subScriptName}
    </p>`
            : "";
        subScriptItem.transactions.forEach((transaction) => {
          outputString += `
      <div
        class="card mt-1"
        style="
          background-color: white;
          border: 1px black solid;
          border-radius: 10px;
          overflow: hidden;
        "
      >
        <div class="card-header p-1 input">
          <div style="color: black; display: flex; flex-direction: column">
            <div
              style="
                display: flex;
                justify-content: flex-end;
                height: 20px;
                user-select: none;
              "
            >
              <button
                data-toggle="tooltip"
                data-placement="top"
                title="View Trace Data"
                class="btn btn-sm btn-none p-0"
                style="height: 20px; display:flex; align-items:center; justify-content: center"
                onclick="moveToTrace('${transaction.trnsId}', '${
            this.scriptName
          }', '${this.executionId}', '${this.total}')"
              ><span  style="
                    color: black;
                    font-size: 20px;
                    height: 15px;
                    width: 15px;
                    margin-top: -15px;
                  " >&#9979;</span>
                </button
              >&nbsp;&nbsp;&nbsp;&nbsp; 
              <span
                class="mr-3"
                style="color: black; font-size: 12px; font-weight: bold"
                >[ ${this.hexPipe.transform(transaction["trnsId"])} ]</span
              >
            </div>
            <div class="inputPre">
              <pre
                class="m-0"
                style="color: black"
              ><span style="user-select: none;">[</span> ${
                transaction["MI"]
              } <span style="user-select: none;">]</span></pre>
            </div>
          </div>
        </div>
        <div
          class="card-body p-2"
          style="
            cursor: default;
            max-height: 16vh;
            min-height: 16vh;
            overflow: auto;
          "
          id="${
            (this.phase == "PH2" ? "executionph2" : "executionph1") +
            transaction["trnsId"]
          }"
          onscroll="
            onScroll(
              '${
                (this.phase == "PH1" ? "executionph2" : "executionph1") +
                transaction["trnsId"]
              }', 
              '${
                (this.phase == "PH2" ? "executionph2" : "executionph1") +
                transaction["trnsId"]
              }')">
          <div
            class="container"
           
          >
            <div class="row" >
              <div class="outputPre">
                ${
                  transaction["MO"]
                    ? `<pre
                  style="
                    color: black;
                    text-align: inherit;
                    font-size: 0.85vw;
                    width: 100%;
                    white-space: pre;
                    margin-bottom: 0px;
                  "
                  >${transaction["MO"]}
              </pre>`
                    : ""
                }
              </div>
            </div> ${
              transaction["AB"]
                ? `
            <div>
              <div class="container p-0">
                <div class="row">
                  <div class="col p-0" style="background-color: white">
                    <div class="text-dark p-1" style="font-weight: bold">
                      [DUMP]
                    </div>
                    <div class="error-container">
                      <table class="table dumpTable">
                        <thead>
                          <tr style="background-color: #324354">
                            <th>S.No</th>
                            <th>ERROR</th>
                            <th>SO</th>
                            <th>OBJ</th>
                            <th>DISP</th>
                            <th>FUNCTION</th>
                            <th>LOADSET</th>
                          </tr>
                        </thead>
                        <!-- {{transacData['AB']|json}} -->
                        <tbody>
                          ${
                            transaction["AB"] && transaction["AB"].length
                              ? transaction["AB"].map(
                                  (dumptItem, i) => `<tr style="width: 100%">
                                <td>${i + 1}</td>
                                <td>
                                    ${dumptItem["ABrecordTwo"]["errorType"]}-${
                                    dumptItem["ABrecordTwo"]["errorPrefix"]
                                  }${
                                    dumptItem["ABrecordTwo"]["errorCode"]
                                  }                                  </td>
                                <td>
                                  ${dumptItem["ABrecordOne"]["data"]["SO"]}
                                </td>
                                <td>
                                  ${dumptItem["ABrecordOne"]["data"]["object"]}
                                </td>
                                <td>
                                  ${dumptItem["ABrecordOne"]["data"]["pgmDisp"]}
                                </td>
                                <td>
                                  ${
                                    dumptItem["ABrecordOne"]["data"][
                                      "functionName"
                                    ]
                                  }
                                </td>
                                <td>
                                  ${dumptItem["ABrecordOne"]["data"]["loadset"]}
                                </td>
                              </tr>
                              `
                                )
                              : ""
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>`
                : ``
            }
          </div>
        </div>
      </div>
      `;
        });
        outputString +=
          subScriptItem.subScriptName != "No Subscript" ? `</div>` : "";
      });
      outputString += `</div>`;
    });
    this.sanitizedHtmlContent =
      this.sanitizer.bypassSecurityTrustHtml(outputString);
  }
  sanitizedHtmlContent: any;
}
