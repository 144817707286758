import { HttpClient } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { ShareDataService } from "src/app/_services/share-data.service";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";

@Component({
  selector: "app-usercard",
  templateUrl: "./usercard.component.html",
  styleUrls: ["./usercard.component.scss"],
})
export class UsercardComponent {
  @Input() userData;
  @Input() connectedUsersList;
  onlineStatus = false;
  userConnectedSocket;
  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public router: Router,
    private sharedServices: ShareDataService,
    private socket: SocketioClientServices,
    private route: ActivatedRoute,
    public httpAdapter: HttpAdapterService
  ) {
    // this.httpAdapter = new HttpAdapterService(this.http);
  }
  isMoreInformationModalvisible = false;
  existingTabsSocketIdList = [];
  handleCancel() {
    this.isMoreInformationModalvisible = false;
  }
  handleOk() {
    this.isMoreInformationModalvisible = true;
  }
  ngOnInit() {
    this.existingTabsSocketIdList =
      this.connectedUsersList && this.connectedUsersList[this.userData["user"]]
        ? this.connectedUsersList[this.userData["user"]]
        : [];

    if (this.existingTabsSocketIdList.length) {
      this.onlineStatus = true;
    }

    this.socket.listeningFunction("userConnected").subscribe((data: any) => {
      if (data._id == this.userData._id) {
        this.onlineStatus = true;
        if (
          !this.existingTabsSocketIdList.some((item) => item == data.socketId)
        ) {
          this.existingTabsSocketIdList.push(data.socketId);
        }
      }
    });
    this.socket.listeningFunction("userDisConnected").subscribe((data: any) => {
      if (this.existingTabsSocketIdList.some((item) => item == data.socketId)) {
        this.existingTabsSocketIdList = this.existingTabsSocketIdList.filter(
          (item) => item != data.socketId
        );
        if (!this.existingTabsSocketIdList.length) {
          this.onlineStatus = false;
        }
      }
    });
  }
}
