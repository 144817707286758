import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ShareDataService } from "src/app/_services/share-data.service";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";
import * as Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";

@Component({
  selector: "app-lniata-card",
  templateUrl: "./lniata-card.component.html",
  styleUrls: ["./lniata-card.component.scss"],
})
export class LniataCardComponent {
  @Input() label = ""; // Parameter for dislaying the Lniata Name
  @Input() value = 0; // Parameter to indicate how many runs are going on using this lniata
  @Input() brand = ""; // Parameter for background color
  @Input() brand_color = ""; // Parameter for font color

  @Output() deleteLniata = new EventEmitter(); // Signal emitter when the user clicks the delete icon
  @Output() viewLniata = new EventEmitter(); // Signal emitter when the user clicks the edit icon
}
