<div class="card-body p-0">
  <div
    class="checkboxArea"
    *ngIf="script['progress'] == 'COMPLETED' && sharedData['view'] == 'Report'"
    (click)="$event.stopPropagation()"
  >
    <input
      class="selectCheckBox"
      type="checkbox"
      [(ngModel)]="script['selected']"
      (change)="saveCheckedScript(script)"
    />
  </div>
  <div class="scriptDataArea">
    <div class="scriptName">
      <div class="scriptNameTag" style="grid-template-columns: 1">
        <a
          target="_blank"
          [href]="['#/' + selectedSystem + '/scripts/validate/' + script._id]"
        >
          <span class="card-title">{{ script["name"] }}</span>
        </a>
      </div>
      <div
        class="userIdTag"
        style="
          grid-template-columns: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        "
      >
        <!-- <button
          *ngIf="script.timeoutPH1Happened?.length"
          data-toggle="tooltip"
          data-placement="top"
          title="Pricing Error!"
          class="btn btn-sm btn-none p-0 mr-1"
          style="height: 20px"
        >
          <mat-icon
            style="color: red; font-size: 20px; height: 20px; width: 20px"
            >error_outline</mat-icon
          >
        </button> -->
        <button
          *ngIf="
            script.apiErrorPH1Happened?.length ||
            script.apiErrorPH2Happened?.length
          "
          data-toggle="tooltip"
          data-placement="top"
          title="Pricing Error!"
          class="btn btn-sm btn-none p-0 mr-2"
          style="height: 20px; width: 20px !important"
          [matMenuTriggerFor]="apiErrorMenu"
        >
          <mat-icon
            style="color: red; font-size: 20px; height: 20px; width: 20px"
            [ngClass]="{ blinkingIcon: script['progress'] == 'PROGRESS' }"
            >error_outline</mat-icon
          >
        </button>
        <button
          *ngIf="
            script.timeoutPH1Happened?.length ||
            script.timeoutPH2Happened?.length
          "
          data-toggle="tooltip"
          data-placement="top"
          title="Timeout happened!"
          class="btn btn-sm btn-none p-0 mr-2"
          style="height: 20px; width: 20px !important"
          [matMenuTriggerFor]="timeoutMenu"
        >
          <mat-icon
            class="m-0"
            [ngClass]="{ blinkingIcon: script['progress'] == 'PROGRESS' }"
            style="color: orange; font-size: 20px; height: 20px; width: 20px"
            >hourglass_empty</mat-icon
          >
        </button>

        <button
          data-toggle="tooltip"
          data-placement="top"
          title="View Trace Data"
          class="btn btn-sm btn-none p-0 mr-1"
          style="height: 30px"
          (click)="getTraceLog(script)"
        >
          <p style="font-size: 20px; margin-bottom: 4vh; color: white">
            &#9979;
          </p>
        </button>
        <div class="userId mr-2" style="color: white">
          <mat-icon
            style="color: white; font-size: 20px; height: 20px; width: 20px"
            >perm_identity</mat-icon
          >
          <p style="line-height: 20px" class="m-0">
            {{ script["user"]["_id"] | uppercase }}
          </p>
        </div>
      </div>
    </div>
    <mat-menu #timeoutMenu="matMenu">
      <div style="display: grid; grid-template-columns: auto auto">
        <!-- <p class="p-2 m-0" *ngFor="let item of script.timeoutPH1Happened">
          <span>{{ item.transId | hex }}</span>
        </p> -->
        <p class="p-2 m-0">
          PH1 - {{ script.timeoutPH1Happened?.length || 0 }}
        </p>
        <p class="p-2 m-0">
          PH2 - {{ script.timeoutPH2Happened?.length || 0 }}
        </p>
      </div>
    </mat-menu>
    <mat-menu #apiErrorMenu="matMenu">
      <div style="display: grid; grid-template-columns: auto auto">
        <!-- <p class="p-2 m-0" *ngFor="let item of script.timeoutPH1Happened">
          <span>{{ item.transId | hex }}</span>
        </p> -->
        <p class="p-2 m-0">
          PH1 - {{ script.apiErrorPH1Happened?.length || 0 }}
        </p>
        <p class="p-2 m-0">
          PH2 - {{ script.apiErrorPH2Happened?.length || 0 }}
        </p>
      </div>
    </mat-menu>
    <div class="scriptStatusDetails pt-2">
      <span
        class="label label-success completed"
        *ngIf="script['progress'] != null && script['progress'] == 'COMPLETED'"
        >COMPLETED</span
      >
      <span *ngIf="script['progress'] == 'PROGRESS'" class="inProgress"
        >INPROGRESS</span
      >
      <span *ngIf="script['progress'] == 'ABORTED'" class="aborted"
        >ABORTED</span
      >
      <span
        class="label label-danger fail"
        *ngIf="
          script['validationStatus']?.['state'] != null &&
          script['validationStatus']?.['state'] == false
        "
        >FAIL</span
      >
      <span
        class="pass"
        *ngIf="
          script['validationStatus']?.['state'] != null &&
          script['validationStatus']?.['state'] == true
        "
        >PASS</span
      >
    </div>

    <div
      class="flowStatus1 Percentage m-1 text-truncate"
      *ngIf="
        (script['progress'] == 'PROGRESS' || script['progress'] == 'ABORTED') &&
        script.percentage
      "
    >
      <ng-container>
        <b>PH1:</b>
        {{
          script.percentage["PHASE1Count"]
            ? script.percentage["PHASE1Count"]
            : 0
        }}/{{
          script.percentage["PHASE1Total"]
            ? script.percentage["PHASE1Total"]
            : 000
        }}
        {{ "(" + script.percentage["PHASE1"] + "%)" }}
      </ng-container>
      &nbsp;|&nbsp;
      <ng-container>
        <b>PH2:</b>
        {{
          script.percentage["PHASE2Count"]
            ? script.percentage["PHASE2Count"]
            : 0
        }}/{{
          script.percentage["PHASE2Total"]
            ? script.percentage["PHASE2Total"]
            : 000
        }}
        {{ "(" + script.percentage["PHASE2"] + "%)" }}
      </ng-container>
    </div>
    <div class="scriptTimeDetails">
      <span class="activeStartTime col"
        >Start: {{ script.startTime | date : "hh:mm" }}
      </span>
      <div
        class="flowStatus text col text-truncate"
        mat-raised-button
        #tooltip="matTooltip"
        [matTooltip]="script.progressMsg"
        *ngIf="
          script['progress'] != 'COMPLETED' &&
          script['progress'] != 'YET TO BE REVIEWED'
        "
      >
        {{ script.progressMsg | titlecase }}
      </div>
      <span class="activeStartTime">
        <i
          *ngIf="
            script['progress'] == 'COMPLETED' ||
            script['progress'] == 'YET TO BE REVIEWED'
          "
          class="timeIcon fa fa-angle-right"
          aria-hidden="true"
        ></i
      ></span>
      <span
        class="activeEndTime col"
        *ngIf="
          script['progress'] == 'COMPLETED' ||
          script['progress'] == 'YET TO BE REVIEWED'
        "
        >End: {{ script.endTime | date : "hh:mm" }}</span
      >
      <!-- Delete button in validate view, For deleting the list of logs  (PD-11)-->
      <div>
        <button
          class="activeEndTime"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          style="
            width: 30px;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button class="btn" mat-menu-item (click)="getNetworkLog(script)">
            <span>Network Log</span>
          </button>
          <button class="btn" mat-menu-item (click)="getInputFile(script)">
            <span>Input File</span>
          </button>
          <!-- <button
            class="btn"
            mat-menu-item
            data-toggle="modal"
            (click)="getTraceLog(script)"
          >
            <span>Trace Data</span>
          </button> -->
          <button
            class="btn"
            mat-menu-item
            data-toggle="modal"
            data-target="#exampleModal"
            (click)="deletingSelectedScript(script)"
            [disabled]="script['progress'] == 'PROGRESS'"
          >
            <span>Delete Log</span>
          </button>
          <button
            [disabled]="script.iRTFNames.length === 0"
            class="menuButton btn"
            (click)="getiRTFLog(script)"
            mat-menu-item
          >
            <span>Download iRTF Log</span>
          </button>
          <button
            class="menuButton"
            mat-menu-item
            [matMenuTriggerFor]="reports"
          >
            Download Reports
          </button>
          <button
            class="menuButton btn"
            [disabled]="
              script['status'] != null ||
              script['progress'] == 'COMPLETED' ||
              (script['progress'] != 'COMPLETED' &&
                script['progressMsg'] == 'validation inProgress') ||
              (script['progress'] != 'COMPLETED' &&
                script['progressMsg'] == 'Starting Validation')
            "
            (click)="onDemandValidation(script)"
            mat-menu-item
          >
            <span>On Demand Validation</span>
          </button>
        </mat-menu>
        <mat-menu #reports="matMenu">
          <button
            mat-menu-item
            [disabled]="script['progress'] != 'COMPLETED'"
            (click)="downloadPLOGReports(script)"
          >
            PLOG Report
          </button>
          <button
            mat-menu-item
            [disabled]="script['progress'] != 'COMPLETED'"
            (click)="downloadSummaryReports(script)"
          >
            Summary Reports
          </button>
          <button mat-menu-item (click)="downloadDumpReports(script)">
            Dump Report
          </button>
          <button
            mat-menu-item
            [disabled]="script['progress'] != 'COMPLETED'"
            (click)="downloadRunTimeReports(script)"
          >
            Run Time Report
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
