import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {
  @Input() conf;
  @Input() validator;
  // passowrdType = "password"

  public newKeys = [];
  /**
    * Below function is to collect the newly added forms in an arry
    */
  @Input() public set newKeysAdded(val: string) {
    if (val) {
       this.newKeys = [];
      Object.keys(val).map(value => {
        this.newKeys.push(value)
      })
    }
  }

  public showPassword: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

}
