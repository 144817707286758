import { NZ_I18N } from "ng-zorro-antd/i18n";
import { en_US } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatNativeDateModule } from "@angular/material/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavLayoutComponent } from "./nav-layout/nav-layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./_services/shared.module";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { DialogueComponent } from "./errorPages/dialogue/dialogue.component";
import { LoaderService } from "./_services/loader.service";
import { LoaderInterceptor } from "./interceptors/loader-interceptor.service";
import { MyLoaderComponent } from "./components/my-loader/my-loader.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { LoadingComponent } from "./components/loading/loading.component";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatMenuModule } from "@angular/material/menu";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { CookieService } from "ngx-cookie-service";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    NavLayoutComponent,
    DialogueComponent,
    MyLoaderComponent,
    LoadingComponent,
    WelcomeComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatGridListModule,
    MatIconModule,
    MatDialogModule,
    MatDialogModule,
    ScrollingModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatProgressBarModule,
    MatMenuModule,
    MatListModule,
    MatButtonModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTooltipModule,
  ],
  providers: [
    LoaderService,
    CookieService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
