import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";

// import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
// import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { InputDispComponent } from "../input-disp/input-disp.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-input-selection",
  templateUrl: "./input-selection.component.html",
  styleUrls: ["./input-selection.component.scss"],
})
export class InputSelectionComponent implements OnInit {
  //variable declared to check whether file has been selected/not
  itemSelected: boolean = false;
  httpAdapter: HttpAdapterService;
  enableUpload = false;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  file;

  public archiveScriptStatus: boolean = false;
  public showArchiveAllButton: boolean = true;
  public deleteScriptsArrayId = [];
  public disableArchiveFile: boolean = true;
  private selectedSystem: string = "";

  errorMessage = [];
  successMessage = null;
  subscription: any;
  constructor(
    public http: HttpClient,
    public dialogRef: MatDialogRef<InputSelectionComponent>,
    public dialog: MatDialog,
    private sharedServices: ShareDataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit(): void {
    this.socketListener();
  }

  socketListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      )
        this.selectedSystem = data["selectedSystem"];
    });
  }

  onChange(event) {
    this.file = event.target.files;
    console.log(this.file);
    this.onUpload();
  }

  onUpload() {
    var self = this;
    // this.loading = !this.loading;
    var InputFileName = self.file[0].name.split(".")[0];
    var FileExtension = self.file[0].name.split(".")[1];
    var inputFiles = [];
    self.errorMessage = [];
    // console.log(" On Upload Func File is ==>", InputFileName);
    if (InputFileName.toString().split("").length > 7) {
      self.errorMessage.push(
        `* More than 7 characters in the file name - <b>${InputFileName}</b>`
      );
    }

    /** Numbers should not be permitted. ERORR validaton is currently not needed. */

    // if (InputFileName.toString().match(/[0-9]/g) != null) {
    //   self.errorMessage.push(`* Numbers should not be permitted - <b>${InputFileName}</b>`);
    // }

    if (
      FileExtension === undefined ||
      (FileExtension.toLowerCase() != "txt" &&
        FileExtension.toLowerCase() != "input")
    ) {
      self.errorMessage.push(
        `* File Upload Failed due to Invalid file type <b>${
          FileExtension ? " - " + FileExtension : ""
        }</b>`
      );
    }
    if (self.errorMessage.length == 0) {
      self.errorMessage = null;
      console.log("1");
      var params = {
        system: this.selectedSystem,
        url: "inputFile/getInputFileList",
      };
      self.httpAdapter.httpPost(params, (error, response) => {
        if (error) {
          // console.log("Error", error)
        } else {
          var filesList = response.body.data;
          filesList.forEach((file) => {
            console.log("file", file);
            inputFiles.push(file.fileName);
          });
          console.log("InputFileName", InputFileName);
          console.log("inputFiles", inputFiles);
          var fileStatus = inputFiles.includes(InputFileName.toUpperCase());
          if (fileStatus) {
            var replace;
            const showDialogue = self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              panelClass: "my-alert-message-class",
              data: {
                Title: "Confirmation",
                Content:
                  "Script alredy Exsist! The Script will be replaced on your action",
                isConfirm: true,
              },
            });
            showDialogue.afterClosed().subscribe((result) => {
              console.log(`Dialog result: ${result}`);
              replace = result;
              if (replace) {
                this.UploadFile(function (data) {
                  data
                    ? self.dialog.open(DialogueComponent, {
                        direction: "ltr",
                        minWidth: "350px",
                        position: { top: "10px" },
                        panelClass: "my-alert-message-class",
                        data: {
                          Title: "Information",
                          Content:
                            "The " +
                            InputFileName +
                            " Script has been replaced",
                          isConfirm: true,
                          isClose: false,
                        },
                      })
                    : "";
                });

                this.itemSelected = false;
              } else {
                this.itemSelected = false;
              }
            });
          } else {
            this.UploadFile(function (data) {});
          }
          this.dialogRef.componentInstance.data["value"] = response.body.data;
        }
      });
    }
  }

  UploadFile(callback) {
    var self = this;
    console.log("this.selectedSystemthis.selectedSystem", this.selectedSystem);
    var params = {
      system: this.selectedSystem,
      url: "inputFile/uploadFile",
    };
    self.httpAdapter.upload(params, self.file).subscribe(
      (result) => {
        if (result) {
          console.log("result-->", result);
          const params = {
            system: this.selectedSystem,
            url: "inputFile/getInputFileList",
          };
          this.httpAdapter.httpPost(params, (error, response) => {
            if (error) {
              // console.log("Error", error)
            } else {
              console.log("response--->", response);
              this.dialogRef.componentInstance.data["value"] =
                response.body.data;
            }
          });
          if (result["fileUpload"]) {
            callback(true);
            self.successMessage = `Successfully Added `;
            setTimeout(() => {
              self.successMessage = null;
            }, 5000);
          } else {
            self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              panelClass: "my-alert-message-class",
              data: {
                Title: "Error Message",
                Content: "File upload failed: " + result["uploadMsg"],
              },
            });
            callback(false);
            // window.alert("File upload failed: " + result["uploadMsg"])
          }
        } else {
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            panelClass: "my-alert-message-class",
            data: {
              Title: "Error Message",
              Content: "File upload failed: " + result["uploadMsg"],
            },
          });
          callback(false);

          // window.alert("File upload failed")
        }
      },
      (err) => {
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          panelClass: "my-alert-message-class",
          data: { Title: "Error Message", Content: "File upload failed" },
        });
        callback(false);

        // window.alert("File upload failed")
      }
    );
  }

  CheckDialoug() {
    this.itemSelected = true;
  }

  CloseDialoug() {
    this.data["closed"] = true;
    this.dialogRef.close();
  }

  // The function is used to Open Content for the Script file in the Editor
  openContent(event, item) {
    event.stopPropagation();
    this.showInput(item);
  }

  // The function is used to display the details of the Script file in the OpenContent
  showInput(file) {
    var self = this;
    var inputFilegetParam = {
      url: "inputFile/viewFile",
      data: file,
    };
    self.httpAdapter.httpPost(inputFilegetParam, function (err, fileResp) {
      if (!err) {
        var inputFile;
        if (fileResp["body"].length < 2) {
          inputFile = fileResp["body"][0]["inputFileContent"].split("\n");
        } else {
        }
        self.dialog.open(InputDispComponent, {
          // data: { value: inputFile.join('\n') }
          data: { value: inputFile, irtf: fileResp["body"][0]["irtfRequired"] },
        });
        // const dialogRef = self.dialog.open(InputDispComponent, {
        //   data: { value: inputFile.join('\n') }
        // });
        // dialogRef.afterClosed().subscribe(result => {
        // // console.log(`Dialog result: ${result}`);
        // });
      }
    });
    // console.log("Input file data==>", inputFile)
  }

  onClick() {
    const fileInput = this.fileInput.nativeElement;
    fileInput.click();
  }

  callUploadService(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    // this.httpAdapter.upload(formData).pipe(map(event => {
    //     switch (event.type) {
    //       case HttpEventType.UploadProgress:
    //         file.progress = Math.round(event.loaded * 100 / event.total);
    //         break;
    //       case HttpEventType.Response:
    //         return event;
    //     }
    //   }).subscribe((event: any) => {
    //     if (typeof (event) === 'object') {
    //     // console.log(event.body);
    //     }
    //   });
  }

  // private upload() {
  //   this.fileInput.nativeElement.value = '';
  //   this.files.forEach(file => {
  //     this.callUploadService(file);
  //   });
  // }

  // deleteFile(event, file) {
  //   event.stopPropagation();
  //   var self = this;
  // // console.log(self.file);
  //   var params = {
  //     url: "inputFile/deleteFile",
  //     data: {
  //       ObjectId: file._id
  //     }
  //   }
  //   self.httpAdapter.httpPost(params, (err, result) => {
  //     if (err) {
  //       window.alert("error in deleting file")
  //     } else {
  //       if (result.status) {
  //         window.alert("File deleted");
  //         this.dialogRef.componentInstance.data["value"] = result.body.files;

  //       } else {
  //         window.alert("File delete Failed")
  //       }
  //     }
  //   })
  //   //   result => {
  //   //     if (result) {
  //   //     // console.log(result)
  //   //       if (result['fileUpload']) {
  //   //         var params = {
  //   //           url: "inputFile/getInputFileList",
  //   //           data: { ObjectId: file._id }
  //   //         }
  //   //         this.httpAdapter.httpPost(params, (error, response) => {
  //   //           if (error) {
  //   //           // console.log("Error", error)
  //   //           } else {
  //   //             this.dialogRef.componentInstance.data["value"] = response.body.data;
  //   //           }
  //   //         })

  //   //       } else {
  //   //         window.alert("File upload failed: " + result["uploadMsg"])
  //   //       }
  //   //     } else {
  //   //       window.alert("File upload failed")
  //   //     }

  //   //   },
  //   //   err => {
  //   //     window.alert("File upload failed")
  //   //   // console.log(err)
  //   //   }
  //   // );
  // }

  /**
   *
   * archiveFile Function is to change the status of the buttons that are being displayed for archiving files.
   *
   */
  archiveFile() {
    var self = this;
    self.archiveScriptStatus = !self.archiveScriptStatus;
    self.showArchiveAllButton = !self.showArchiveAllButton;
  }

  /**
   * checkValue Function gets Called whenever there is a change detected in the checkbox
   * Change is detected in the Checkbox that is checked/unchecked for deletion
   * Based on the check status, an Array is maintained to keep track of list of items that needs to be deleted.
   *
   * Gets Executed if a script has been checked.
   *
   * If condition gets executed after a Script has been checked and then it is being pushed to deleteScriptsArrayId
   *
   * Else condition gets executed only when the Script is unchecked and then it is being removed from the deleteScriptsArrayId
   * Removing the Element from the Array by finding out Index using findIndex and then deleting the element with the help of Index found.
   *
   *
   */

  checkValue(eventValue, file) {
    var self = this;

    if (eventValue.target.checked) {
      self.deleteScriptsArrayId.push(file);
    } else {
      var deleteIndex = self.deleteScriptsArrayId.findIndex(
        (indElement) => indElement == file
      );
      self.deleteScriptsArrayId.splice(deleteIndex, 1);
    }

    self.disableArchiveFile =
      self.deleteScriptsArrayId.length > 0 ? false : true;
  }

  /**
   *
   * archiveSelectedFile Function gets Called whenever there is a change detected in the checkbox
   * Change is detected in the Checkbox that is checked/unchecked for deletion
   * Based on the check status, an Array is maintained to keep track of list of items that needs to be deleted.
   *
   * Gets Executed if a script has been checked.
   *
   * If condition gets executed after a Script has been checked and then it is being pushed to deleteScriptsArrayId
   *
   * Else condition gets executed only when the Script is unchecked and then it is being removed from the deleteScriptsArrayId.
   * Removing the Element from the Array by finding out Index using findIndex and then deleting the element with the help of Index found.
   *
   */

  archiveSelectedFile() {
    var self = this;

    var params = {
      url: "inputFile/deleteFiles",
      system: this.selectedSystem,
      data: {
        ObjectId: self.deleteScriptsArrayId,
      },
    };
    self.httpAdapter.httpPost(params, (err, result) => {
      if (err) {
        // window.alert("Error in deleting the file")
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          panelClass: "my-alert-message-class",
          data: {
            Title: "Error Message",
            Content: "Error in deleting the file",
          },
        });
      } else {
        if (result.status) {
          // this.dialogRef.componentInstance.data["value"] = result.body.files;
          {
            /**
             * @jira PM-902
             */
          }
          let params = {
            url: "inputFile/getInputFileList",
            system: this.selectedSystem,
            data: {},
          };
          this.httpAdapter.httpPost(params, (error, response) => {
            if (error) {
              // console.log("Error", error)
            } else {
              this.dialogRef.componentInstance.data["value"] =
                response.body.data;
            }
          });
          self.errorMessage = [];
          self.successMessage = `Successfully Deleted `;
          setTimeout(() => {
            self.successMessage = null;
          }, 5000);
        } else {
          // window.alert("File/s delete Failed")
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            panelClass: "my-alert-message-class",
            data: {
              Title: "Error Message",
              Content: "Error in deleting the file",
            },
          });
        }
      }
    });

    //   result => {
    //     if (result) {
    //     // console.log(result)
    //       if (result['fileUpload']) {
    // var params = {
    //   url: "inputFile/getInputFileList",
    //   data: { ObjectId: file._id }
    // }
    // this.httpAdapter.httpPost(params, (error, response) => {
    //   if (error) {
    //   // console.log("Error", error)
    //   } else {
    //     this.dialogRef.componentInstance.data["value"] = response.body.data;
    //   }
    // })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
