import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ValidateViewComponent } from "./validate-view.component";
import { ExecLogComponent } from "./exec-log/exec-log.component";
import { MappedViewComponent } from "./mapped-view/mapped-view.component";
import { SummaryReportComponent } from "./summary-report/summary-report.component";

const routes: Routes = [
  { path: "", redirectTo: "validate", pathMatch: "full" },
  { path: "", component: ValidateViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ValidateViewRoutingModule { }
