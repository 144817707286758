<!-- <h6 class="m-2" style="background-color: white">Script: {{ executionName }}</h6> -->
<div
  style="
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  "
  class="mt-3"
>
  <nz-select
    class="col-2 mr-5 mt-2 pl-2"
    [(ngModel)]="selectedTransaction"
    nzShowSearch
    nzAllowClear
    (ngModelChange)="
      phase1offset = 0;
      getPhase1ExecutionData();
      phase2offset = 0;
      getPhase2ExecutionData()
    "
    nzPlaceHolder="Select a Trasaction"
  >
    <nz-option nzLabel="ALL" nzValue="ALL"></nz-option>
    <nz-option
      [nzLabel]="transaction"
      [nzValue]="transaction"
      *ngFor="let transaction of wholeTrasactions; trackBy: trackByFn"
    ></nz-option>
  </nz-select>
  <button
    class="btn btn-success"
    [title]="!show98 ? 'Show 98 record' : 'Show All Records'"
    style="display: flex; align-items: center"
    (click)="
      show98 = show98 ? '' : 'true';
      phase1offset = 0;
      phase2offset = 0;
      getPhase1ExecutionData();
      getPhase2ExecutionData()
    "
  >
    98
    <mat-icon class="addHover ml-1" matSuffix>
      {{ !show98 ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
  <button
    class="btn btn-warning ml-4"
    [title]="!show75 ? 'Show 75 record' : 'Show All Records'"
    style="display: flex; align-items: center"
    (click)="
      show75 = show75 ? '' : 'true';
      phase1offset = 0;
      phase2offset = 0;
      getPhase1ExecutionData();
      getPhase2ExecutionData()
    "
  >
    75
    <mat-icon class="addHover ml-1" matSuffix>
      {{ !show75 ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
  <button
    class="btn btn-secondary ml-4"
    [title]="!show1 ? 'Show 1 record' : 'Show All Records'"
    style="display: flex; align-items: center"
    (click)="
      show1 = show1 ? '' : 'true';
      phase1offset = 0;
      phase2offset = 0;
      getPhase1ExecutionData();
      getPhase2ExecutionData()
    "
  >
    1
    <mat-icon class="addHover ml-1" matSuffix>
      {{ !show1 ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
  <button
    class="btn btn-info ml-4"
    [title]="!show70 ? 'Show 70 record' : 'Show All Records'"
    style="display: flex; align-items: center"
    (click)="
      show70 = show70 ? '' : 'true';
      phase1offset = 0;
      phase2offset = 0;
      getPhase1ExecutionData();
      getPhase2ExecutionData()
    "
  >
    70
    <mat-icon class="addHover ml-1" matSuffix>
      {{ !show70 ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
  <button
    class="btn btn-danger ml-4"
    [title]="!show133 ? 'Show 133 record' : 'Show All Records'"
    style="display: flex; align-items: center"
    (click)="
      show133 = show133 ? '' : 'true';
      phase1offset = 0;
      phase2offset = 0;
      getPhase1ExecutionData();
      getPhase2ExecutionData()
    "
  >
    133
    <mat-icon class="addHover ml-1" matSuffix>
      {{ !show133 ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
</div>
<div class="container-fluid">
  <mat-tab-group
    [(selectedIndex)]="currentTabIndex"
    (selectedTabChange)="tabChange($event)"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    preserveContent
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="matTabTextArea">
          <span class="matLabel">Phase 1</span>
        </div>
      </ng-template>
      <div class="matTabContent" style="width: 100%; height: 80vh">
        <cdk-virtual-scroll-viewport
          style="width: 100%; height: 80vh; overflow: scroll"
          itemSize="50"
        >
          <table
            class="table table-hover table-scrollable table-sm"
            style="width: 100%"
          >
            <thead class="thead-light">
              <tr style="position: sticky; top: 0px; z-index: 10">
                <th>Sl.No</th>
                <th>I/O</th>
                <th>Trans Id</th>
                <th>MSG No</th>
                <th style="position: sticky; right: 0px">Copy</th>
                <th>MSG Data</th>
              </tr>
            </thead>
            <tbody>
              <!-- <ng-container

              > -->
              <tr
                *ngFor="
                  let item of phase1ExecutionData;
                  let i = index;
                  let last = last;
                  trackBy: trackByFn
                "
                networkLazyload
                (reachedBottom)="loadMorePhase1Data(last, i)"
                [ngClass]="
                  item.inputOutputFlag === 'INPUT'
                    ? 'inputClass'
                    : 'outputClass'
                "
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <i
                    *ngIf="item.inputOutputFlag === 'INPUT'"
                    class="material-icons"
                    >arrow_circle_up</i
                  >
                  <i
                    *ngIf="item.inputOutputFlag === 'OUTPUT'"
                    class="material-icons"
                    >arrow_circle_down</i
                  >
                </td>
                <td>
                  <b>{{ item.transactionId }}</b>
                </td>

                <td>{{ item.messageNumber }}</td>
                <td
                  style="cursor: pointer"
                  (click)="copyToClipboard(item.messageData)"
                >
                  <i class="material-icons">content_copy</i>
                </td>
                <td
                  style="
                    overflow: auto;
                    text-align: left;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.messageData | slice : 0 : 105 }}
                </td>
              </tr>
              <!-- </ng-container> -->
            </tbody>
          </table>
        </cdk-virtual-scroll-viewport>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="matTabTextArea">
          <span class="matLabel">Phase 2</span>
        </div>
      </ng-template>
      <div class="matTabContent" style="width: 100%">
        <cdk-virtual-scroll-viewport
          style="width: 100%; height: 80vh"
          itemSize="50"
        >
          <table
            class="table table-hover table-scrollable table-sm"
            style="width: 100%"
          >
            <thead class="thead-light">
              <tr style="position: sticky; top: 0px; z-index: 10">
                <th>Sl.No</th>
                <th>I/O</th>
                <th>Trans Id</th>
                <th>MSG No</th>
                <th>Copy</th>
                <th>MSG Data</th>
              </tr>
            </thead>
            <tbody>
              <!-- <ng-container
                *ngFor="let item of phase2ExecutionData; let i = index"
              > -->
              <tr
                *ngFor="
                  let item of phase2ExecutionData;
                  let i = index;
                  let last = last;
                  trackBy: trackByFn
                "
                networkLazyload
                (reachedBottom)="loadMorePhase2Data(last, i)"
                [ngClass]="
                  item.inputOutputFlag === 'INPUT'
                    ? 'inputClass'
                    : 'outputClass'
                "
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <i
                    *ngIf="item.inputOutputFlag === 'INPUT'"
                    class="material-icons"
                    >arrow_circle_up</i
                  >
                  <i
                    *ngIf="item.inputOutputFlag === 'OUTPUT'"
                    class="material-icons"
                    >arrow_circle_down</i
                  >
                </td>
                <td>
                  <b>{{ item.transactionId }}</b>
                </td>
                <td>{{ item.messageNumber }}</td>
                <td
                  style="cursor: pointer"
                  (click)="copyToClipboard(item.messageData)"
                >
                  <i class="material-icons">content_copy</i>
                </td>
                <td
                  style="
                    overflow: auto;
                    text-align: left;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.messageData | slice : 0 : 105 }}
                </td>
              </tr>
              <!-- </ng-container> -->
            </tbody>
          </table>
        </cdk-virtual-scroll-viewport>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
