<mat-tab-group preserveContent>
  <mat-tab label="System">
    <div
      *ngIf="serverUtilizationInfo"
      class="container-fluid"
      style="height: 90vh; overflow: auto"
    >
      <div class="card m-2 px-2 py-4 row">
        <div class="col-12 row m-0 p-0">
          <div class="col-3">
            <app-utilization-card
              [value]="serverUtilizationInfo.memoryFree"
              [total]="serverUtilizationInfo.memoryTotal"
              icon="memory"
              label="Memory"
              brand="#e6e1b7"
              brand_color="white"
              socketInfo="memory"
              [GraphStatus]="true"
            ></app-utilization-card>
          </div>
          <div class="col-3">
            <app-utilization-card
              [value]="serverUtilizationInfo.storageFree"
              [total]="serverUtilizationInfo.storageTotal"
              label="Storage"
              icon="sd_storage"
              brand="#b669ea87"
              brand_color="white"
              socketInfo="storage"
              [GraphStatus]="true"
            ></app-utilization-card>
          </div>
          <div class="col-6 row m-0">
            <div class="col-6 mb-1">
              <app-utilization-card
                [value]="serverUtilizationInfo.process1"
                icon="import_export"
                label="RES Runs"
                brand="#e6e1b7"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="childProcesssInitiated1"
                iconColor="#b669ea87"
              ></app-utilization-card>
            </div>
            <div class="col-6">
              <app-utilization-card
                [value]="serverUtilizationInfo.process2"
                icon="import_export"
                label="AIR4 Runs"
                brand="#b669ea87"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="childProcesssInitiated2"
                iconColor="#e6e1b7"
              ></app-utilization-card>
            </div>
            <div class="col-6">
              <app-utilization-card
                [value]="serverUtilizationInfo.process3"
                icon="import_export"
                label="OSS Runs"
                brand="#b669ea87"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="childProcesssInitiated3"
                iconColor="#e6e1b7"
              ></app-utilization-card>
            </div>
            <div class="col-6">
              <app-utilization-card
                [value]="serverUtilizationInfo.uptime"
                icon="access_time"
                label="Up Time"
                brand="#e6e1b7"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="uptime"
                iconColor="#b669ea87"
              ></app-utilization-card>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <h4>
          VPARS List and their runs
          <button
            [title]="'Configure New VPAR'"
            class="btn btn-none btn-sm"
            (click)="openVparsModal('')"
          >
            <mat-icon style="color: blue" class="mt-1"
              >add_circle_outline</mat-icon
            >
          </button>
        </h4>
        <div class="col-12">
          <ng-container *ngFor="let item of vparsList">
            <div class="card p-3 mb-2">
              <h6>
                {{ item.systemName }}
                <button
                  [title]="'Configure New VPAR for ' + item.systemName"
                  class="btn btn-none btn-sm"
                  (click)="openVparsModal(item.systemName)"
                >
                  <mat-icon style="color: blue" class="mt-1"
                    >add_circle_outline</mat-icon
                  >
                </button>
              </h6>
              <div class="col-12 row m-0">
                <ng-container
                  *ngFor="let vparsItem of item.vparsList; let i = index"
                >
                  <div class="col-3 mb-2">
                    <app-utilization-card
                      [value]="
                        vparsItem.LniataTypes.length
                          ? vparsItem.LniataTypes[0].Lniatas.length
                          : 0
                      "
                      icon="desktop_windows"
                      [label]="vparsItem.vparsName | uppercase"
                      [brand]="colorArray[i]"
                      brand_color="white"
                      [deleteIcon]="
                        vparsItem.LniataTypes[0].Lniatas.length ? false : true
                      "
                      (deleteIconClickEvent)="deleteVparConfirmation(vparsItem)"
                      (ediIconClickEvent)="editVparInfo(vparsItem)"
                    ></app-utilization-card>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <nz-modal
      [(nzVisible)]="showDeleteVparsConfirmationModal"
      nzTitle="Confirmation!"
      (nzOnCancel)="handleCancelVPARDelete()"
      (nzOnOk)="deleteVPAR()"
      nzWidth="30%"
      [nzOkText]="'Confirm'"
    >
      <ng-container *nzModalContent>
        <div class="col-12 row m-0">
          <h5 *ngIf="selectedVPARForDeletion" style="text-align: center">
            Are you sure, you want to Delete the VPAR -
            <span style="color: blue">{{
              selectedVPARForDeletion.vparsName
            }}</span>
          </h5>
        </div>
      </ng-container>
    </nz-modal>
    <nz-modal
      [(nzVisible)]="isVPARSModalVisible"
      [nzTitle]="editVPAR ? 'Update VPAR' : 'Add New VPAR'"
      (nzOnCancel)="handleVPARModalCancel()"
      (nzOnOk)="editVPAR ? updateVPARSData() : handleVPARSModalOk()"
      [nzOkDisabled]="!vparSystem || !vparName || !vparIp"
      nzWidth="50%"
      [nzOkText]="editVPAR ? 'Update' : 'Create'"
    >
      <ng-container *nzModalContent>
        <div class="col-12 row m-0">
          <div class="col-4">
            <p class="mb-2" style="font-weight: bold">System</p>
            <select class="form-control" [(ngModel)]="vparSystem">
              <option *ngFor="let item of systems" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="col-4">
            <p class="mb-2" style="font-weight: bold">VPAR Name</p>
            <input
              class="form-control"
              [(ngModel)]="vparName"
              placeholder="XXXXXXXX"
              style="text-transform: uppercase"
              maxlength="8"
            />
          </div>
          <div class="col-4">
            <p class="mb-2" style="font-weight: bold">VPAR IP</p>
            <input
              class="form-control"
              placeholder="XXX.XXX.XXX.XXX"
              [(ngModel)]="vparIp"
            />
          </div>
        </div>
        <!-- <ng-container
        *ngIf="selectedLniata && selectedLniata.ActiveSystems.length"
      >
        <div class="my-2">
          <div
            class="col-12"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: bold;
              border-bottom: 1px solid black;
            "
          >
            <div class="col-2">Sl.No</div>
            <div class="col-3">VPARS Name</div>
            <div class="col-3">Release</div>
          </div>
          <div
            class="col-12 mt-2"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
            *ngFor="let item of selectedLniata.ActiveSystems; let i = index"
          >
            <div class="col-2">{{ i + 1 }}</div>
            <div class="col-3">{{ item }}</div>
            <div class="col-3">
              <button
                (click)="openRemoveVPARSModal(item)"
                class="btn btn-none"
              >
                <mat-icon style="color: red">remove_circle_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container> -->
      </ng-container>
    </nz-modal>
  </mat-tab>
  <mat-tab label="Scripts">
    <div class="container-fluid" style="height: 90vh; overflow: auto">
      <div class="card m-2 p-2 row">
        <div class="col-12 card-title row m-0">
          <h4 class="col-2" style="text-decoration: underline">User Group:</h4>
          <h4 class="col-2" style="text-decoration: underline">System:</h4>
          <h4 class="col-8" style="text-decoration: underline">Date Range:</h4>
          <div class="col-12 row m-0 mt-2">
            <div class="col-2">
              <select [(ngModel)]="selectedGroupName" class="form-control">
                <option value="" disabled selected>Select Group</option>
                <option *ngFor="let system of groupNames" [value]="system">
                  <h6 style="font-weight: bold">{{ system }}</h6>
                </option>
              </select>
            </div>
            <div class="col-2">
              <select [(ngModel)]="selectedSystem" class="form-control">
                <option value="" disabled selected>Select System</option>
                <option *ngFor="let system of systems" [value]="system">
                  <h6 style="font-weight: bold">{{ system }}</h6>
                </option>
              </select>
            </div>
            <div class="col-3">
              <input type="date" class="form-control" [(ngModel)]="fromDate" />
            </div>
            <div class="col-3">
              <input type="date" class="form-control" [(ngModel)]="toDate" />
            </div>
            <div class="col-2">
              <button
                [disabled]="!selectedSystem || !fromDate || !toDate"
                (click)="
                  this.getAllScriptsBasedOnUser();
                  this.getScriptsFromPlogByDateRange();
                  this.getScriptsNotFromPlogByDateRange();
                  this.getAllScriptsByDateRange();
                  this.getAllScriptsByStatusByDateRange()
                "
                class="btn btn-primary"
              >
                Generate
              </button>
            </div>
          </div>
        </div>

        <div class="row card-body">
          <div class="card col-12" *ngIf="userScriptsChartOption">
            <highcharts-chart
              [Highcharts]="Highcharts"
              #chartContainer
              [options]="userScriptsChartOption"
              style="width: 98%; height: 400px"
            ></highcharts-chart>
          </div>
          <div class="col-12 mt-2 row">
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="allScriptListChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="allScriptListByStatusChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="scriptFromPlogPieChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="scriptNotFromPlogPieChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Users">
    <div class="container-fluid" style="height: 90vh; overflow: auto">
      <div class="card m-2 p-2 row">
        <div class="col-12 row m-0">
          <div
            class="col-12 row m-0"
            *ngFor="let item of userList; let i = index"
            [ngClass]="{ 'col-12': i == 0, 'col-6': i != 0 }"
          >
            <div class="m-2 col-12 card py-2">
              <div
                class="card-title bg-info rounded p-2 m-0"
                style="display: flex; align-items: center; color: white"
              >
                <mat-icon class="mr-2">account_circle</mat-icon>
                {{ item.groupName }} ({{ item.userList.length }})
              </div>
              <div class="row card-body p-2">
                <ng-container *ngFor="let user of item.userList">
                  <div
                    [ngClass]="{ 'col-2': i == 0, 'col-3': i != 0 }"
                    class="mt-2"
                  >
                    <app-usercard
                      [connectedUsersList]="connectedUsersList"
                      [userData]="user"
                    ></app-usercard>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card mb-2 col-12" *ngIf="dbDocsListchartOptions">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="dbDocsListchartOptions"
              style="width: 100%; height: 50vh; display: block"
            ></highcharts-chart>
          </div>
        </div>
        <h4>
          Documents count in List view
          <button class="btn btn-none btn-sm" (click)="getServerUtilization()">
            <mat-icon class="mt-1">refresh</mat-icon>
          </button>
        </h4>
        <div class="col-12 row m-0">
          <ng-container *ngFor="let item of dbDocsList; let i = index">
            <div class="col-3 mb-2">
              <app-utilization-card
                [value]="item.value | numberFormatter"
                icon="storage"
                [label]="item.label | titlecase"
                [brand]="colorArray[i]"
                brand_color="white"
              ></app-utilization-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Lniatas">
    <div class="container-fluid" style="height: 90vh; overflow: auto">
      <div class="card m-2 p-0 py-2 row">
        <div class="col-12 row">
          <div class="col-6">
            <h5 style="font-weight: bold; text-align: center">
              CMD Lniata
              <button
                title="Configure New CMD Lniata"
                (click)="openLniataModal('CMD')"
                class="btn btn-none"
              >
                <mat-icon style="color: blue">add_circle_outline</mat-icon>
              </button>
            </h5>
          </div>
          <div class="col-6">
            <h5 style="font-weight: bold; text-align: center">
              ALC Lniata
              <button
                title="Configure New ALC Lniata"
                (click)="openLniataModal('ALC')"
                class="btn btn-none"
              >
                <mat-icon style="color: blue">add_circle_outline</mat-icon>
              </button>
            </h5>
          </div>
        </div>
        <div class="col-12 row m-0">
          <div class="col-6 card p-0 py-2">
            <div class="col-12 row m-0">
              <ng-container *ngFor="let item of cmdLniataList; let i = index">
                <div class="col-4 p-1">
                  <app-lniata-card
                    [value]="item.ActiveSystems.length"
                    [label]="item.lniataName | uppercase"
                    [brand]="colorArray[i]"
                    brand_color="white"
                    (deleteLniata)="openDeleteModal(item, 'CMD')"
                    (viewLniata)="handleViewLniata(item)"
                  ></app-lniata-card>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-6 card p-0 py-2">
            <div class="col-12 row m-0">
              <ng-container *ngFor="let item of alcLniataList; let i = index">
                <div class="col-4 p-1">
                  <app-lniata-card
                    [value]="item.ActiveSystems.length"
                    [label]="item.lniataName | uppercase"
                    [brand]="colorArray[i]"
                    brand_color="white"
                    (deleteLniata)="openDeleteModal(item, 'ALC')"
                    (viewLniata)="handleViewLniata(item)"
                  ></app-lniata-card>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nz-modal
      [(nzVisible)]="isLniataModalVisible"
      [nzTitle]="edit ? 'Update Lniata' : 'Add New Lniata'"
      (nzOnCancel)="handleLniataModalCancel()"
      (nzOnOk)="handleLniataModalOk()"
      [nzOkDisabled]="!newLniataName || !newLniataType"
      nzWidth="50%"
      [nzOkText]="edit ? 'Update' : 'Create'"
    >
      <ng-container *nzModalContent>
        <div class="col-12 row m-0">
          <div class="col-6">
            <p class="mb-2" style="font-weight: bold">Lniata Type</p>
            <select class="form-control" [(ngModel)]="newLniataType">
              <option value="CMD">CMD</option>
              <option value="ALC">ALC</option>
            </select>
          </div>
          <div class="col-6">
            <p class="mb-2" style="font-weight: bold">Lniata</p>
            <input class="form-control" [(ngModel)]="newLniataName" />
          </div>
        </div>
        <ng-container
          *ngIf="selectedLniata && selectedLniata.ActiveSystems.length"
        >
          <div class="my-2">
            <div
              class="col-12"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                border-bottom: 1px solid black;
              "
            >
              <div class="col-2">Sl.No</div>
              <div class="col-3">VPARS Name</div>
              <div class="col-3">Release</div>
            </div>
            <div
              class="col-12 mt-2"
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
              *ngFor="let item of selectedLniata.ActiveSystems; let i = index"
            >
              <div class="col-2">{{ i + 1 }}</div>
              <div class="col-3">{{ item }}</div>
              <div class="col-3">
                <button
                  (click)="openRemoveVPARSModal(item)"
                  class="btn btn-none"
                  title="Release VPAR"
                >
                  <mat-icon style="color: red">remove_circle_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </nz-modal>
    <nz-modal
      [(nzVisible)]="openConfirmationModal"
      nzTitle="Confirmation!"
      (nzOnCancel)="handleConfirmationModalCancel()"
      (nzOnOk)="deleteLniata()"
      nzWidth="30%"
      [nzOkText]="'Confirm'"
    >
      <ng-container *nzModalContent>
        <div class="col-12 row m-0">
          <h5 *ngIf="selectedLniata" style="text-align: center">
            Are you sure, You want to Delete the Lniata -
            <span style="color: blue">{{ selectedLniata.lniataName }}</span>
          </h5>
        </div>
      </ng-container>
    </nz-modal>
    <nz-modal
      [(nzVisible)]="openVPARSReleaseConfirmation"
      nzTitle="Confirmation!"
      (nzOnCancel)="handleRemoveVPARSCancel()"
      (nzOnOk)="releaseVPARS()"
      nzWidth="30%"
      [nzOkText]="'Confirm'"
    >
      <ng-container *nzModalContent>
        <div class="col-12 row m-0">
          <h5 *ngIf="selectedLniata" style="text-align: center">
            Are you sure, you want to Release the LNIATA -
            <span style="color: blue">{{ selectedLniata.lniataName }}</span>
          </h5>
        </div>
      </ng-container>
    </nz-modal>
  </mat-tab>
</mat-tab-group>
