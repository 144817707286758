import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { MatTabGroup } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-send-request",
  templateUrl: "./send-request.component.html",
  styleUrls: ["./send-request.component.scss"],
})
export class SendRequestComponent implements OnInit {
  @ViewChild("bodyTabs", { static: false }) bodyTabs: MatTabGroup;

  httpAdapter: HttpAdapterService;
  requestMethods: string[];
  defaultMethod = "GET";
  requestURL: string;
  responseData: string;
  responseTime: number;
  responseStatus: string;
  responseJsonData: any;
  tabIndexArray: string[];
  bodyTabIndexArray: string[];
  tabIndex: number;
  bodyTabIndex: number;
  rawData: string;
  jsonError: boolean;

  headers: { key: any; value: any }[];
  requestBodyTypes: string[];
  requestBody: any;
  dataTypes: string[];
  errorMessage: string;

  constructor(private http: HttpClient, public dialog: MatDialog) {
    this.httpAdapter = new HttpAdapterService(this.http);
    this.requestMethods = ["GET", "POST", "PUT", "DELETE", "PATCH"];
    this.requestURL = "https://jsonplaceholder.typicode.com/posts/1";
    this.dataTypes = ["Text", "Number", "Boolean"];
    this.tabIndexArray = ["Header", "Body"];
    this.bodyTabIndexArray = ["RawData", "FormData"];
    this.requestBody = [{ key: "", value: "" }];
    this.headers = [{ key: "Content-Type", value: "application/json" }];
    this.requestBodyTypes = [""];
    this.rawData = "";
    this.tabIndex = 0;
    this.bodyTabIndex = 0;
    this.jsonError = false;
  }

  ngOnInit() { }

  tabChange(event: any) {
    const self = this;
    self.tabIndex = event.index;
    if (self.tabIndexArray[self.tabIndex] === "Body")
      self.bodyTabs.realignInkBar();
  }

  bodyTabChange(event: any) {
    this.bodyTabIndex = event.index;
  }

  addKeyValue(tabType: string) {
    if (tabType === "Body") this.requestBody.push({ key: "", value: "" });
    else if (tabType === "Headers") this.headers.push({ key: "", value: "" });

    if (tabType === "Body") {
      this.requestBodyTypes.push("");
    }
  }

  isDisbaled(tabType: string) {
    let arrayData;
    if (tabType === "Body") arrayData = this.requestBody;
    else if (tabType === "Headers") arrayData = this.headers;

    if (arrayData.length > 0)
      if (
        arrayData[arrayData.length - 1].key === "" ||
        arrayData[arrayData.length - 1].value === ""
      )
        return true;
    return false;
  }

  removeKeyValue(index: number, tabType: string) {
    if (tabType === "Body") this.requestBody.splice(index, 1);
    else if (tabType === "Headers") this.headers.splice(index, 1);
    this.requestBodyTypes.splice(index, 1);
  }

  reducerJson(object, item) {
    object[item.key] = item.value;
    return object;
  }

  constructJsonData(tabType: string) {
    let arrayData;
    if (tabType === "FormData") arrayData = this.requestBody;
    else if (tabType === "Headers") arrayData = this.headers;
    let constructedObject = {};
    constructedObject = arrayData.reduce(this.reducerJson, {});
    return constructedObject;
  }

  sendRequest() {
    const self = this;
    self.resetResponseData();
    const tabSelected = self.tabIndexArray[self.tabIndex];
    const bodyTab = self.bodyTabIndexArray[self.bodyTabIndex];
    let bodyData;
    const methodCheck =
      self.defaultMethod !== "GET" && self.defaultMethod !== "DELETE";
    //Parsing the KEY-VALUE/Raw JSON Data to the Structurized format of JSON
    if (bodyTab === "FormData" && methodCheck) {
      self.requestBody.forEach((item, index) => {
        if (self.requestBodyTypes[index] === "Number") {
          item = Number(item);
        }
      });
      bodyData = self.constructJsonData("FormData");
    } else if (bodyTab === "RawData" && methodCheck) {
      try {
        bodyData = self.rawData ? JSON.parse(self.rawData) : {};
      } catch (error) {
        console.log(error);

        // alert("Please check the inserted data");
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          panelClass: "my-alert-message-class",
          data: { Title: "Message", Content: "Please check the inserted data" },
        });
        return;
      }
    }
    const request = {
      url: self.requestURL,
      method: self.defaultMethod,
      headers: self.headers,
      keyValue: self.requestBody,
      rawData: self.rawData,
      resStatus: self.responseStatus,
      responseData: self.responseJsonData,
      responseTime: self.responseTime,
    };
    var params = {
      url: "saveRequest",
      data: request,
    };

    self.httpAdapter.httpPostNew(params).subscribe((response) => {

      self.responseStatus = response.apiResponse.status;
      self.responseTime = response.apiResponse.time;
      self.responseJsonData = response.apiResponse.data;
      self.responseData = JSON.stringify(response.apiResponse.data);

    }, (err) => {
      console.log("Error in Fetching Data -->", err);
      self.errorMessage = err;
    });
    return

    switch (self.defaultMethod) {
      case "GET": {
        const params = {
          url: self.requestURL,
          headers: self.constructJsonData("Headers"),
        };
        self.httpAdapter.httpSimulationGet(params, function (err, res) {
          if (err) {
            console.log("Error in Fetching Data -->", err);
            self.errorMessage = err;
          } else {
            self.responseStatus = res.status;
            self.responseTime =
              res.headers.get("endTime") - res.headers.get("startTime");
            self.responseJsonData = res.body;
            self.responseData = JSON.stringify(res.body);
            self.insertDataToDB();
          }
        });
        break;
      }

      case "POST": {
        const params = {
          url: self.requestURL,
          data: bodyData,
          headers: self.constructJsonData("Headers"),
        };
        console.log("POST Request after constructing", params);
        self.httpAdapter.httpSimulationPost(params, function (err, res) {
          if (err) {
            console.log("Error in Fetching Data -->", err);
          } else {
            console.log("Got");
            self.responseStatus = res.status;
            self.responseTime =
              res.headers.get("endTime") - res.headers.get("startTime");
            self.responseJsonData = res.body;
            self.responseData = JSON.stringify(res.body);
            self.insertDataToDB();
          }
        });
        break;
      }

      case "PUT": {
        const params = {
          url: self.requestURL,
          data: bodyData,
          headers: self.constructJsonData("Headers"),
        };
        console.log("POST Request after constructing", params);
        self.httpAdapter.httpSimulationPut(params, function (err, res) {
          if (err) {
            console.log("Error in Fetching Data -->", err);
          } else {
            self.responseStatus = res.status;
            self.responseTime =
              res.headers.get("endTime") - res.headers.get("startTime");
            self.responseJsonData = res.body;
            self.responseData = JSON.stringify(res.body);
            self.insertDataToDB();
          }
        });
        break;
      }

      case "DELETE": {
        const params = {
          url: self.requestURL,
          headers: self.constructJsonData("Headers"),
        };

        self.httpAdapter.httpSimulationDelete(params, function (err, res) {
          if (err) {
            console.log("Error in Fetching Data -->", err);
          } else {
            self.responseStatus = res.status;
            self.responseTime =
              res.headers.get("endTime") - res.headers.get("startTime");
            self.responseJsonData = res.body;
            self.responseData = JSON.stringify(res.body);
            self.insertDataToDB();
          }
        });
        break;
      }

      case "PATCH": {
        const params = {
          url: self.requestURL,
          data: bodyData,
          headers: self.constructJsonData("Headers"),
        };

        self.httpAdapter.httpSimulationPatch(params, function (err, res) {
          if (err) {
            console.log("Error in Fetching Data -->", err);
          } else {
            self.responseStatus = res.status;
            self.responseTime =
              res.headers.get("endTime") - res.headers.get("startTime");
            self.responseJsonData = res.body;
            self.responseData = JSON.stringify(res.body);
            self.insertDataToDB();
          }
        });
        break;
      }
    }
  }

  validateJsonData(value) {
    var self = this;
    try {
      JSON.parse(value);
      self.jsonError = false;
    } catch (error) {
      self.jsonError = true;
    }
  }

  resetData() {
    const self = this;
    self.defaultMethod = "GET";
    this.requestURL = "";
    self.requestBody = [{ key: "", value: "" }];
    self.requestBodyTypes = [""];
    self.headers = [];
    self.rawData = "";
    self.tabIndex = 0;
    self.bodyTabIndex = 0;
  }

  resetResponseData() {
    var self = this;
    self.responseData = "";
    self.responseStatus = "";
    self.responseTime = 0;
    self.responseJsonData = "";
    self.errorMessage = "";
  }

  insertDataToDB() {
    var self = this;
    const request = {
      url: self.requestURL,
      method: self.defaultMethod,
      headers: self.headers,
      keyValue: self.requestBody,
      rawData: self.rawData,
      resStatus: self.responseStatus,
      responseData: self.responseJsonData,
      responseTime: self.responseTime,
    };
    var params = {
      url: "saveRequest",
      data: request,
    };

    self.httpAdapter.httpPost(params, function (error, response) {
      if (!error) {
        // alert(response.body.msg);
      }
    });
  }
}
