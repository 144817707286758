import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-phases",
  templateUrl: "./phases.component.html",
  styleUrls: ["./phases.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PhasesComponent implements OnInit {
  // private _conf: any;
  selectedSystem: any;
  public options;
  vparsList = [];
  filteredOptions1: Observable<string[]>;
  filteredOptions2: Observable<string[]>;

  @ViewChild("autoCompleteInput1", { read: MatAutocompleteTrigger })
  autoComplete1: MatAutocompleteTrigger;

  @ViewChild("autoCompleteInput2", { read: MatAutocompleteTrigger })
  autoComplete2: MatAutocompleteTrigger;

  @Input() PhasesForm;
  @Input() validator;

  public newKeys = [];
  subscription: any;
  /**
   * Below function is to collect the newly added forms in an arry
   */
  @Input() public set newKeysAdded(val: string) {
    if (val) {
      this.newKeys = [];
      Object.keys(val).map((value) => {
        this.newKeys.push(value);
      });
    }
  }
  selectedVPAr = "";
  @Output() changedConf = new EventEmitter();

  //public Phases: FormGroup;
  constructor(
    private fb: FormBuilder,
    private sharedServices: ShareDataService
  ) {}

  ngOnInit() {
    this.systemListener();
  }
  trackByFn(index, item) {
    return index; // Assuming your item has a unique 'id' property
  }
  get phase1() {
    return this.PhasesForm.get("PHASE1").get("VPARS_NAME");
  }

  get phase2() {
    return this.PhasesForm.get("PHASE2").get("VPARS_NAME");
  }

  systemListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      ) {
        this.selectedSystem = data["selectedSystem"];
        this.options = data["vpars"];
        this.vparsList = data["vpars"];
        console.log(this.options);

        // this.phasesInit();
      }
    });
  }

  // filter() {
  //   let Ph2Data: any = this.PhasesForm.get("PHASE2").get("VPARS_NAME");

  //   this.filteredOptions2 = Ph2Data.valueChanges.pipe(
  //     startWith(""),
  //     map((value) => this._filter2(value.toString()))
  //   );
  // }
  // phasesInit() {
  //   let Ph1Data: any = this.PhasesForm.get("PHASE1").get("VPARS_NAME");
  //   this.filteredOptions1 = Ph1Data.valueChanges.pipe(
  //     startWith(""),
  //     map((value) => this._filter1(value.toString()))
  //   );
  //   window.addEventListener("scroll", this.scrollEvent1, true);

  //   let Ph2Data: any = this.PhasesForm.get("PHASE2").get("VPARS_NAME");
  //   this.filteredOptions2 = Ph2Data.valueChanges.pipe(
  //     startWith(""),
  //     map((value) => this._filter2(value.toString()))
  //   );
  //   window.addEventListener("scroll", this.scrollEvent2, true);
  // }
  // //Code to update the positon of the Panel in the
  // scrollEvent1 = (event: any): void => {
  //   if (this.autoComplete1.panelOpen) {
  //     // \\this.autoComplete.closePanel();
  //     this.autoComplete1.updatePosition();
  //   }
  // };

  // //Code for auto Complete Filter(Filter that check if the options includes the value)
  // private _filter1(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.options.filter((option) =>
  //     option.toLowerCase().includes(filterValue)
  //   );
  // }

  // scrollEvent2 = (event: any): void => {
  //   if (this.autoComplete2.panelOpen) {
  //     // this.autoComplete.closePanel();
  //     this.autoComplete2.updatePosition();
  //   }
  // };

  // //Code for auto Complete Filter(Filter that check if the options includes the value)
  // private _filter2(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   console.log(this.options);

  //   return this.options.filter((option) =>
  //     option.toLowerCase().includes(filterValue)
  //   );
  // }
}
