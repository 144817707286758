<div
  style="
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  "
>
  <h6 class="mr-4 ml-4">Script: {{ scriptName }}</h6>
  <form class="mr-5 mt-2">
    <mat-form-field class="example-full-width matDropdown" appearance="fill">
      <mat-label>Filter By Transaction</mat-label>
      <input
        type="text"
        placeholder="Select a Transaction"
        aria-label="Filter"
        matInput
        [formControl]="myControl"
        (input)="myControl.setValue(myControl.value.toUpperCase())"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          (click)="
            phase1offset = 0;
            getRecordsFromScriptIdOrTransIdPhase1('');
            phase2offset = 0;
            getRecordsFromScriptIdOrTransIdPhase2('')
          "
          class="nonsubScriptStyle"
          [value]="'ALL'"
          ><span style="font-weight: 500">ALL</span></mat-option
        >
        <ng-container
          *ngFor="
            let transaction of filteredOptions | async;
            trackBy: trackByFn
          "
        >
          <mat-option
            (click)="
              phase1offset = 0;
              getRecordsFromScriptIdOrTransIdPhase1(transaction);
              phase2offset = 0;
              getRecordsFromScriptIdOrTransIdPhase2(transaction)
            "
            [value]="transaction"
          >
            <!--         (click)="filterMethod(script, true)"
 -->
            <span class="subScriptStyle">{{ transaction }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <button
    class="btn btn-info"
    [title]="showSP ? 'Hide SP record' : 'Show SP record'"
    style="display: flex; align-items: center"
    (click)="showSP = !showSP"
  >
    SP
    <mat-icon class="addHover ml-1" matSuffix>
      {{ showSP ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
  <button
    [title]="showSM ? 'Hide SM record' : 'Show SM record'"
    class="btn btn-success ml-4"
    style="display: flex; align-items: center"
    (click)="showSM = !showSM"
  >
    SM
    <mat-icon class="addHover ml-1" matSuffix>
      {{ showSM ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
  <button
    [title]="!recordsMissing ? 'Missing Record' : 'All'"
    class="btn btn-success ml-5"
    style="display: flex; align-items: center"
    (click)="
      recordsMissing = recordsMissing ? '' : 'true';
      phase1offset = 0;
      phase2offset = 0;
      getRecordsFromScriptIdOrTransIdPhase1('');
      getRecordsFromScriptIdOrTransIdPhase2('')
    "
  >
    Records Missing
    <mat-icon class="addHover ml-1" matSuffix>
      {{ !recordsMissing ? "visibility_off" : "visibility" }}
    </mat-icon>
  </button>
</div>
<mat-tab-group
  [(selectedIndex)]="currentTabIndex"
  (selectedTabChange)="tabChange($event)"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="matTabTextArea">
        <span class="matLabel">Phase 1</span>
      </div>
    </ng-template>
    <div class="matTabContent p-0 m-0" style="width: 100%">
      <div
        style="
          width: 100%;
          max-height: 80vh;
          overflow-y: auto;
          overflow-x: hidden;
        "
        class="scrollableView"
      >
        <table
          class="table table-hover table-sm table-bordered"
          style="width: 100%"
        >
          <thead class="thead-light">
            <tr style="position: sticky; top: 0px; z-index: 10">
              <th style="max-width: 10vw">
                <p style="text-align: center; margin: 0">Trans Id</p>
              </th>
              <th>
                <div style="display: flex">
                  <p style="text-align: center; width: 85px; margin: 0">
                    Record
                  </p>
                  <p style="text-align: center; width: 40px; margin: 0">Copy</p>
                  <p class="col-md-7 m-0">Record Data</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- <ng-container

            > -->
            <tr
              *ngFor="
                let item of phase1ExecutionData;
                let i = index;
                let last = last;
                trackBy: trackByFn
              "
              traceLazyload
              (reachedBottom)="loadMorePhase1Data(last, i)"
              [ngClass]="
                item.transactionId === 'INPUT' ? 'inputClass' : 'outputClass'
              "
            >
              <td style="max-width: 10vw">
                <div
                  class="p-0 m-0"
                  style="width: 100%; display: flex; align-items: center"
                >
                  <p
                    class="col-md-1 m-0"
                    style="text-align: center; font-weight: bold"
                  >
                    {{ item.transactionId }}
                  </p>
                </div>
              </td>
              <td style="overflow-x: auto; max-width: 70vw">
                <table class="table table-hover table-sm table-bordered mb-0">
                  <tr>
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "MI" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.MI)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p
                        class="record col-md-9 m-0 p-0"
                        style="font-family: 'Courier New', Courier, monospace"
                      >
                        {{ item.MI }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "MO" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.MO)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p
                        class="record col-md-9 m-0 p-0"
                        style="font-family: 'Courier New', Courier, monospace"
                      >
                        {{ item.MO }}
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="showSP">
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "SP" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.SP)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p class="record col-md-9 m-0 p-0">
                        {{ item.SP }}
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="showSM">
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "SM" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.SM)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p class="record col-md-9 m-0 p-0">
                        {{ item.SM }}
                      </p>
                    </td>
                  </tr>
                </table>
                <!-- <div class="p-0 m-0" style="width: 100%">
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "MI" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(item.MI)"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p
                      class="record col-md-9 p-0"
                      style="font-family: 'Courier New', Courier, monospace"
                    >
                      {{ item.MI }}
                    </p>
                  </div>
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "MO" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(item.MO)"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p
                      class="record p-0"
                      style="
                        font-family: 'Courier New', Courier, monospace;
                        width: 88%;
                      "
                    >
                      {{ item.MO }}
                    </p>
                  </div>
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "SP" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(removeSpace(item.SP))"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p class="record col-md-9 p-0">
                      {{ item.SP }}
                    </p>
                  </div>
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "SM" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(item.SM)"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p class="record col-md-9 p-0">{{ item.SM }}</p>
                  </div>
                </div> -->
              </td>

              <!-- <td>
              <b>{{ item.transactionId }}</b>
            </td>

            <td>{{ item.transactionId }}</td>
            <td
              style="cursor: pointer"
              (click)="copyToClipboard(item.transactionId)"
            >
              <img src="assets/Images/content_copy.png" />
            </td>
            <td
              style="
                overflow: auto;
                text-align: left;
                text-overflow: ellipsis;
              "
            >
              {{ item.transactionId }}
            </td> -->
            </tr>
            <!-- </ng-container> -->
          </tbody>
        </table>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div class="matTabTextArea">
        <span class="matLabel">Phase 2</span>
      </div>
    </ng-template>
    <div class="matTabContent p-0 m-0" style="width: 100%">
      <div
        style="
          width: 100%;
          max-height: 80vh;
          overflow-y: auto;
          overflow-x: hidden;
        "
        class="scrollableView"
      >
        <table
          class="table table-hover table-sm table-bordered"
          style="width: 100%"
        >
          <thead class="thead-light">
            <tr style="position: sticky; top: 0px; z-index: 10">
              <th style="max-width: 10vw">
                <p style="text-align: center; margin: 0">Trans Id</p>
              </th>
              <th>
                <div style="display: flex">
                  <p style="text-align: center; width: 85px; margin: 0">
                    Record
                  </p>
                  <p style="text-align: center; width: 40px; margin: 0">Copy</p>
                  <p class="col-md-7 m-0">Record Data</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- <ng-container

            > -->
            <tr
              *ngFor="
                let item of phase2ExecutionData;
                let i = index;
                let last = last;
                trackBy: trackByFn
              "
              traceLazyload
              (reachedBottom)="loadMorePhase2Data(last, i)"
              [ngClass]="
                item.transactionId === 'INPUT' ? 'inputClass' : 'outputClass'
              "
            >
              <td style="max-width: 10vw">
                <div
                  class="p-0 m-0"
                  style="width: 100%; display: flex; align-items: center"
                >
                  <p
                    class="col-md-1 m-0"
                    style="text-align: center; font-weight: bold"
                  >
                    {{ item.transactionId }}
                  </p>
                </div>
              </td>
              <td style="overflow-x: auto; max-width: 70vw">
                <table class="table table-hover table-sm table-bordered mb-0">
                  <tr>
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "MI" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.MI)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p
                        class="record col-md-9 m-0 p-0"
                        style="font-family: 'Courier New', Courier, monospace"
                      >
                        {{ item.MI }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "MO" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.MO)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p
                        class="record col-md-9 m-0 p-0"
                        style="font-family: 'Courier New', Courier, monospace"
                      >
                        {{ item.MO }}
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="showSP">
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "SP" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.SP)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p class="record col-md-9 m-0 p-0">
                        {{ item.SP }}
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="showSM">
                    <td style="width: 85px">
                      <p
                        style="
                          text-align: center;
                          font-weight: bold;
                          width: 70px;
                          margin: 0;
                        "
                      >
                        {{ "SM" }}
                      </p>
                    </td>
                    <td style="width: 40px">
                      <p style="text-align: center; width: 40px; margin: 0">
                        <img
                          style="cursor: pointer"
                          (click)="copyToClipboard(item.SM)"
                          src="assets/Images/content_copy.png"
                        />
                      </p>
                    </td>
                    <td style="white-space: nowrap">
                      <p class="record col-md-9 m-0 p-0">
                        {{ item.SM }}
                      </p>
                    </td>
                  </tr>
                </table>
                <!-- <div class="p-0 m-0" style="width: 100%">
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "MI" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(item.MI)"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p
                      class="record col-md-9 p-0"
                      style="font-family: 'Courier New', Courier, monospace"
                    >
                      {{ item.MI }}
                    </p>
                  </div>
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "MO" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(item.MO)"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p
                      class="record p-0"
                      style="
                        font-family: 'Courier New', Courier, monospace;
                        width: 88%;
                      "
                    >
                      {{ item.MO }}
                    </p>
                  </div>
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "SP" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(removeSpace(item.SP))"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p class="record col-md-9 p-0">
                      {{ item.SP }}
                    </p>
                  </div>
                  <div class="recordContainer">
                    <p
                      style="text-align: center; font-weight: bold; width: 70px"
                    >
                      {{ "SM" }}
                    </p>
                    <p style="text-align: center; width: 40px">
                      <img
                        style="cursor: pointer"
                        (click)="copyToClipboard(item.SM)"
                        src="assets/Images/content_copy.png"
                      />
                    </p>
                    <p class="record col-md-9 p-0">{{ item.SM }}</p>
                  </div>
                </div> -->
              </td>

              <!-- <td>
              <b>{{ item.transactionId }}</b>
            </td>

            <td>{{ item.transactionId }}</td>
            <td
              style="cursor: pointer"
              (click)="copyToClipboard(item.transactionId)"
            >
              <img src="assets/Images/content_copy.png" />
            </td>
            <td
              style="
                overflow: auto;
                text-align: left;
                text-overflow: ellipsis;
              "
            >
              {{ item.transactionId }}
            </td> -->
            </tr>
            <!-- </ng-container> -->
          </tbody>
        </table>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
