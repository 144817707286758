<div class="reports">
  <mat-tab-group [selectedIndex]="0">
    <mat-tab label="Generate Reports">
      <ng-template matTabContent>
        <div class="reports__generate mat-elevation-z4">
          <app-generate-report [logs]="logs"></app-generate-report>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Edit PLOG">
      <ng-template matTabContent>
        <div class="reports__plog mat-elevation-z4">
          <app-plog></app-plog>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="HIT & Non HIT">
      <ng-template matTabContent>
        <div class="reports__hit mat-elevation-z4">
          <app-connect-linux [logs]="logs" [selectedSystem]="selectedSystem"></app-connect-linux>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
