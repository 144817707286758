import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialogue",
  templateUrl: "./dialogue.component.html",
  styleUrls: ["./dialogue.component.scss"],
})
export class DialogueComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  isConfirm = false;
  isClose = true;
  ngOnInit(): void {
    if (this.data["isClose"] != undefined) this.isClose = this.data["isClose"];
    if (this.data["isConfirm"]) this.isConfirm = this.data["isConfirm"];
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
