<form [formGroup]="conf">
  <div class="col-12 my-2 mb-4 row">
    <div class="col-6" formArrayName="MACRO">
      <button
        class="btn col-4 p-0 btn-primary"
        color="primary"
        (click)="addTrcMacro()"
      >
        Add MACRO
      </button>

      <div
        class="rounded border border-secondary p-2 mt-2"
        *ngIf="MACRO.controls.length"
      >
        <div
          class="form-group m-0 mb-2 row"
          [formGroupName]="i"
          *ngFor="let macro of MACRO.controls; let i = index"
        >
          <label class="col-4 col-form-label">MACRO Name</label>
          <div class="col-5">
            <input
              type="text"
              class="form-control"
              formControlName="NAME"
              placeholder="Macro Name"
            />
          </div>

          <!-- <div class="col-2" style="display: flex; align-items: center">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                formControlName="OPTION"
                type="checkbox"
              />
              <label class="form-check-label">Option</label>
            </div>
          </div> -->

          <div class="col-3">
            <button
              mat-flat-button
              color="warn"
              class="btn btn-danger btn-sm rounded-pill"
              (click)="removeMacro(i)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="SO" class="col-6 addSO">
      <button
        class="btn col-4 p-0 btn-primary"
        color="primary"
        (click)="addTrcSO()"
      >
        Add SO
      </button>
      <div
        *ngIf="SO.controls.length"
        class="rounded border border-secondary p-2 mt-2"
      >
        <div
          class="form-group m-0 mb-2 row"
          [formGroupName]="i"
          *ngFor="let inputfile of SO.controls; let i = index"
        >
          <label class="col-3 col-form-label">SO Name</label>
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              formControlName="NAME"
              placeholder="FILE NAME"
            />
          </div>

          <div class="col-3">
            <button
              mat-flat-button
              color="warn"
              class="btn btn-danger btn-sm rounded-pill"
              (click)="removeSO(i)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
