import { Injectable } from "@angular/core";
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LoaderService } from "../_services/loader.service";
import { DialogueComponent } from "../errorPages/dialogue/dialogue.component";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { UserService } from "../_services/user.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  count: number;
  unauthorizedErrorOccurred: boolean = false;
  selectedSystem: string = "";

  constructor(
    private loaderService: LoaderService,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private userService: UserService,
    public router: Router
  ) {
    this.count = 0;
  }

  removeRequest(request: HttpRequest<any>) {
    const i = this.requests.indexOf(request);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let updatedRequest: HttpRequest<any>;
    const startTime = new Date().getTime();
    this.requests.push(request);
    this.loaderService.isLoading.next(true);

    const token = this.cookieService.get("virusGHitter");
    console.log("HTTP Request");
    if (token) {
      updatedRequest = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${token}`),
      });
    } else {
      updatedRequest = request;
    }

    return new Observable((observer) => {
      const subscription = next.handle(updatedRequest).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            const endTime = new Date().getTime();
            event = event.clone({
              headers: event.headers
                .set("startTime", startTime.toString())
                .set("endTime", endTime.toString()),
            });
            this.removeRequest(request);
            observer.next(event);
          }
        },
        (err) => {
          if (
            err instanceof HttpErrorResponse &&
            (err.status === 401 || err.status === 403)
          ) {
            if (err.error.message == "Invalid token") {
              if (!this.unauthorizedErrorOccurred) {
                this.dialog.open(DialogueComponent, {
                  direction: "ltr",
                  minWidth: "350px",
                  position: { top: "10px" },
                  data: {
                    Title: "Unauthorized",
                    Content: "Token Expired!, Please Login.",
                  },
                });
              }
              this.unauthorizedErrorOccurred = true;
            } else {
              this.dialog.open(DialogueComponent, {
                direction: "ltr",
                minWidth: "350px",
                position: { top: "10px" },
                data: {
                  Title: "Unauthorized",
                  Content:
                    "You are not authorized to use this Resource!, Please Login.",
                },
              });
            }

            this.removeRequest(request);
            observer.error(err);
            this.userService.logout();
            observer.complete();
          } else {
            console.log("something went wrong", err);
            this.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              data: { Title: "Error Message", Content: "Something went wrong" },
            });
            observer.error(err);
            observer.complete();
            this.removeRequest(request);
          }
        },
        () => {
          this.removeRequest(request);
          observer.complete();
        }
      );

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
}
