<div class="my-parent-class">
    <a mat-icon-button mat-dialog-close class="close" color="primary" aria-label="Example icon button with a home icon">
        <mat-icon>close</mat-icon>
    </a>
    <h2 mat-dialog-title>{{data["Title"]}}</h2>
    <mat-dialog-content class="mat-typography pre-content" [innerHTML]="data['Content']">
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-raised-button *ngIf="isConfirm" class="matButton-ok" (click)="confirm()">OK</button> &nbsp; &nbsp;
        <button mat-raised-button *ngIf="isClose" mat-dialog-close class="matButton-close">Close</button>
    </mat-dialog-actions>
</div>