<div
  style="
    width: 100%;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
  "
>
  <div
    [ngClass]="{
      phaseOne: true
    }"
  >
    <div class="phaseContainer" [ngClass]="{}">
      <div class="phaseNameContainer">
        <div class="phaseContent">
          <span class="PhaseTotalCount">{{ this.ph1Length }}</span>
          <!-- <img class="ml-2 mr-2 " style="width: 20px; height: 20px;" src="assets/Images/phaseCountArrow.png" /> -->
          <mat-icon style="color: white">chevron_right</mat-icon>
          <span class="phaseName">{{ "PH1" }}</span>
        </div>
        <span class="vparsContainer">
          {{ this.currentScript.conf.PHASES.PHASE1.VPARS_NAME }}
        </span>
      </div>
    </div>
  </div>
  <div
    [ngClass]="{
      phaseTwo: true
    }"
  >
    <div class="phaseContainer" [ngClass]="{}">
      <div class="phaseNameContainer">
        <div class="phaseContent">
          <span class="PhaseTotalCount">{{ this.ph2Length }}</span>
          <!-- <img src="assets/Images/phaseCountArrow.png" /> -->
          <mat-icon style="color: white">chevron_right</mat-icon>

          <span class="phaseName">{{ "PH2" }}</span>
        </div>
        <span class="vparsContainer">
          {{ this.currentScript.conf.PHASES.PHASE2.VPARS_NAME }}
        </span>
      </div>
    </div>
  </div>
</div>

<cdk-virtual-scroll-viewport
  class="execLogContainer"
  [itemSize]="ph1Count || ph2Count"
  *ngIf="PH1Data.length || PH2Data.length"
  id="execLogContainer"
>
  <div
    *ngIf="PH1Data.length || PH2Data.length"
    style="width: 100%; display: flex; justify-content: space-between"
  >
    <div style="width: 49.5%">
      <app-subscriptcontainer
        [PHData]="PH1Data"
        [phase]="'PH1'"
        [total]="
          this.currentScript.percentage.PHASE1Total ||
          this.currentScript.percentage.PHASE2Total
        "
        [executionId]="executionId"
        [scriptName]="this.currentScript.name"
        [selectedSystem]="selectedSystem"
        [filteredScript]="filteredScript"
        (loadMoreDataEmitter)="loadMoreData($event, 'PH1')"
        (loadSubScriptEmitter)="loadSubScript($event)"
        (TraceDataEmitter)="moveToTrace($event)"
        [aciveSubScript]="activeSubScript"
      ></app-subscriptcontainer>
    </div>
    <div style="width: 49.5%">
      <app-subscriptcontainer
        [PHData]="PH2Data"
        [phase]="'PH2'"
        [total]="
          this.currentScript.percentage.PHASE1Total ||
          this.currentScript.percentage.PHASE2Total
        "
        [executionId]="executionId"
        [scriptName]="this.currentScript.name"
        [selectedSystem]="selectedSystem"
        (loadSubScriptEmitter)="loadSubScript($event)"
        [filteredScript]="filteredScript"
        (loadMoreDataEmitter)="loadMoreData($event, 'PH2')"
        (TraceDataEmitter)="moveToTrace($event)"
        [aciveSubScript]="activeSubScript"
      ></app-subscriptcontainer>
    </div>
  </div>
</cdk-virtual-scroll-viewport>

<div class="fixedButton">
  <div>
    <button mat-icon-button class="rounded-circle goTop" (click)="scrollTop()">
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
  <div>
    <button
      mat-icon-button
      class="rounded-circle goDown"
      (click)="scrollDown()"
    >
      <mat-icon fontIcon="arrow_downward"></mat-icon>
    </button>
  </div>
</div>
