import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "EFT";

  constructor(private _snackBar: MatSnackBar) {
    if (environment.production) console.log = function () { }
  }
  public showSnackBar(message, type) {
    var self = this;
    if (type == "error") {
      self._snackBar.open(message, "ERROR!", {
        panelClass: "errorNotification",
        duration: 2000,
      });
    }
    if (type == "success") {
      self._snackBar.open(message, "Success!", {
        panelClass: "successNotification",
        duration: 2000,
      });
    }
    if (type == "notes") {
      self._snackBar.open(message, "Notes!", {
        panelClass: "infoNotification",
        duration: 2000,
      });
    }
  }

  public showPreloadSnackBar(message, type) {
    var self = this;
    if (type == "error") {
      self._snackBar.open(message, "ERROR!", {
        panelClass: "errorNotification",
        duration: 400000,
      });
    }
    if (type == "success") {
      self._snackBar.open(message, "Success!", {
        panelClass: "successNotification",
        duration: 400000,
      });
    }
    if (type == "notes") {
      self._snackBar.open(message, "Notes!", {
        panelClass: "infoNotification",
        duration: 400000,
      });
    }
  }
}
