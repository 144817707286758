import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ReportRoutingModule } from "./report-routing.module";
import { ReportComponent } from "./report.component";
import { ListFreezedRunsComponent } from "./list-freezed-runs/list-freezed-runs.component";
import { MatIconModule } from "@angular/material/icon";
import { ReportsComponent } from "./reports/reports.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; // <-- #1 import module
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { GenerateReportComponent } from "./generate-report/generate-report.component";
import { PlogComponent } from "./plog/plog.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { PlogDispComponent } from "./plog-disp/plog-disp.component";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ConnectLinuxComponent } from './connect-linux/connect-linux.component';
@NgModule({
  declarations: [
    ReportComponent,
    ListFreezedRunsComponent,
    ReportsComponent,
    GenerateReportComponent,
    PlogComponent,
    PlogDispComponent,
    ConnectLinuxComponent,
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatDialogModule,
    CdkVirtualScrollViewport,
    MatListModule,
    MatTooltipModule,
  ],
})
export class ReportModule {}
