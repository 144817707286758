import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { FormsModule } from '@angular/forms';
import { FilterformPipe } from "./filterform.pipe";
import { RunViewRoutingModule } from "./run-view-routing.module";
import { RunViewComponent } from "./run-view/run-view.component";
import { GeneralComponent } from "./run-view/configTabs/general/general.component";
import { ScriptsComponent } from "./run-view/configTabs/scripts/scripts.component";
import { PhasesComponent } from "./run-view/configTabs/phases/phases.component";
import { TracedefComponent } from "./run-view/configTabs/tracedef/tracedef.component";
import { TraceoverrideComponent } from "./run-view/configTabs/traceoverride/traceoverride.component";
import { DatabaseComponent } from "./run-view/configTabs/database/database.component";
import { SnapshotComponent } from "./run-view/configTabs/snapshot/snapshot.component";
import { LoadComponent } from "./run-view/configTabs/load/load.component";
// import { NgJsonEditorModule } from 'ang-jsoneditor';
import { MatIconModule } from "@angular/material/icon";
// import { ReactiveFormsModule } from '@angular/forms';
// import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { ScrollSpyDirective } from "./run-view/scrollspy.directive";
import { InputDispComponent } from "./run-view/configTabs/scripts/input-disp/input-disp.component";
import { InputSelectionComponent } from "./run-view/configTabs/scripts/input-selection/input-selection.component";
import { IrtfDispComponent } from "./run-view/configTabs/scripts/irtf-disp/irtf-disp.component";
import { IrtfSelectionComponent } from "./run-view/configTabs/scripts/irtf-selection/irtf-selection.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatListModule } from "@angular/material/list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
  declarations: [
    FilterformPipe,
    RunViewComponent,
    GeneralComponent,
    ScriptsComponent,
    PhasesComponent,
    TracedefComponent,
    TraceoverrideComponent,
    DatabaseComponent,
    SnapshotComponent,
    LoadComponent,
    ScrollSpyDirective,
    InputDispComponent,
    InputSelectionComponent,
    IrtfDispComponent,
    IrtfSelectionComponent,
  ],
  imports: [
    CommonModule,
    RunViewRoutingModule,
    FormsModule,
    // RxReactiveFormsModule,
    MatTabsModule,
    MatAutocompleteModule,
    // NgJsonEditorModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatDialogModule,
    MatFormFieldModule,
    ScrollingModule,
    MatButtonToggleModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatRadioModule,
    NzSelectModule,
  ],
})
export class RunViewModule {}
