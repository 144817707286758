import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  NgZone,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UntypedFormControl } from "@angular/forms";
import { Observable, Subject, of } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";

import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import * as $ from "jquery";
import * as _ from "underscore";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { ShareDataService } from "src/app/_services/share-data.service";
import { SocketioClientServices } from "../../_services/socketio-client.services";
import { AppComponent } from "../../app.component";
import { MatSnackBar } from "@angular/material/snack-bar";

/** Interface for DropDown (PD-37) */
interface dropDownList {
  scriptName: string;
  parentScript: string;
  subScript: boolean;
}

@Component({
  selector: "app-validate-view",
  templateUrl: "./validate-view.component.html",
  styleUrls: ["./validate-view.component.scss", "./stepper.scss"],
})
export class TSIValidateViewComponent implements OnInit {
  // @Input() execLogCompletion;
  myControl = new UntypedFormControl("");
  options: string[] = ["One", "Two", "Three"];
  filteredOptions: any;
  public execLog;
  // public httpAdapter;
  public scriptStatus = {};
  public summaryReport = [];
  public currentTabIndex = 0;

  public ValidationData = {};
  sharedData: any;
  public currentView = "Execution Log";
  public currentScript;
  @Input() execMessage = [];
  currentScriptIndex: any;
  ValidationDataStatus: any;
  ValidationDataMsg: any;
  summaryReportStatus: any;
  summaryReportReportGenerated: any;
  tabLoadTimes: Date[] = [];
  subscription: any;
  selectedValue: any = {};
  scriptDropdownList = [];
  selectedFilter: any;
  socketSubscriptionForValidateAndSummaryStatus: any; //The variable is to listing to socket event called ValidateAndSummaryStatus.
  getTimeLoaded(index: number) {
    return new Date();
  }
  // public httpAdapter: HttpAdapterService;

  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public router: Router,
    private sharedServices: ShareDataService,
    private socket: SocketioClientServices,
    private route: ActivatedRoute,
    public httpAdapter: HttpAdapterService
  ) {
    // this.httpAdapter = new HttpAdapterService(this.http);
  }

  private _filter(value: string) {
    var filter = this.subScriptData.filter((option) => {
      return (
        option["FileName"].toLowerCase().includes(value.toLowerCase()) ||
        option["subScript"].toLowerCase().includes(value.toLowerCase())
      );
    });
    return filter;
  }
  subScriptData = [];
  getSubScriptData(executionId) {
    this.subScriptData = [];
    const params = { url: "scripts/getSubScriptById?id=" + executionId };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.subScriptData = res;
      this.selectedValue = {};
      this.myControl = new UntypedFormControl("");
      // this.scriptDropdownList = [];
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value || ""))
      );
    });
  }
  executionId;

  getScriptById() {
    var self = this;
    // this.currentScript = undefined;
    const params = { url: "scripts/getScriptById/" + this.executionId };
    this.httpAdapter.httpPostNew(params).subscribe((response) => {
      self.currentScriptIndex = response._id;
      self.currentScript = response;
      self.subscription = self.sharedServices.currentData$.subscribe((data) => {
        self.sharedData = data;
        self.sharedData["currentScript"] = response;
        self.sharedData["currentScriptIndex"] = response._id;
        // self.sharedServices.changeData(self.sharedData);
      });
      // self.sharedData["currentScriptIndex"] = response.body._id;
      // self.sharedData["currentScript"] = response.body;
      self.sharedServices.changeData(self.sharedData);
      // self.subscription.unsubscribe();
    });
  }
  ngOnInit() {
    const self = this;
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.executionId = undefined;
          this.currentTabIndex = 0;
          const executionId = params.get("id");
          return of(executionId);
        })
      )
      .subscribe((executionId) => {
        this.executionId = executionId;
        this.scriptLogData = null;
        this.getLogStatus(executionId);
        this.getSubScriptData(executionId);
        this.getScriptById();
      });
    // const params = { url: "scripts/getScriptById/" + this.executionId, }
    // this.httpAdapter.httpPostNew(params).subscribe(response => {
    //   self.currentScriptIndex = response._id
    //   self.currentScript = response;
    //   self.subscription = self.sharedServices.currentData$.subscribe((data) => {
    //     self.sharedData = data;
    //     self.sharedData["currentScript"] = response;
    //     self.sharedData["currentScriptIndex"] = response._id;
    //     // self.sharedServices.changeData(self.sharedData);
    //   });
    //   // self.sharedData["currentScriptIndex"] = response.body._id;
    //   // self.sharedData["currentScript"] = response.body;
    //   self.sharedServices.changeData(self.sharedData);
    //   self.subscription.unsubscribe();
    // });
    // For Execution completion, Validation status & Updateing the current index and currentScript
    // self.subscription = self.sharedServices.currentData$.subscribe((data) => {
    //   self.sharedData = data;
    //   console.log("self.sharedData --->", self.sharedData);
    //   if (self.currentScript != undefined) {
    //     if (self.sharedData["currentScriptIndex"] != self.currentScriptIndex) {
    //       self.currentScript = self.sharedData["currentScript"];
    //       self.currentScriptIndex = self.sharedData["currentScriptIndex"];
    //       self.currentTabIndex = 0;
    //       self.ValidationData = {};
    //       // self.loadScriptData();
    //     } else {
    //       self.currentScript = self.sharedData["currentScript"];
    //       self.currentScriptIndex = self.sharedData["currentScriptIndex"];
    //     }
    //   } else {
    //     if (self.sharedData["currentScript"]) {
    //       self.currentScript = self.sharedData["currentScript"];
    //       self.currentScriptIndex = self.sharedData["currentScriptIndex"];
    //       self.currentTabIndex = 0;
    //       self.ValidationData = {};
    //       // self.loadScriptData();
    //     }
    //   }
    //   //Additional update condition for  ValidationDataMsg
    //   if (self.sharedData["socket"]["SocketlogInfo"]["data"] != undefined) {
    //     if (
    //       self.currentScriptIndex ==
    //       self.sharedData["socket"]["SocketlogInfo"]["data"]["_id"]
    //     ) {
    //       if (
    //         self.sharedData["socket"]["SocketlogInfo"]["data"]["status"] ==
    //         false
    //       ) {
    //         self.ValidationDataMsg = {
    //           message: "FAILED",
    //           state: "fail",
    //         };
    //         self.ValidationDataStatus = true;
    //       } else if (
    //         self.sharedData["socket"]["SocketlogInfo"]["data"]["status"] == true
    //       ) {
    //         self.ValidationDataMsg = {
    //           message: "SUCCESS",
    //           state: "success",
    //         };
    //         self.ValidationDataStatus = true;
    //       }
    //     }
    //   }
    //   if (self.sharedData["socket"]["SocketCheckInfo"] == true) {
    //     // Once Execution Completed, Below Conditions for re-call the Execution API and re-render the all executed transaction .
    //     if (self.sharedData["socket"]["SocketlogInfo"]["execution"] == true) {
    //       if (
    //         self.currentScriptIndex ==
    //         self.sharedData["socket"]["SocketlogInfo"]["data"]["id"]
    //       ) {
    //         var params = {
    //           url:
    //             "scripts/execution/" +
    //             self.sharedData["socket"]["SocketlogInfo"]["data"]["id"],
    //         };
    //         // self.httpAdapter.httpGet(params, function (Err, result) {
    //         //   if (!Err) {
    //         //     self.execLog = JSON.parse(JSON.stringify(result.body));
    //         //     self.sharedData["socket"]["SocketlogInfo"]["execution"] = false;
    //         //     self.sharedData["socket"]["SocketCheckInfo"] = false;
    //         //     self.sharedServices.changeData(self.sharedData);
    //         //   } else {
    //         //     // console.log(Err)
    //         //     self.sharedData["socket"]["SocketlogInfo"]["execution"] = false;
    //         //     self.sharedData["socket"]["SocketCheckInfo"] = false;
    //         //     self.sharedServices.changeData(self.sharedData);
    //         //   }
    //         // });
    //       }
    //     }
    //     // Once Log status has been changed by another account, Below condition will changes the validate status to us.
    //     else if (
    //       self.sharedData["socket"]["SocketlogInfo"]["logStatus"] == true
    //     ) {
    //       if (
    //         self.currentScriptIndex ==
    //         self.sharedData["socket"]["SocketlogInfo"]["data"]["_id"]
    //       ) {
    //         if (
    //           self.sharedData["socket"]["SocketlogInfo"]["data"]["status"] ==
    //           false
    //         ) {
    //           self.ValidationDataMsg = {
    //             message: "FAILED",
    //             state: "fail",
    //           };
    //           self.ValidationDataStatus = true;
    //         } else if (
    //           self.sharedData["socket"]["SocketlogInfo"]["data"]["status"] ==
    //           true
    //         ) {
    //           self.ValidationDataMsg = {
    //             message: "SUCCESS",
    //             state: "success",
    //           };
    //           self.ValidationDataStatus = true;
    //         }
    //         self.sharedData["socket"]["SocketCheckInfo"] = false;
    //         self.sharedData["socket"]["SocketlogInfo"]["logStatus"] = false;
    //       }

    //       self.sharedData["socket"]["SocketlogInfo"]["logStatus"] = false;
    //       self.sharedData["socket"]["SocketCheckInfo"] = false;
    //       self.sharedServices.changeData(self.sharedData);
    //     }
    //   }

    //   // if (this.currentScriptIndex)
    //   //   self.router.navigate(["scripts/validate/execution/" + this.currentScriptIndex]);

    // });

    // self.sharedData["mode"] = "validate";
    // self.sharedServices.changeData(self.sharedData);

    /**
     * After the backend validation is complete, the function below will re-render the summarystatus && Validationstatus.(PM-71)
     */
    self.socketSubscriptionForValidateAndSummaryStatus = self.socket
      .listeningFunction("validateAndSummaryStatus")
      .subscribe((data) => {
        if (self.executionId == data["scriptId"]) {
          this.getLogStatus(this.executionId);
          this.getSubScriptData(this.executionId);
          this.getScriptById();
          self.ValidationDataMsg =
            data["validationDataStatus"]["validationMsg"];
          self.ValidationDataStatus = data["validationDataStatus"]["validated"];
          self.summaryReportStatus =
            data["summaryReportStatus"]["performanceMsg"];
          self.summaryReportReportGenerated =
            data["summaryReportStatus"]["reportGenerated"];
          new AppComponent(self._snackBar).showSnackBar(
            "Validation & Performance has been Completed",
            "success"
          );
        }
      });
  }

  onSelectionChange(event) {
    console.log("onSelectionChange called", event.option.value);
  }

  dropDownList(event) {
    this.selectedValue = {};
    this.myControl = new UntypedFormControl("");
    this.scriptDropdownList = [];
    // if(event.length > 0){
    //   this.scriptDropdownList.push({ scriptName: 'All',
    //     parentScript: 'All',
    //     subScript: false})
    // }
    for (let i = 0; i < event.length; i++) {
      this.scriptDropdownList.push(event[i]);
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  tabChange(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTabIndex = tabChangeEvent.index;
    // console.log('tabChangeEvent => ', tabChangeEvent);
    //  console.log('index => ', tabChangeEvent.index);
    // if(tabChangeEvent.index === 0){
    //    this.router.navigate(["/login"]);

    // }
    // else if(tabChangeEvent.index === 1){}
    // else if(tabChangeEvent.index === 2){}
  }
  scriptLogData;
  public getLogStatus(id) {
    var self = this;
    self.ValidationDataMsg = null;
    self.ValidationDataStatus = null;
    self.summaryReportStatus = null;
    self.summaryReportReportGenerated = null;
    let params = {
      url: "scripts/getScriptStatus?id=" + id,
    };
    self.httpAdapter.httpGetNew(params).subscribe((result) => {
      if (result) {
        const response: any = result.body;
        this.scriptLogData = response;
        if (response.progress != null) {
          self.ValidationDataStatus = response.validationData?.["validated"];
          self.ValidationDataMsg = response.validationData?.["validationMsg"];
          self.summaryReportReportGenerated =
            response.summaryReport?.["reportGenerated"];
          self.summaryReportStatus = response.summaryReport?.["performanceMsg"];
        }
      }
    });
  }
  public getexecLog(script) {
    var self = this;
    self.ValidationDataMsg = null;
    self.ValidationDataStatus = null;
    self.summaryReportStatus = null;
    self.summaryReportReportGenerated = null;

    var params = {
      url: "scripts/execution/" + script._id,
    };
    self.httpAdapter.httpGet(params, function (Err, result) {
      if (!Err) {
        // console.log("Hey",result)
        self.execLog = JSON.parse(JSON.stringify(result.body));
        // self.currentScript['executionMsg']=self.execLog['executionData']['executionMsg']

        if (
          self.currentScript.progress == "COMPLETED" ||
          self.currentScript.progress == "YET TO BE REVIEWED"
        ) {
          var params = {
            url: "scripts/validationDataStatus/" + script._id,
            data: script,
          };

          self.httpAdapter.httpGet(
            params,
            function (validationRequestErr, Validation_Result) {
              if (!validationRequestErr) {
                // console.log('Validation_Result',Validation_Result)

                self.ValidationDataStatus = Validation_Result.body["validated"];
                self.ValidationDataMsg =
                  Validation_Result.body["validationMsg"];
                var params = {
                  url: "scripts/summaryReportStatus/" + script._id,
                  data: script,
                };
                self.httpAdapter.httpGet(params, function (err, result) {
                  if (err) {
                    // console.log('Error', err)
                  } else {
                    // console.log('result',result)
                    self.summaryReportReportGenerated =
                      result["body"]["reportGenerated"];
                    self.summaryReportStatus = result["body"]["performanceMsg"];
                    // debugger;
                  }
                });
              } else {
                if (validationRequestErr.status == 401) {
                  return self.router.navigate(["/login"]);
                }
              }
            }
          );
        }
      } else {
        if (Err.status == 401) {
          return self.router.navigate(["/login"]);
        }
      }
    });
  }
  trackByFn(index, item) {
    return index; // Assuming your item has a unique 'id' property
  }
  loadScriptData() {
    var self = this;
    $("#horizontal li").removeClass("active");
    $("#horizontal li:first").addClass("active");
    self.currentView = $("#horizontal li:first").text().trim();
    // self.sharedData["execLogInterval"] =null;
    if (
      (!_.isEmpty(self.currentScript) &&
        self.currentScript != undefined &&
        self.currentScript.progress == "COMPLETED") ||
      self.currentScript.progress == "YET TO BE REVIEWED" ||
      self.currentScript.progress == "ABORTED"
    ) {
      self.getexecLog(self.currentScript);
    }
    if (self.currentScript.progress == "PROGRESS") {
      self.getexecLog(self.currentScript);

      // self.sharedData["execLogInterval"] = setInterval(function () {
      //   if (self.execLog['executionData']['executionMsg']["state"] == "success" || self.execLog['executionData']['executionMsg']["state"] == "failed") {
      //     clearInterval(self.sharedData["execLogInterval"])
      //     self.sharedData["execLogInterval"] = null;
      //     self.sharedServices.changeData(self.sharedData)

      //   }
      //   self.getexecLog(self.currentScript);
      // }, 5000)

      self.sharedServices.changeData(self.sharedData);
    }
  }

  public findKeys(obj) {
    if (obj) return Object.keys(obj);
    else return [];
  }

  //Event Emitter listening to the Child <app-mapped-view>
  ValidationStatusHandler(valueEmitted) {
    var self = this;
    self.ValidationDataMsg["state"] = valueEmitted.state;
    self.ValidationDataMsg["message"] = valueEmitted.message;
    self.sharedServices.changeData(self.sharedData);
  }
  changingValue: Subject<boolean> = new Subject();
  filterChanging: Subject<{
    script: Object;
    subScriptStatus: Boolean;
  }> = new Subject();

  refreshLog() {
    this.getSubScriptData(this.executionId);
    this.changingValue.next(true);
  }

  filterMethod(event, subScriptStatus) {
    this.filterChanging.next({ script: event, subScriptStatus });
    // this.selectedFilter = event;
  }
  ngOnDestroy(): void {
    /**
     * unsubscribe Observables that have been subscribed - (PM-147)
     */
    this.subscription ? this.subscription.unsubscribe() : "";
    this.socketSubscriptionForValidateAndSummaryStatus.unsubscribe();
  }
}
