import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: "app-plog-disp",
  templateUrl: "./plog-disp.component.html",
  styleUrls: ["./plog-disp.component.scss"],
})
export class PlogDispComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<String>) {}

  ngOnInit() {}
}
