import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";

// @Injectable({
//   providedIn: 'root'
// })

export class ShareDataService {
  private dataSource = new BehaviorSubject({});
  currentData$ = this.dataSource.asObservable();

  constructor() {}

  changeData(data) {
    this.dataSource.next(data);
  }

  getData(): Observable<any> {
    return this.currentData$;
  }
}
