import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import * as _ from "underscore";
import * as moment from "moment";
import { AppComponent } from "../../app.component";
import { HttpAdapterService } from "../../_services/http-adapter.service";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { saveAs } from "file-saver";
import { ShareDataService } from "../../_services/share-data.service";
import { SocketioClientServices } from "../../_services/socketio-client.services";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { DialogueComponent } from "../../errorPages/dialogue/dialogue.component";
// import { MatDialog } from "@angular/material/dialog";
import { filter } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { UserService } from "../../_services/user.service";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: "app-tsi-scripts",
  templateUrl: "./scripts.component.html",
  styleUrls: [
    "./scripts.component.scss",
    "./scss/scripts.sortMenu.scss",
    "./scss/scripts.scriptSearch.scss",
  ],
  // encapsulation: ViewEncapsulation.None
})
export class ScriptsComponent implements OnInit, OnDestroy {
  public currentCardDetails: any;
  public scripts = [];
  public sharedData = {};
  // public httpAdapter: HttpAdapterService;
  public currentScript = {};
  public groupedScripts = [];
  public currentScriptIndex = "";
  public selectedScripts = [];
  public search = false;
  public searchScriptFilter = "";
  public searchConfigFilter = "";
  public isShowFreezeView = false;
  public freezeId;
  public minimize = false;
  public selectall = false;
  public isSortShow = false;
  public addScriptDetailsWindow = false;
  public progressFilteredItems = {};
  public statusFilteredItems = {};
  public scriptDeatils = {
    scriptName: "",
  };
  @ViewChildren(CdkVirtualScrollViewport)
  virtualScroll: QueryList<CdkVirtualScrollViewport>;

  public currentRunScript = {};
  public filters = [false, false, false, false, false, false];
  public deletingScript: String;
  public deletingScriptId: String;
  execMessage = [];
  listScripts = [];
  execLogCompletion = [];
  removedIndexNext: any = null;
  deletespinner = false;
  runValidation: boolean = false;
  ValidationData: any;
  execLog: any;
  selectedDate: any;
  maxdate = new Date();
  subscription: any;
  isFilter = false;
  month = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  addedNewScript = null;
  @ViewChild("searchField") inputSearchElement: ElementRef;
  @ViewChild("configSearchField") scriptSearchElement: ElementRef;
  selectedSystem: string = "";
  routeSubscription: any;
  trackByFn(index, item) {
    return index; // Assuming your item has a unique 'id' property
  }

  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public router: Router,
    private sharedServices: ShareDataService,
    public dialog: MatDialog,
    public socket: SocketioClientServices,
    private ref: ChangeDetectorRef,
    public httpAdapter: HttpAdapterService,
    private route: ActivatedRoute,
    private titleService: Title,
    private userService: UserService
  ) {
    this.selectedScripts = [];
    // this.sharedService = new ShareDataService();
    // this.httpAdapter = new HttpAdapterService(this.http);
    this.freezeId = "";
    this.currentScript = {};
    this.currentScriptIndex = "";
    this.groupedScripts = [];
  }

  changeSearchData(ele) {
    this.searchScriptFilter = ele;
    this.offset = 0;
    this.getScripts();
  }

  messages = [];
  socketSubscriptionForLogInfo: any; //The variable is to listing to socket event called LogInfo.
  socketSubscriptionForLogStatus: any; //The variable is to listing to socket event called LogStatus.
  socketSubscriptionForRunProgressInfo: any; //The variable is to listing to socket event called RunProgressInfo.
  socketSubscriptionForAddconfig: any; //The variable is to listing to socket event called Addconfig.
  socketSubscriptionForDeleteConfig: any; //The variable is to listing to socket event called DeleteConfig.
  socketSubscriptionForDeleteLogs: any; //The variable is to listing to socket event called DeleteLogs.

  assignLiveTab() {
    const currentRouteUrl = this.router.url;
    const routeName = this.extractRouteName(currentRouteUrl);
    console.log("My ROuteName", routeName, this.sharedData["view"], currentRouteUrl);
    this.sharedData["view"] = "Live";
    this.sharedData["freezeId"] = "live";

    routeName === "runScripts"
      ? (this.runValidation = false)
      : (this.runValidation = true);

    // this.getScriptRunList();
    this.offset = 0;
    // this.currentScriptIndex = "";
    this.getScripts();
  }

  sharedServicesFunction() {
    var self = this;
    self.subscription = self.sharedServices.currentData$.subscribe((data) => {
      self.sharedData = data;
      if (
        self.sharedData["selectedSystem"] &&
        self.selectedSystem != self.sharedData["selectedSystem"]
      ) {
        self.selectedSystem = self.sharedData["selectedSystem"];
        this.assignLiveTab();
      }
      if (
        self.sharedData["socket"] &&
        self.sharedData["socket"]["SocketCheckInfo"] == true
      ) {
        if (
          self.sharedData["socket"]["SocketlogInfo"]["moveToGivenRunScript"] !=
          undefined
        ) {
          self.sharedData["socket"]["SocketCheckInfo"] = false;
          var moveGivenRunScript =
            self.sharedData["socket"]["SocketlogInfo"]["moveToGivenRunScript"];
          delete self.sharedData["socket"]["SocketlogInfo"][
            "moveToGivenRunScript"
          ];
          self.loadRunScript(moveGivenRunScript);
        }
      }

      if (self.sharedData["listRunScripts"])
        self.listScripts = [...self.sharedData["listRunScripts"]];
      // if (self.sharedData["updateRunList"] == true) {
      //   self.getScriptRunList();
      // self.getScripts();
      //   self.sharedData["updateRunList"] = false;
      // }
      //Checking whether the correct index is present in the available config or not. If it's not switching to the available index, (PM-131)
      var indexPresent = false;

      // if (
      //   self.sharedData["listRunScripts"] != undefined &&
      //   self.sharedData["listRunScripts"].length > 0
      // ) {
      //   for (let i = 0; i < self.sharedData["listRunScripts"].length; i++) {
      //     if (
      //       self.sharedData["listRunScripts"][i]["_id"] ==
      //       self.currentRunScript["_id"]
      //     ) {
      //       indexPresent = true;
      //     }
      //   }
      //   if (!indexPresent) {
      //     self.currentRunScript = self.sharedData["listRunScripts"][0];
      //   }
      // }
    });
    // self.getScriptRunList();
    // self.getScripts();
  }
  private extractRouteName(url: string): string {
    const segments = url.split("/");
    return segments[segments.length - 1];
  }

  getNetworkLogData(script) {
    window.open(
      `/#/${this.selectedSystem}/networkLog/${script.name}/${script._id
      }?total=${script.percentage.PHASE1Total | script.percentage.PHASE2Total}`,
      "_blank"
    );
  }

  getTraceLogData(script) {
    window.open(
      `/#/${this.selectedSystem}/traceData?scriptName=${script.name}&scriptId=${script._id
      }&transId=&total=${script.percentage.PHASE1Total | script.percentage.PHASE2Total
      }`,
      "_blank"
    );
  }

  ngOnInit() {
    const userDetails = this.userService.getPayload();
    console.log("user Detals on Script Component ", userDetails);
    //TODO: Token to send isGroup and isDev
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Get the current route URL
        const currentRouteUrl = this.router.url;
        const routeName = this.extractRouteName(currentRouteUrl);
        if (routeName === "runScripts") {
          this.runValidation = false;
        } else {
          this.runValidation = true;
        }
      });
    const currentRouteUrl = this.router.url;
    const routeName = this.extractRouteName(currentRouteUrl);
    if (routeName === "runScripts") {
      this.runValidation = false;
    } else {
      this.runValidation = true;
      this.currentScriptIndex =
        this.router.url.split("/")[this.router.url.split("/").length - 1];
    }
    var self = this;

    this.sharedServicesFunction();
    this.routerListerner();

    self.progressFilteredItems["completed"] = {
      value: false,
      conditionKey: "progress",
      conditionValue: "COMPLETED",
    };
    self.progressFilteredItems["inProgress"] = {
      value: false,
      conditionKey: "progress",
      conditionValue: "PROGRESS",
    };
    self.progressFilteredItems["yetToBeReviewed"] = {
      value: false,
      conditionKey: "progress",
      conditionValue: "YET TO BE REVIEWED",
    };
    self.progressFilteredItems["aborted"] = {
      value: false,
      conditionKey: "progress",
      conditionValue: "ABORTED",
    };
    self.statusFilteredItems["pass"] = {
      value: false,
      conditionKey: "status",
      conditionValue: true,
    };
    self.statusFilteredItems["fail"] = {
      value: false,
      conditionKey: "status",
      conditionValue: false,
    };


  }

  routerListerner() {
    const self = this;
    self.routeSubscription = self.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const urlSegements = this.router.url.split("/");
        // const routeName = this.extractRouteName(currentRouteUrl);
        // const currentSystem = this.router.url.split("/")[1];
        console.log("Current SyStem # ", urlSegements[3]);
        // if(urlSegements[1]=='runScripts')this.runValidation = false
        // else if()
        urlSegements[3] == "regressionLogs"
          ? (this.runValidation = false)
          : (this.runValidation = true);
      });
  }

  moveFocus() {
    const self = this;
    self.search = !self.search;
    //Setting the Focus to the Input Search Field
    if (self.search) {
      setTimeout(() => {
        self.inputSearchElement.nativeElement.focus();
      }, 0);
    }
  }
  configSearch = false;
  moveConfigFocus() {
    const self = this;
    self.configSearch = !self.configSearch;
    //Setting the Focus to the Input Search Field
    if (self.configSearch) {
      setTimeout(() => {
        self.scriptSearchElement.nativeElement.focus();
      }, 0);
    }
  }
  trackItem(index, item) {
    return item._id;
  }

  socketFunction() {
    var self = this;
    /**
     * SocketImplementation
     * The socket events are listed below. (PM-71)
     */

    /**
     *  The function below is used to update the loginfo when any other users initiate the executions.
     *  when the signal is coming from hte server, the below function will check for the script id's availability
     *      - If the script is avilable it will update the script with the latest data.
     *      - else the new index will be created in the client and the tost alert will be displayed saying "Execution Process started, for LOG @fileName"
     */
    self.socketSubscriptionForLogInfo = self.socket
      .listeningFunction("logInfo")
      .subscribe((data) => {
        if (self.selectedSystem === data["system"]) {
          const scriptData = data["script"];
          if (
            !this.scripts.some(
              (item) =>
                item.date ==
                moment(scriptData.startTime).format("YYYY-MM-DD") &&
                item.data.some(
                  (scriptInfo) => scriptInfo["_id"] == scriptData["_id"]
                )
            )
          ) {
            new AppComponent(self._snackBar).showSnackBar(
              "Execution Process Started, For LOG " +
              scriptData["name"].toUpperCase() +
              " " +
              scriptData["progress"],
              "success"
            );
            if (!this.userSearch) {
              if (
                this.scripts.some(
                  (item) =>
                    item.date ==
                    moment(scriptData.startTime).format("YYYY-MM-DD")
                )
              ) {
                const position = this.scripts.findIndex(
                  (obj) =>
                    obj.date ==
                    moment(scriptData.startTime).format("YYYY-MM-DD")
                );
                self.scripts[position].data.unshift(scriptData);
                self.offset += 1;
              } else {
                self.scripts.unshift({
                  date: moment(scriptData.startTime).format("YYYY-MM-DD"),
                  data: [scriptData],
                });
                self.offset += 1;
                if (!this.currentScriptIndex)
                  this.currentScriptIndex = scriptData["_id"];
              }
            }
          } else {
            const position = this.scripts.findIndex(
              (obj) =>
                obj.date == moment(scriptData.startTime).format("YYYY-MM-DD")
            );
            const scriptPosition = this.scripts[position].data.findIndex(
              (obj) => obj._id == scriptData["_id"]
            );
            self.scripts[position].data[scriptPosition]["progress"] =
              scriptData["progress"];
            self.scripts[position].data[scriptPosition]["progressMsg"] =
              scriptData["progressMsg"];
            self.scripts[position].data[scriptPosition]["endTime"] =
              scriptData["endTime"];
            self.scripts[position].data[scriptPosition]["status"] =
              scriptData["status"];
            self.scripts[position].data[scriptPosition].timeoutPH1Happened =
              scriptData.timeoutPH1Happened;
            self.scripts[position].data[scriptPosition].timeoutPH2Happened =
              scriptData.timeoutPH2Happened;
            self.scripts[position].data[scriptPosition].apiErrorPH1Happened =
              scriptData.apiErrorPH1Happened;
            self.scripts[position].data[scriptPosition].apiErrorPH2Happened =
              scriptData.apiErrorPH2Happened;
          }
        }
      });
    /**
     * Below function is for handling the log status updation from different user.
     * When other users are update the status of the log toast alert will be displyed in the client.
     */
    self.socketSubscriptionForLogStatus = self.socket
      .listeningFunction("logStatus")
      .subscribe((data) => {
        if (
          self.scripts.some((scriptInfo) =>
            scriptInfo.data.some((item) => item["_id"] == data["_id"])
          )
        ) {
          const position = this.scripts.findIndex((obj) =>
            obj.data.some((item) => item["_id"] == data["_id"])
          );
          const scriptPosition = this.scripts[position].data.findIndex(
            (obj) => obj["_id"] == data["_id"]
          );
          const dataObj = this.scripts[position].data[scriptPosition];

          // if (data["_id"] != this.currentScriptIndex)
          new AppComponent(self._snackBar).showSnackBar(
            "Log Status have been updated, For " + dataObj["name"],
            "success"
          );
        }
      });

    /**
     * The code below is used to update the percentage of the execution process.
     * The user began running the configuration; the function below is used to display the percentage of the execution process.
     */

    self.socketSubscriptionForRunProgressInfo = self.socket
      .listeningFunction("runProgressInfo")
      .subscribe((data: any) => {
        if (
          self.scripts.some((scriptInfo) =>
            scriptInfo.data.some((item) => item["_id"] == data["lastRunId"])
          )
        ) {
          const position = this.scripts.findIndex((obj) =>
            obj.data.some((item) => item["_id"] == data["lastRunId"])
          );
          const scriptPosition = this.scripts[position].data.findIndex(
            (obj) => obj["_id"] == data["lastRunId"]
          );
          this.scripts[position].data[scriptPosition] = {
            ...this.scripts[position].data[scriptPosition],
            percentage: data.percentage,
            timeoutPH1Happened: data.timeoutPH1Happened,
            timeoutPH2Happened: data.timeoutPH2Happened,
            apiErrorPH1Happened: data.apiErrorPH1Happened,
            apiErrorPH2Happened: data.apiErrorPH2Happened,
          };
        }

        if (
          self.sharedData["listRunScripts"].some(
            (scriptInfo) => scriptInfo["_id"] == data["_id"]
          )
        ) {
          const i = self.sharedData["listRunScripts"].findIndex(
            (obj) => obj._id == data["_id"]
          );
          self.sharedData["listRunScripts"][i]["percentage"] =
            data["percentage"];
          self.sharedData["listRunScripts"][i]["scriptRunStatus"] =
            data["scriptRunStatus"];
          self.sharedData["listRunScripts"][i]["startTime"] = data["startTime"];
          self.sharedData["listRunScripts"][i]["endTime"] = data["endTime"];
          self.sharedData["listRunScripts"][i]["progressMsg"] =
            data["progressMsg"];
          if (
            self.sharedData["currentRunScript"]["_id"] ===
            self.sharedData["listRunScripts"][i]["_id"]
          ) {
            self.sharedData["currentRunScript"]["scriptRunStatus"] =
              data["scriptRunStatus"];
          } else {
            self.sharedData["listRunScripts"][i]["scriptRunStatus"] =
              data["scriptRunStatus"];
          }
          self.sharedServices.changeData(self.sharedData);
        }
      });

    /**
     * The function below is used to add a new configuration to the run view.
     * If the same account is used in multiple browsers, the following function is used to update it.
     * */
    self.socketSubscriptionForAddconfig = self.socket
      .listeningFunction("Addconfig")
      .subscribe((data) => {
        var NewScript = true;
        //Checking the configList, If not exists need to add
        for (let i = 0; i < self.sharedData["listRunScripts"].length; i++) {
          if (
            self.sharedData["listRunScripts"][i]["scriptName"] ==
            data["scriptName"]
          ) {
            NewScript = false;
            break;
          }
        }
        if (NewScript) {
          new AppComponent(self._snackBar).showSnackBar(
            "New Config Added",
            "success"
          );
          self.sharedData["listRunScripts"].splice(0, 0, data);
          // To identification, whether new config is added or not. - (PM-112)
          self.sharedData["socket"] = { ...self.sharedData["socket"], NewLogAdded: true }

        }

        self.sharedServices.changeData(self.sharedData);
      });

    /**
     * The following function is used to remove configuration from the run view.
     * */
    self.socketSubscriptionForDeleteConfig = self.socket
      .listeningFunction("deleteConfig")
      .subscribe((data) => {
        if (data["deleteStatus"] == true) {
          // The same account loged by multiple user, If one user trying to delete the config. it will removed to all.
          self.sharedData["listRunScripts"] = self.sharedData[
            "listRunScripts"
          ].filter((value) => {
            if (value["_id"] != data["_id"]) {
              return value;
            }
          });

          self.listScripts = self.listScripts.filter((value) => {
            if (value["_id"] != data["_id"]) {
              return value;
            }
            if (value["_id"] == data["_id"]) {
              new AppComponent(self._snackBar).showSnackBar(
                "Config deleted",
                "success"
              );
            }
          });
        }
      });

    /**
     * To remove log from the validation view, use the function below.
     * */
    self.socketSubscriptionForDeleteLogs = self.socket
      .listeningFunction("deleteLogs")
      .subscribe((data) => {
        if (data["objectId"] && self.selectedSystem == data["system"]) {
          setTimeout(() => {
            new AppComponent(self._snackBar).showSnackBar(
              "Log has been Deleted for " + data["name"],
              "success"
            );
          }, 1000);
          if (this.currentScriptIndex == data["objectId"]) {
            self.currentScriptIndex = null;
            this.currentScript = {};
            self.offset = 0;
            self.getScripts();
            self.scrollHappened = false;
          } else {
            const position = this.scripts.findIndex((obj) =>
              obj.data.some((item) => item["_id"] == data["objectId"])
            );
            self.scripts[position].data = self.scripts[position].data.filter(
              (item) => item._id != data["objectId"]
            );
          }
          self.deletespinner = false;
        }
      });

    /** Socket listening End */
  }

  public deletingSelectedScript(scriptNameToDeleted: any) {
    var self = this;
    self.deletingScriptId = null;
    self.deletingScript = scriptNameToDeleted["name"];
    self.deletingScriptId = scriptNameToDeleted["_id"];
  }

  deleteError: string;

  public deleteScript() {
    var self = this;
    var deletingId = self.deletingScriptId;
    self.deletespinner = true;
    var params = {
      url: "scripts/deleteScripts",
      data: { objectId: self.deletingScriptId, requested: "log" },
    };
    const nextScriptIndex = this.scripts.findIndex(
      (obj) => obj._id === deletingId
    );

    self.httpAdapter.httpPost(params, function (err, res) {
      if (err) {
        console.log("error running script===>", err);
        self.deletespinner = false;
      } else {
        if (res.body.status) {
          document.getElementById("exampleModal").click();
          new AppComponent(self._snackBar).showSnackBar(
            self.deletingScript.toUpperCase() + " Log has been Deleted",
            "success"
          );
          self.socket.emitingFunction("deleteLogs", {
            objectId: deletingId,
            name: self.deletingScript.toUpperCase(),
            system: self.selectedSystem,
          });

          if (self.currentScriptIndex == deletingId) {
            self.currentScriptIndex = null;
            self.currentScript = {};
            self.offset = 0;
            self.getScripts();
            self.scrollHappened = false;
          } else {
            const position = self.scripts.findIndex((obj) =>
              obj.data.some((item) => item["_id"] == deletingId)
            );
            self.scripts[position].data = self.scripts[position].data.filter(
              (item) => item._id != deletingId
            );
          }
          self.deletespinner = false;
        } else {
          /* Once the back - end has been thrown status as false,
           the blow code will be triggered and display the error message. (PD-11)*/
          self.deleteError = res.body.message;
          setTimeout(() => {
            self.deletespinner = false;
            document.getElementById("exampleModal").click();
            self.deleteError = null;
          }, 3000);
        }
      }
    });
  }

  public getiRTFLog(script) {
    var self = this;
    var params = {
      url: "irtfFile/getLog",
      data: { objectId: script._id },
    };
    console.log(params);
    self.httpAdapter.httpPost(params, function (err, res) {
      //const scriptName = res.body.scriptName;
      // const pdfData = res.body.data;
      const docDefinition = res.body.docDefinition;
      pdfMake.createPdf(docDefinition).open();
    });
  }

  public onDemandValidation(script) {
    const self = this;
    const params = {
      url: "validate/onDemandValidation",
      data: {
        scriptId: script._id,
      },
    };

    self.httpAdapter.httpPost(params, function (err, data) {
      if (err) {
        console.log(err);
        window.alert("Internal Error - Validation");
      } else {
        console.log(data);
        // alert(data.body.msg);
      }
    });
  }

  // Below function is to fitering the log data - (PD-7)
  dateFilterForLog(event: any) {
    this.selectedDate = event.value;
    this.isFilter = true;
    clearInterval(this.sharedData["execLogInterval"]);
    this.getScripts();
  }

  scrollHappened = false;
  public getScripts() {
    var self = this;
    if (
      self.sharedData["view"] == "Report" &&
      self.sharedData["freezeId"] == undefined
    ) {
      self.backToFreezeIdList();
    }
    var params = {
      system: this.selectedSystem,
      url: `scripts/findAllLogsByTSI?limit=${this.limit}&offset=${this.offset}&searchText=${this.searchScriptFilter}`,
      data: {
        // If the data is availabe in selectedDate, It will shared to backend else null. (PD-7)
        requestDate: this.selectedDate,
        filterArray: this.filterArray,
        passFailData: this.passFailData,
        currentUser: this.userSearch,
      },
    };
    this.httpAdapter.httpPostNew(params).subscribe((result) => {
      if (result) {
        if (this.offset === 0) {
          const response: any[] = Array(result);
          this.offset += response[0].reduce((a, b) => b.data.length + a, 0);
          self.scripts = result;
          if (response[0].reduce((a, b) => b.data.length + a, 0) < this.limit)
            this.endReached = true;
          else this.endReached = false;
        } else {
          const response: any[] = Array(result);
          this.offset += response[0].reduce((a, b) => b.data.length + a, 0);
          self.scripts = [...self.scripts, ...result];
          const mergedScriptsMap = new Map();
          self.scripts.forEach((script) => {
            if (mergedScriptsMap.has(script.date)) {
              mergedScriptsMap.get(script.date).data.push(...script.data);
            } else {
              mergedScriptsMap.set(script.date, script);
            }
          });
          self.scripts = Array.from(mergedScriptsMap.values());
          self.scripts = self.scripts.sort((a, b) => {
            let initialDate = new Date(a.date).getTime();
            let compareDate = new Date(b.date).getTime();
            return compareDate - initialDate;
          });

          if (response[0].reduce((a, b) => b.data.length + a, 0) < this.limit)
            this.endReached = true;
          else this.endReached = false;
        }


        if ((!this.currentScriptIndex || this.currentScriptIndex == "regressionLogs") && this.scripts.length) {
          this.currentScriptIndex = this.scripts[0].data[0]._id;
          this.currentScript = this.scripts[0].data[0];
          let existingTitle = this.titleService.getTitle().split("-")[0];
          this.router.navigateByUrl(
            "/" + this.selectedSystem + "/regressionLogs/validate/" + this.currentScriptIndex
          );
          this.titleService.setTitle(
            this.currentScript
              ? existingTitle + "-" + this.currentScript["name"]
              : existingTitle
          );
        }

        if (this.currentScript && !Object.keys(this.currentScript).length) {
          if (self.scripts.some((obj) =>
            obj.data.some((item) => item["_id"] == this.currentScriptIndex)
          )) {
            const position = self.scripts.findIndex((obj) =>
              obj.data.some((item) => item["_id"] == this.currentScriptIndex)
            );
            let RequireScript = self.scripts[position].data.find(
              (item) => item._id == this.currentScriptIndex
            );
            if (RequireScript && Object.keys(RequireScript).length) {
              this.currentScript = RequireScript;
              let existingTitle = this.titleService.getTitle().split("-")[0];
              this.titleService.setTitle(
                this.currentScript
                  ? existingTitle + "-" + this.currentScript["name"]
                  : existingTitle
              );
              this.ref.detectChanges();
            }
          } else {
            if (!this.endReached) this.getScripts();
          }
        }

        if (
          this.currentScriptIndex && this.currentScript &&
          Object.keys(this.currentScript).length &&
          !this.scrollHappened
        ) {
          const requireScript = document
            .getElementById(this.currentScriptIndex)
            .getBoundingClientRect();
          document
            .getElementById("scriptDataDisplayContainer")
            .scrollTo({ top: requireScript.top - 200 });
          this.scrollHappened = true;
        }
      } else {
        if (result.status == 401) {
          return self.router.navigate(["/login"]);
        }
      }
    });
  }

  /**
   * Returns the length of the Script/s on Date Basis
   * @param date
   * @returns
   */
  getScriptLength(date) {
    return this.scripts.filter(
      (script) =>
        moment(date).format("DD MMM YYYY") ==
        moment(script.startTime).format("DD MMM YYYY")
    ).length;
  }

  endReached = false;
  limit = 30;
  offset = 0;

  loadMoreScriptsData(index, dateIndex) {
    if (
      // (this.offset != 0 && index === this.scripts.reduce((a, b) => b.data.length + a, 0) - 3) ||
      dateIndex === this.scripts.length - 1 &&
      (index === this.scripts[this.scripts.length - 1].data.length ||
        index + 1 > this.scripts[this.scripts.length - 1].data.length)
    ) {
      this.getScripts();
    }

    // if (this.currentScript.progress !== "PROGRESS" && last && !this.endReached) {
    // this.getExecutionData(this.limit, this.offset + this.limit)
    // this.offset += this.limit
    // }
  }
  public backToFreezeIdList() {
    var self = this;
    self.router.navigate(["/report"]);
  }
  public scriptUser(scriptuser) {
    console.log("scriptuser", scriptuser);
    return scriptuser.padEnd(8).toUpperCase();
  }

  public findKeys(obj) {
    return Object.keys(obj);
  }

  public formatDate(date) {
    return moment(date, "YYYY-MM-DD").calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastDay: "[Yesterday]",
      lastWeek: "DD MMM YYYY",
      sameElse: "DD MMM YYYY",
    });
  }

  public loadScriptDetails(script) {
    // window.alert("New Script " + script.name + " is being loaded now!!!!!")

    var self = this;
    // self.dialog.open(DialogueComponent, {
    //   direction: "ltr",
    //   minWidth: "350px",
    //   position: { top: "10px" },
    //   panelClass: "my-alert-message-class",
    //   data: {
    //     Title: "Message",
    //     Content: "New Script " + script.name + " is being loaded now!!!!!",
    //   },
    // });
    self.currentScriptIndex = script._id;
    self.currentScript = script;
    self.sharedData["currentScript"] = self.currentScript;
    self.sharedData["currentScriptIndex"] = self.currentScriptIndex;

    self.sharedServices.changeData(self.sharedData);
    this.router.navigateByUrl(
      "/" + this.selectedSystem + "/scripts/validate/" + script._id
    );
    // event.preventDefault();
  }

  public closeSearch() {
    this.search = false;
    if (this.searchScriptFilter) this.offset = 0;

    this.searchScriptFilter = "";
    this.getScripts();
  }
  public closeConfigSearch() {
    this.configSearch = false;
    this.searchConfigFilter = "";
  }
  public selectAllScripts() {
    var self = this;
    self.scripts.forEach(function (ele) {
      ele["selected"] = self.selectall;
      self.saveCheckedScript(ele);
    });
    // self.groupingScripts(self.scripts);

    /////temp solution////////////
  }

  // public groupingScripts(scripts) {
  //   var self = this;

  //   var groupedArray = _.groupBy(scripts, function (a) {
  //     return a["scriptStartDateModified"];
  //   });
  //   var temp = _.pairs(groupedArray);
  //   temp.sort(function (a, b) {
  //     return +new Date(b[0]) - +new Date(a[0]);
  //   });
  //   self.groupedScripts = JSON.parse(
  //     JSON.stringify(
  //       temp.map(function (ele, index) {
  //         return {
  //           Date: ele[0],
  //           scriptData: ele[1].sort(function (a, b) {
  //             return +new Date(b["startTime"]) - +new Date(a["startTime"]);
  //           }),
  //         };
  //       })
  //     )
  //   );
  //   console.log(this.groupedScripts);

  //   if (_.isEmpty(self.currentScript) || self.currentScript == undefined) {
  //     if (self.groupedScripts.length != 0) {
  //       self.currentScript =
  //         self.groupedScripts[_.keys(self.groupedScripts)[0][0]][
  //         "scriptData"
  //         ][0];
  //       self.currentScriptIndex =
  //         self.groupedScripts[_.keys(self.groupedScripts)[0][0]][
  //           "scriptData"
  //         ][0]._id;
  //     }
  //     //Once user selecting fileter, Below function is to display the snackBar - (PD-7)
  //     if (self.isFilter) {
  //       var message = `Logs are Fetched from -
  //       ${self.month[self.selectedDate.getMonth("MM")]
  //         } ${self.selectedDate.getDate()}`;
  //       new AppComponent(self._snackBar).showSnackBar(message, "success");
  //       self.isFilter = false;
  //     }
  //     self.sharedData["currentScript"] = self.currentScript;
  //     self.sharedData["currentScriptIndex"] = self.currentScriptIndex;
  //     self.sharedServices.changeData(self.sharedData);
  //   } else {
  //     //Condition for moving the deleted currentIndex to next - (PD-11)
  //     if (self.removedIndexNext != null) {
  //       self.currentScriptIndex = self.removedIndexNext;
  //       self.removedIndexNext = null;
  //     }
  //     //Once user selecting fileter, Below function is to display the snackBar - (PD-7)
  //     if (self.isFilter) {
  //       // Below message variabe id to concate the string for snackBar message. - (PD-7)
  //       var message = `Logs are Fetched from -
  //       ${self.month[self.selectedDate.getMonth("MM")]
  //         } ${self.selectedDate.getDate()}`;
  //       new AppComponent(self._snackBar).showSnackBar(message, "success");
  //       self.isFilter = false;
  //       var changeIndex = false;
  //       //If current index is not availabe in the filted list means, Below condition will be applicable. (PD-7)
  //       for (let i = 0; i < scripts.length; i++) {
  //         if (scripts[i]["_id"] == self.currentScriptIndex) {
  //           changeIndex = true;
  //         }
  //       }
  //       // The current index is not availabe in the filtered list, The below condition will execute and the current index move to availabe list last index - (PD-7)
  //       if (!changeIndex) {
  //         var groupCount = self.groupedScripts.length - 1;
  //         var indexCount =
  //           self.groupedScripts[groupCount]["scriptData"].length - 1;
  //         self.currentScriptIndex =
  //           self.groupedScripts[groupCount]["scriptData"][indexCount]._id;
  //       }
  //     }
  //     // self.currentScript = self.scripts.filter((a) => {
  //     //   return a._id === self.currentScriptIndex;
  //     // })[0];
  //     // self.sharedData["currentScript"] = self.currentScript;
  //     // self.sharedData["currentScriptIndex"] = self.currentScriptIndex;
  //     // self.sharedServices.changeData(self.sharedData);
  //   }
  // }

  public saveCheckedScript(script) {
    var self = this;
    if (script.selected) {
      self.selectedScripts.push(script);
    } else {
      self.selectall = false;
      self.selectedScripts.splice(self.selectedScripts.indexOf(script), 1);
    }
  }

  public freezeScripts() {
    var self = this;
    this.isShowFreezeView = !this.isShowFreezeView;
    if (self.groupedScripts.length == 1) {
      self.freezeId = moment(self.groupedScripts[0]["Date"]).format("LL");
    } else if (self.groupedScripts.length > 0) {
      self.freezeId =
        moment(
          self.groupedScripts[self.groupedScripts.length - 1]["Date"]
        ).format("LL") +
        " - " +
        moment(self.groupedScripts[0]["Date"]).format("LL");
    }
  }

  public cancelFreeze() {
    this.isShowFreezeView = !this.isShowFreezeView;
  }

  public statusCheck() {
    var self = this;
    if (self.scripts.length > 0) {
      var filteredScript = self.scripts.filter(function (ele) {
        if (ele["progress"] === "PROGRESS") {
          return ele;
        }
      });
      if (filteredScript.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public submitFreeze() {
    var self = this;
    var params = {
      url: "scripts/freeze/" + self.freezeId,
    };

    self.httpAdapter.httpPut(params, function (freezeResult) {
      if (freezeResult.status == 200) {
        self.scripts = [];
        self.groupedScripts = [];
        self.currentScript = {};
        self.isShowFreezeView = !self.isShowFreezeView;
        self.sharedData["currentScript"] = undefined;
        self.sharedServices.changeData(self.sharedData);
      } else {
        console.log("Error in freezing scripts");
      }
    });
  }

  public createReport() {
    var self = this;
    var ids = _.pluck(self.selectedScripts, "_id");
    var params = {
      url: "reports/generatereport/" + self.sharedData["freezeId"],
      data: {
        script_ids: ids,
      },
    };

    self.httpAdapter.httpPostFileGet(params, function (err, reportResult) {
      if (err) {
        console.log(err);
      } else {
        const blob = new Blob([reportResult], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, self.sharedData["freezeId"]);
      }
    });
  }
  userSearch = false;
  downloadReports(script, subUrl) {
    const self = this;
    const params = {
      url: `reports/${subUrl}`,
      data: {
        ids: [script._id],
        details: script.progressMsg,
      },
    };
    self.httpAdapter.httpPostFileGet(params, async function (err, result) {
      if (!err) {
        const today = new Date();
        const blob = new Blob([result], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${subUrl.toUpperCase() +
          "__" +
          today.getDate().toString() +
          "--" +
          today.toLocaleString("default", { month: "long" }) +
          "--" +
          today.getFullYear().toString() +
          "--" +
          today.getHours().toString() +
          "--" +
          today.getMinutes().toString()
          }.xlsx`;
        anchor.click();
      } else {
        console.log("err", err);
      }
    });
  }

  public showSortingOptions() {
    var self = this;
    self.isSortShow = true;
  }
  filterArray = [];
  passFailData = [];
  public submitFilters() {
    var self = this;
    this.filterArray = [];
    this.passFailData = [];

    self.progressFilteredItems["completed"].value = self.filters[0];
    self.progressFilteredItems["inProgress"].value = self.filters[1];
    self.progressFilteredItems["yetToBeReviewed"].value = self.filters[2];
    self.progressFilteredItems["aborted"].value = self.filters[3];
    self.statusFilteredItems["pass"].value = self.filters[4];
    self.statusFilteredItems["fail"].value = self.filters[5];

    if (self.progressFilteredItems["completed"].value) {
      this.filterArray.push("COMPLETED");
    } else {
      this.filterArray = this.filterArray.filter((ele) => ele != "COMPLETED");
    }
    if (self.progressFilteredItems["inProgress"].value) {
      this.filterArray.push("PROGRESS");
    } else {
      this.filterArray = this.filterArray.filter((ele) => ele != "PROGRESS");
    }
    if (self.progressFilteredItems["aborted"].value) {
      this.filterArray.push("ABORTED");
    } else {
      this.filterArray = this.filterArray.filter((ele) => ele != "ABORTED");
    }

    // if (self.progressFilteredItems["yetToBeReviewed"].value) {
    //   this.passFailData[0] = null;
    // }
    // else {
    //   // this.passFailData[0] = "";
    //   this.passFailData.splice(0, 1)
    // }

    if (self.progressFilteredItems["yetToBeReviewed"].value === true) {
      if (!this.passFailData.some((ele) => ele === null)) {
        this.passFailData.push(null);
      }
    } else {
      this.passFailData = this.passFailData.filter((ele) => ele !== null);
    }

    if (self.statusFilteredItems["pass"].value === true) {
      if (!this.passFailData.some((ele) => ele === true)) {
        this.passFailData.push(true);
      }
    } else {
      this.passFailData = this.passFailData.filter((ele) => ele !== true);
    }

    if (self.statusFilteredItems["fail"].value === true) {
      if (!this.passFailData.some((ele) => ele === false)) {
        this.passFailData.push(false);
      }
    } else {
      this.passFailData = this.passFailData.filter((ele) => ele !== false);
    }

    this.offset = 0;
    this.getScripts();
  }

  // public applyFilters() {
  //   var self = this;
  //   var filteredData = [];
  //   var Progresscondition = [];
  //   var StatusCondition = [];
  //   // var appliedFilters = this.filteredItems.filter(a=>{return a.value});

  //   _.keys(self.progressFilteredItems).map(function (element) {
  //     // console.log(element)
  //     if (self.progressFilteredItems[element].value) {
  //       Progresscondition.push(
  //         self.progressFilteredItems[element].conditionValue
  //       );
  //     }
  //   });
  //   _.keys(self.statusFilteredItems).map(function (element) {
  //     if (self.statusFilteredItems[element].value) {
  //       StatusCondition.push(self.statusFilteredItems[element].conditionValue);
  //     }
  //   });
  //   if (Progresscondition.length > 0 || StatusCondition.length > 0) {
  //     filteredData = self.scripts.filter(function (element) {
  //       var filterStatus = false;
  //       if (Progresscondition.length > 0) {
  //         if (Progresscondition.indexOf(element["progress"]) >= 0) {
  //           filterStatus = true;
  //         }
  //       } else {
  //         filterStatus = false;
  //       }
  //       if (StatusCondition.length > 0) {
  //         if (StatusCondition.indexOf(element["status"]) >= 0) {
  //           filterStatus = true;
  //         } else {
  //           filterStatus = false;
  //         }
  //       }
  //       if (filterStatus) {
  //         return element;
  //       }
  //     });
  //   } else {
  //     filteredData = self.scripts;
  //   }

  //   if (filteredData.length == 0) {
  //     self.progressFilteredItems["completed"].value = false;
  //     self.progressFilteredItems["inProgress"].value = false;
  //     self.progressFilteredItems["aborted"].value = false;
  //     self.progressFilteredItems["yetToBeReviewed"].value = false;
  //     self.statusFilteredItems["pass"].value = false;
  //     self.statusFilteredItems["fail"].value = false;
  //     self.filters = [false, false, false, false, false];
  //     self.progressFilteredItems = Object.assign(
  //       [],
  //       self.progressFilteredItems
  //     );

  //     filteredData = self.scripts;
  //     new AppComponent(self._snackBar).showSnackBar(
  //       "Selected filter is not applicable",
  //       "error"
  //     );
  //   }
  //   self.groupingScripts(filteredData);
  // }
  public minimizeWindows() {
    var self = this;
    self.minimize = !self.minimize;
    if (self.sharedData["minimizeWinodw"] == undefined) {
      self.sharedData["minimizeWinodw"] = self.minimize;
    } else {
      self.sharedData["minimizeWinodw"] = self.minimize;
    }
    self.sharedServices.changeData(self.sharedData);
  }

  public addScript() {
    this.addScriptDetailsWindow = true;
  }
  public cancelAddScript() {
    this.addScriptDetailsWindow = false;
    //Once the user tries to cancel, the entered value will become empty.
    this.scriptDeatils.scriptName = "";
  }
  public submitScriptDetails() {
    var self = this;
    console.log(self.sharedData["selectedSystem"]);
    self.addScriptDetailsWindow = false;
    var params = {
      url: "scripts/run/addScript",
      system: self.sharedData["selectedSystem"],
      data: {
        scriptName: self.scriptDeatils.scriptName,
        conf: {},
        confMessage: {},
        confValidated: false,
      },
    };
    self.httpAdapter.httpPost(params, function (err, res) {
      if (!err) {
        if (res.body.status) {
          self.addedNewScript = self.scriptDeatils.scriptName;
          // To identification, whether new config is added or not. (PM-112)
          console.log("Check", self.sharedData);
          self.sharedData["socket"] = { ...self.sharedData["socket"], NewLogAdded: true }

          // self.getScriptRunList();
          new AppComponent(self._snackBar).showSnackBar(
            "New Config Added",
            "success"
          );
          //After the configuration has been added successfully, the entered vaue will become empty.
          self.scriptDeatils.scriptName = "";
        } else {
          // window.alert(res.body.message)
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            panelClass: "my-alert-message-class",
            data: { Title: "Message", Content: res.body.message },
          });
        }
      } else {
        console.log(err);
      }
    });
  }

  public loadRunScript(script) {
    var self = this;
    self.currentRunScript = script;
    self.sharedData["currentRunScript"] = self.currentRunScript;
    self.sharedServices.changeData(self.sharedData);
  }

  getScriptRunList = () => {
    var self = this;
    const params = {
      system: this.selectedSystem,
      url: "scripts/run/listScripts",
    };
    self.httpAdapter.httpPost(params, function (err, res) {
      if (err) {
        console.log(err);
      } else {
        self.sharedData["listRunScripts"] = [...res.body];
        self.listScripts = [...self.sharedData["listRunScripts"]];
        if (self.listScripts.length > 0) {
          if (!Object.keys(self.currentRunScript).length) {
            self.currentRunScript = self.listScripts[0];
            self.sharedData["currentRunScript"] = self.currentRunScript;
          }
        } else if (self.listScripts.length == 0) {
          self.currentRunScript = {};
          self.sharedData["currentRunScript"] = self.currentRunScript;
        } else {
          self.currentRunScript = self.sharedData["currentRunScript"];
        }
        if (self.addedNewScript != null) {
          for (let i = 0; i < self.sharedData["listRunScripts"].length; i++) {
            if (
              self.sharedData["listRunScripts"][i]["scriptName"] ==
              self.addedNewScript
            ) {
              //Emiting Addconfig to Server
              self.socket.emitingFunction(
                "newAddconfig",
                self.sharedData["listRunScripts"][i]
              );
              break;
            }
          }
        }
        self.sharedServices.changeData(self.sharedData);
      }
    });
  };

  // public LoadPrograms() {
  //   var self = this;
  //   var params = {
  //     url: "app/jobserver/job",
  //     data: {
  //       "jobType": "L",
  //       "projectName": "Sample_Project",
  //       "loadsetName": "raguLdSet",
  //       "systemName": "VPZTPF2"
  //     }
  //   }
  //   self.httpAdapter.httpGiPost(params, function (err, res) {
  //     if (err) {
  //       console.log(err)
  //     } else {
  //       // console.log(res)
  //     }
  //   })
  // }

  ngOnDestroy() {
    /**
     * unsubscribe Observables that have been subscribed - (PM-147)
     */
    this.subscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    // this.socketSubscriptionForLogInfo.unsubscribe();
    // this.socketSubscriptionForLogStatus.unsubscribe();
    // this.socketSubscriptionForRunProgressInfo.unsubscribe();
    // this.socketSubscriptionForAddconfig.unsubscribe();
    // this.socketSubscriptionForDeleteConfig.unsubscribe();
    // this.socketSubscriptionForDeleteLogs.unsubscribe();
  }

  getInputFile(script) {
    var self = this;
    var params = {
      url: `trace/getInputFileFromExecution?id=${script._id}`,
    };
    self.httpAdapter.httpGetNew(params).subscribe((result) => {
      if (result.body) {
        const today = new Date();
        const blob = new Blob([result.body[0]["finalString"]], {
          type: "text",
        });
        saveAs(blob, script.name + ".input");
      }
    });
  }
}
