<div style="display: flex; justify-content: space-between; align-items: center">
  <h2 mat-dialog-title>
    Script file
    <span class="float-right text-danger">{{
      data["irtf"] ? "*iRTF required " : ""
    }}</span>
  </h2>
  <h2
    style="display: flex; justify-content: space-between; align-items: center"
    mat-dialog-title
  >
    <span
      class="float-right text-info"
      style="cursor: pointer"
      (click)="copyScriptFile(this.data['value'])"
      >Copy</span
    >
  </h2>
</div>
<mat-dialog-content class="mat-typography" style="border-top: 1px solid black">
  <!-- {{data|json}} -->
  <!-- <quill-editor
  format = "text"
  [styles]="{height: '400px'}"
  [(ngModel)]="data['value']"
  [readOnly]="readonly">
 </quill-editor> -->
  <!-- <mat-list>
      <mat-list-item *ngFor="let trans of data['value']">
        {{trans}}
      </mat-list-item>
    </mat-list> -->

  <!-- Added normal bootstap container to display transaction input instead of quill-edito (PM-157) -->

  <div class="mb-1 p-1 rounded-0 border border-muted">
    <cdk-virtual-scroll-viewport
      style="
        width: 40vw;
        max-width: 40vw;
        min-height: 55vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
      "
      [itemSize]=""
    >
      <div
        *cdkVirtualFor="
          let item of this.data['value'];
          let i = index;
          trackBy: trackByFn
        "
        class="text-dark m-0"
        style="
          font-weight: 500;
          font-size: 16px;
          /* white-space: nowrap; */
          overflow: visible;
        "
      >
        <span class="text-muted restrictTOcopy">{{ i + 1 }} </span>
        <span class="restrictTOcopy pl-1 pr-2"> | </span>

        {{ item }}
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button *ngIf="readonly" (click)="editFile()">Edit</button> -->
  <!-- <button mat-button *ngIf="!readonly" (click)="stopEdit()">Save</button> -->
</mat-dialog-actions>
