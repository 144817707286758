import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ShareDataService } from './share-data.service';
@NgModule({})
export class SharedModule {
    
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [ShareDataService],
        }
    }
}