<mat-card class="card__linux" appearance="outlined">
  <mat-card-header>
    <mat-card-title>Generate HIT and Non-HIT</mat-card-title>
    <mat-card-subtitle>Load Path Details</mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="form-container">
      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Load File Path</mat-label>
          <input
            matInput
            formControlName="loadFilePath"
            placeholder="Enter load file path"
            required
          />
          <mat-error *ngIf="myForm.get('loadFilePath').hasError('required')">
            Load File Path is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Username</mat-label>
          <input
            matInput
            formControlName="username"
            placeholder="Enter username"
            required
          />
          <mat-error *ngIf="myForm.get('username').hasError('required')">
            Username is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-field">
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Enter password"
            required
          />
          <button
            mat-icon-button
            matSuffix
            (click)="togglePasswordVisibility()"
            type="button"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-error *ngIf="myForm.get('password').hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-row">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          class="submit-button"
          [ngClass]="buttonClasses"
        >
          Generate Report
        </button>
      </div>
    </form>
  </mat-card-actions>
</mat-card>
