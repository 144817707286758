import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpAdapterService } from "../../_services/http-adapter.service";
import { HttpClient } from "@angular/common/http";
import { ShareDataService } from "../../_services/share-data.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/_services/user.service";

@Component({
  selector: "app-list-freezed-runs",
  templateUrl: "./list-freezed-runs.component.html",
  styleUrls: ["./list-freezed-runs.component.scss"],
})
export class ListFreezedRunsComponent implements OnInit, OnDestroy {
  public httpAdapter: HttpAdapterService;
  public reportData = {};
  public sharedData = {};
  public access = false;
  selectedSystem: string = "";
  subscription: any;
  constructor(
    public http: HttpClient,
    private sharedServices: ShareDataService,
    public router: Router,
    private userService: UserService
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit() {
    // this.subscription = this.sharedServices.currentData$.subscribe(data=>{
    //   this.sharedData = data;
    // })
    // this.getReports()
    console.log("Reports");

    const token = this.userService.getToken();
    this.access = token ? true : false;
    if (this.access) this.socketListener();
  }

  socketListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      )
        this.selectedSystem = data["selectedSystem"];
    });
  }

  public getReports() {
    var self = this;
    var params = {
      url: "reports",
    };

    self.httpAdapter.httpGet(params, function (err, result) {
      if (!err) {
        self.reportData = result.body;
      } else {
        console.log("Error in executing api", err);
      }
    });
  }

  public LoadFreezedRunData(freezeId, reportData) {
    var self = this;
    self.sharedData["freezeId"] = freezeId;
    self.sharedData["reportData"] = reportData;
    self.sharedServices.changeData(self.sharedData);
    self.router.navigate(["report/scripts"]);
  }

  public moveToReports() {
    const self = this;
    self.router.navigate([this.selectedSystem + "/reports/scripts"]);
    // self.router.navigate(["DEV-A/reports/scripts"]);
  }

  ngOnDestroy(): void {
    /**
     * unsubscribe Observables that have been subscribed - (PM-147)
     */
    // this.subscription.unsubscribe();
  }
}
