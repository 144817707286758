import { MatFormFieldModule } from "@angular/material/form-field";
import { GenerateTreeComponent } from "./generate-tree/generate-tree.component";
import { PgmScriptComponent } from "./pgm-script.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MatInputModule } from "@angular/material/input";

const routes: Routes = [
  {
    path: "",
    component: PgmScriptComponent,
    // children: [
    //   { path: 'generateTree', component: GenerateTreeComponent }
    // ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), MatFormFieldModule, MatInputModule],
  exports: [RouterModule],
})
export class PgmScriptRoutingModule {}
