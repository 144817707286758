import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterform'
})
export class FilterformPipe implements PipeTransform {

  transform(value: object): string {
    if (value.toString() != '') {
      var fileName = value[0]['NAME'];
      return fileName.length > 7 ? fileName.substring(0, 7) : fileName
    }
    return ''
  }

}
