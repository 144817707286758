<!--

  Dialog UI for Showing the list of Script after clicking on 'Add Script' Button in Run View 
-->
<div class="row">
  <h2
    class="col-6"
    mat-dialog-title
    [ngClass]="{
      erroheading:
        (errorMessage != null && errorMessage.length != 0) ||
        successMessage != null,
      heading:
        errorMessage != null &&
        errorMessage.length == 0 &&
        successMessage == null
    }"
    style="text-align: center"
  >
    Select the Input Script
  </h2>
  <!--
     Section to display the 'Archive File/s' button.
     'Archive File/s' button when clicked enables the archive option to select the list of Input Script
-->
  <div
    class="col-6"
    *ngIf="showArchiveAllButton; else showDeleteScript"
    style="display: flex; justify-content: center; align-items: center"
  >
    <button class="deleteAllButton btn btn-danger" (click)="archiveFile()">
      Delete File/s
    </button>
  </div>

  <!--
    Section to display the buttons after the 'Archive File/s' button is clicked.
    Display two buttons like # 'Archive Selected File/Files' and 'Cancel' button.
    Clicking on 'Archive Selected File/Files' will archive the selected Input Script Files.
-->
  <ng-template #showDeleteScript>
    <div class="col-6 deleteContainer">
      <button
        [disabled]="disableArchiveFile"
        class="deleteFileButton btn btn-danger mr-1"
        (click)="archiveSelectedFile()"
      >
        Delete Selected File/Files
      </button>
      <button class="deleteFileButton btn btn-info" (click)="archiveFile()">
        Cancel
      </button>
    </div>
  </ng-template>
</div>

<div class="row">
  <div class="col-6" *ngIf="errorMessage != null && errorMessage.length > 0">
    <div *ngFor="let error of errorMessage; let i = index">
      <p
        class="m-0 errorblink"
        style="
          font-size: min(max(8px, 3vw), 12px);
          padding-bottom: 2px;
          padding-top: 0px;
        "
        [innerHTML]="errorMessage[i]"
      ></p>
    </div>
  </div>

  <div
    class="col-6"
    *ngIf="successMessage != null && successMessage.length > 0"
  >
    <p
      *ngIf="successMessage"
      class="m-0 successblink"
      [innerHTML]="successMessage"
    ></p>
  </div>
</div>

<!-- Reference : <quill-editor></quill-editor> -->
<mat-dialog-content
  (click)="CheckDialoug()"
  class="mat-typography"
  style="border-top: 1px solid black"
>
  <mat-selection-list #shoes [(ngModel)]="data['selected']" [multiple]="false">
    <mat-list-option
      *ngFor="let trans of data['value']"
      [value]="trans"
      [disableRipple]="true"
    >
      <!-- searchPipe -->

      <div class="row align-items-end justify-content-center">
        <div class="col-6">
          <label class="checkbox-inline">
            <input
              *ngIf="archiveScriptStatus"
              type="checkbox"
              [value]="trans"
              (change)="checkValue($event, trans)"
            /><span class="transactionName">{{ trans.fileName }}</span>
          </label>
        </div>

        <div class="col-6">
          <button
            style="float: right"
            mat-button
            color="primary"
            (click)="openContent($event, trans)"
          >
            view file
          </button>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <input class="form-control" type="file" 
            (change)="onChange($event)" style="display:none;" > 
  
    
  <button mat-button color="warn" (click)="onUpload()">
    <mat-icon>file_upload</mat-icon>
    Upload
  </button> -->
  <button mat-button color="warn" (click)="onClick()">
    <mat-icon>file_upload</mat-icon>
    Upload
  </button>
  <input
    class="form-control"
    type="file"
    (click)="fileInput.value = null"
    (change)="onChange($event)"
    value=""
    style="display: none"
    #fileInput
  />
  <!-- <input type="file" [(ngModel)]="files" name="inputFile" multiple #fileInput id="fileUpload"
    style="display:none;" /> -->

  <!-- <form action="/api/upload" enctype="multipart/form-data" method="post">
              <div>Text field title: <input type="text" name="title" /></div>
               <div>File: <input type="file" name="inputFile" multiple="multiple" /></div>
               <input type="submit" value="Upload" /> -->
  <!-- </form> -->
  <!-- <button mat-button *ngIf="!enableUpload" (click)="enableUpload=true">Upload</button> -->
  <button mat-button (click)="CloseDialoug(); data['closed'] = true">
    Close
  </button>
  <button
    *ngIf="itemSelected"
    (click)="CheckDialoug()"
    mat-button
    [mat-dialog-close]="data"
  >
    Select
  </button>
  <!-- <button mat-button *ngIf="readonly" (click)="editFile()">Edit</button> -->
  <!-- <button mat-button *ngIf="!readonly" (click)="stopEdit()">Save</button> -->
</mat-dialog-actions>
