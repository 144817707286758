import { HttpClient } from "@angular/common/http";
import { Component, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, startWith, switchMap } from "rxjs";
import { of } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-trace.data",
  templateUrl: "./trace.data.component.html",
  styleUrls: ["./trace.data.component.scss"],
})
export class TraceDataComponent {
  showSP: boolean = true;
  showSM: boolean = true;
  recordsMissing = "";
  executionName: string = "";
  executionId: string = "";
  myControl = new UntypedFormControl("");

  phase1ExecutionData: any = [];
  phase2ExecutionData: any = [];
  public httpAdapter: HttpAdapterService;
  @ViewChildren(CdkVirtualScrollViewport)
  virtualScroll: QueryList<CdkVirtualScrollViewport>;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private clipboard: Clipboard
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }
  copyToClipboard(data) {
    this.clipboard.copy(data);
  }
  currentTabIndex = 0;
  tabChange(tabChangeEvent: MatTabChangeEvent): void {
    // console.log('tabChangeEvent => ', tabChangeEvent);
    //  console.log('index => ', tabChangeEvent.index);
  }
  removeSpace(data) {
    return data.replaceAll(" ", "");
  }
  limit = 200;
  phase1offset = 0;
  endReached = false;

  loadMorePhase1Data(last, index) {
    if (this.phase1ExecutionData.length - this.limit / 2 === index + 1) {
      this.getRecordsFromScriptIdOrTransIdPhase1(
        this.myControl.value == "ALL" ? "" : this.myControl.value
      );
    } else if (this.phase1ExecutionData.length - 5 === index + 1) {
      this.getRecordsFromScriptIdOrTransIdPhase1(
        this.myControl.value == "ALL" ? "" : this.myControl.value
      );
    }
  }
  loadMorePhase2Data(last, index) {
    if (
      !this.phase2EndReached &&
      this.phase2ExecutionData.length - this.limit / 2 === index + 1
    ) {
      this.getRecordsFromScriptIdOrTransIdPhase2(
        this.myControl.value == "ALL" ? "" : this.myControl.value
      );
    } else if (
      this.phase2EndReached &&
      this.phase2ExecutionData.length - 5 === index + 1
    ) {
      this.getRecordsFromScriptIdOrTransIdPhase2(
        this.myControl.value == "ALL" ? "" : this.myControl.value
      );
    }

    // if (this.currentScript.progress !== "PROGRESS" && last && !this.endReached) {
    // this.getExecutionData(this.limit, this.offset + this.limit)
    // this.offset += this.limit
    // }
  }
  scriptName = "";
  scriptId = "";
  transId = "";
  totalTransactions: Number = 0;
  ngOnInit() {
    // this.route.paramMap.pipe(
    //   switchMap((params) => {
    //     this.executionName = params.get('name');
    //     this.executionId = params.get('id');
    //     return of(this.executionId);
    //   })
    // ).subscribe((executionId) => {
    //   console.log(executionId);
    //   this.getPhase1ExecutionData();
    //   this.getPhase2ExecutionData();

    //   // Do something with the executionName if needed
    // });
    // this.route.paramMap.pipe(
    //   switchMap((params) => {

    this.scriptName = this.route.snapshot.queryParamMap.get("scriptName");
    this.scriptId = this.route.snapshot.queryParamMap.get("scriptId");
    this.transId = this.route.snapshot.queryParamMap.get("transId");
    this.totalTransactions = parseInt(
      this.route.snapshot.queryParamMap.get("total"),
      10
    );
    this.myControl.setValue(this.transId ? this.transId : "ALL");
    //     return of(this.scriptId);
    //   })
    // ).subscribe((scriptId) => {
    this.getRecordsFromScriptIdOrTransIdPhase1(this.transId);
    this.getRecordsFromScriptIdOrTransIdPhase2(this.transId);
    this.getArray();
    // })
  }
  private _filter(value: string) {
    var filter = this.wholeTrasactions.filter((option) => {
      return (
        option.toLowerCase().includes(value.toLowerCase()) ||
        option.toLowerCase().includes(value.toLowerCase())
      );
    });
    console.log(this.wholeTrasactions, filter);

    return filter;
  }
  filteredOptions;
  wholeTrasactions = [];
  getArray() {
    this.wholeTrasactions = Array(this.totalTransactions)
      .fill(1)
      .map((ele, i) => "TX" + (i + 1).toString().padStart(6, "000000"));
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
    console.log(this.wholeTrasactions, this.filteredOptions);
  }

  trackByFn(index, item) {
    return item;
  }
  phase1EndReached = false;
  getRecordsFromScriptIdOrTransIdPhase1(transaction) {
    var params = {
      url: `trace/getRecordsFromScriptIdOrTransId?scriptId=${this.scriptId
        }&transaction=${transaction}&phase=${"PH1"}&limit=${this.limit}&offset=${this.phase1offset
        }&missingRecord=${this.recordsMissing}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe((res) => {
      if (this.phase1offset === 0) {
        this.phase1ExecutionData = res.body;
        const response: any[] = Array(res.body);
        // this.offset = this.limit;
        this.phase1offset += response[0].length;
        if (response[0].length < this.limit) this.phase1EndReached = true;
        else this.phase1EndReached = false;
      } else {
        const response: any[] = Array(res.body);
        this.phase1offset += response[0].length;
        this.phase1ExecutionData = this.phase1ExecutionData.concat(response[0]);
        if (response[0].length < this.limit) this.phase1EndReached = true;
        else this.phase1EndReached = false;
      }
    });
  }
  phase2EndReached = false;
  phase2offset = 0;
  getRecordsFromScriptIdOrTransIdPhase2(transaction) {
    var params = {
      url: `trace/getRecordsFromScriptIdOrTransId?scriptId=${this.scriptId
        }&transaction=${transaction}&phase=${"PH2"}&limit=${this.limit}&offset=${this.phase2offset
        }&missingRecord=${this.recordsMissing}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe((res) => {
      if (this.phase2offset === 0) {
        this.phase2ExecutionData = res.body;
        const response: any[] = Array(res.body);
        // this.offset = this.limit;
        this.phase2offset += response[0].length;
        if (response[0].length < this.limit) this.phase2EndReached = true;
        else this.phase2EndReached = false;
      } else {
        const response: any[] = Array(res.body);
        this.phase2offset += response[0].length;
        this.phase2ExecutionData = this.phase2ExecutionData.concat(response[0]);
        if (response[0].length < this.limit) this.phase2EndReached = true;
        else this.phase2EndReached = false;
      }
    });
  }
}
