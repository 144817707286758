import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ShareDataService } from "src/app/_services/share-data.service";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";
import * as Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";

Exporting(Highcharts);
interface CustomPoint extends Highcharts.Point {
  z: string; // or whatever type `z` is supposed to be
}
@Component({
  selector: "app-utilization-card",
  templateUrl: "./utilization-card.component.html",
  styleUrls: ["./utilization-card.component.scss"],
})
export class UtilizationCardComponent {
  @Input() icon = "";
  @Input() label = "";
  @Input() socketInfo = "";
  @Input() value = 0;
  @Input() brand = "";
  @Input() brand_color = "";
  @Input() GraphStatus = false;
  @Input() processesStatus = false;
  @Input() deleteIcon = false;
  @Input() iconColor = "white";
  @Output() deleteIconClickEvent = new EventEmitter();
  @Output() ediIconClickEvent = new EventEmitter();

  Highcharts: typeof Highcharts = Highcharts;
  @Input() total = 0;
  constructor(
    private sharedServices: ShareDataService,
    private socket: SocketioClientServices,
    public ref: ChangeDetectorRef
  ) {}
  ngOnInit() {
    if (this.GraphStatus) this.socketFunctionWithChart();
    if (this.processesStatus) this.socketForProcess();
  }
  chartOption;
  socketFunctionWithChart() {
    let self = this;
    this.chartOption = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: null,
        type: "pie",
        events: {
          load: function () {
            const chart = this;
            self.socket
              .listeningFunction(self.socketInfo)
              .subscribe((data: any) => {
                const total = data.total;
                const free = data.free;
                const used = (total - free).toFixed(2);
                if (chart.series)
                  chart.series[0].setData([
                    {
                      name: `Used`,
                      y: parseInt(used),
                    },
                    {
                      name: `Free`,
                      y: parseInt(free),
                    },
                  ]);
              });
          },
        },
      },
      title: {
        text: this.label,
        align: "left",
      },
      tooltip: {
        formatter: function () {
          // Customizing tooltip content with the customLabel property
          return `<b>${this.point.name}</b>: ${this.point.y} GB`;
        },
      },
      accessibility: {
        point: {
          valueSuffix: "GB",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true, // Enable data labels
            distance: 10,
            style: {
              color: "#000000", // Change the text color of data labels to black
              fontSize: "14px", // Customize font size (optional)
              fontWeight: "bold", // Customize font weight (optional)
            },
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Memory",
          colorByPoint: true,
          data: [
            {
              name: `Used`,
              y:
                parseInt(parseInt(self.total.toString()).toFixed(2), 10) -
                parseInt(parseInt(self.value.toString()).toFixed(2), 10),
              color: "#f04a58",
            },
            {
              name: `Free`,
              y: parseInt(parseInt(self.value.toString()).toFixed(2), 10),
              color: "#52e378",
            },
          ],
        },
      ],
    };
  }

  socketForProcess() {
    this.socket.listeningFunction(this.socketInfo).subscribe((data: any) => {
      this.value = data;
    });
  }
}
