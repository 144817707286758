import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'searchTransaction'
})
export class SearchTransactionPipe implements PipeTransform {

  transform(value: any, searChText: string): any {


    if (searChText != null && searChText != "" && value != undefined && value.length > 0 ) {
      var searchValue = searChText.toLowerCase();
      console.log(value, searchValue);
      var temp = JSON.parse(JSON.stringify(value));
      temp.forEach(function (element, scriptIndex) {
        
        element['array_of_json'].forEach(function (parentScript, parentIndex) {
          parentScript['array_of_json'] = JSON.parse(JSON.stringify(parentScript['array_of_json'].filter(function (childScript, childindex) {
              return childScript["PH1"]["message"]["msgData"]["MI"]["data"]["input"].toLowerCase().includes(searchValue)
            })))
        })

      })
      return temp;
    } else {
      return value;
    }

  }

}
